import axios from "@/axios";

const status = {
  state: () => ({}),
  mutations: {},
  actions: {
    AdminStatus({ getters }, payload) {
      if (getters.userData) {
        return new Promise((resolve, reject) => {
          axios({
            url: "/api/users/changeStatus",
            method: "post",
            data: { status: payload.status, email: payload.email },
          })
            .then((res) => {
              resolve(res);
            })
            .catch((err) => reject(err));
        });
      }
    },
    DoctorStatus({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/doctors/status/${payload._id}`,
          method: "post",
          data: { status: payload.status },
        })
          .then((res) => {
            commit("doctorDetails", res.data);
            resolve(res);
          })
          .catch((err) => reject(err.response));
      });
    },
    LabStatus({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/labs/status/${payload._id}`,
          method: "post",
          data: { status: payload.status },
        })
          .then((res) => {
            commit("getLabDetails", res.data);
            resolve(res);
          })
          .catch((err) => reject(err.response));
      });
    },
    CompanyStatus({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/companies/status/${payload._id}`,
          method: "post",
          data: { status: payload.status },
        })
          .then((res) => {
            commit("geCompanyDetails", res.data);
            resolve(res);
          })
          .catch((err) => reject(err.response));
      });
    },
    changeEmployeeToAdmin({ getters }, payload) {
      if (getters.userData) {
        return new Promise((resolve, reject) => {
          axios({
            url: `/api/users/setEmployeeAdmin/${payload.id}`,
            method: "post",
            data: { email: payload.email, password: payload.password },
          })
            .then((res) => {
              resolve(res);
            })
            .catch((err) => reject(err.response));
        });
      }
    },
    removeSupervisor({ getters }, payload) {
      if (getters.userData.status == "super-admin") {
        return new Promise((resolve, reject) => {
          axios
            .post(`/api/users/removeEmployeeAdmin/${payload}`)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err.response.data);
            });
        });
      }
    },
  },
  getters: {},
};

export default status;
