<template>
  <div class="container mx-auto py-6 px-4">
    <div class="flex justify-between items-center py-4 border-b mb-10">
      <h1 class="text-3xl">
        {{
          $route.params.count == "requestsCount"
            ? "طلبات عضوية المختبرات"
            : $route.params.count == "RenewalRequestsCount"
            ? "طلبات تجديد العضوية للمختبرات"
            : "  المختبرات"
        }}
      </h1>
      <router-link
        :to="{ name: 'Labs' }"
        class="
          px-5
          py-2
          focus:outline-none
          hover:shadow-md
          rounded-md
          bg-primary
          text-white
          flex
          items-center
        "
      >
        اضافة<i class="bx bxs-plus-circle mx-1"></i>
      </router-link>
    </div>

    <div class="mb-4 flex items-center flex-wrap">
      <div class="relative w-full md:w-auto flex items-center">
        <input
          type="text"
          class="
            w-full
            pl-10
            pr-4
            py-2
            rounded-lg
            border border-inputBorder
            focus:outline-none focus:shadow-outline
            text-gray-600
            font-medium
          "
          placeholder="بحث"
          v-model="Search"
          @keyup.enter="filterBySearch"
        />
        <i
          @click="filterBySearch"
          class="bx bx-search-alt z-20 text-gray-500 -mr-8 cursor-pointer"
        ></i>
      </div>
      <v-select
        dir="rtl"
        v-if="Branches && userData.status == 'super-admin'"
        :options="Branches"
        label="name_ar"
        v-model="branch_id"
        placeholder="النقابة"
        class="
          mx-0
          my-2
          md:my-0 md:mx-3
          w-full
          md:w-1/4
          bg-white
          rounded-md
          border border-inputBorder
          p-1
        "
      ></v-select>
      <v-select
        dir="rtl"
        :options="['الأحدث', 'الأقدم']"
        v-model="SortBy"
        placeholder="ترتيب بأستخدام"
        class="
          mx-0
          my-2
          md:mx-3
          w-full
          md:w-1/4
          bg-white
          rounded-md
          border border-inputBorder
          p-1
        "
      ></v-select>
    </div>

    <div class="flex items-center w-full sm:3/4 lg:w-3/4 my-5 flex-wrap">
      <button
        @click="
          alllabs();
          page = 1;
        "
        :class="
          $route.params.count == 'all'
            ? 'bg-primary text-white'
            : 'bg-white text-primary'
        "
        class="
          focus:outline-none
          mx-3
          border-2
          w-full
          sm:w-auto
          my-2
          border-primary
          px-4
          py-1
          rounded-md
          font-medium
          text-bold
        "
      >
        جميع المختبرات
      </button>
      <button
        @click="
          requestsCount();
          page = 1;
        "
        :class="
          $route.params.count == 'requestsCount'
            ? 'bg-primary text-white'
            : 'bg-white text-primary'
        "
        class="
          focus:outline-none
          mx-3
          border-2
          w-full
          sm:w-auto
          my-2
          border-primary
          px-4
          py-1
          rounded-md
          font-medium
          text-bold
        "
      >
        طلبات العضوية
      </button>
      <button
        @click="
          RenewalRequestsCount();
          page = 1;
        "
        :class="
          $route.params.count == 'RenewalRequestsCount'
            ? 'bg-primary text-white'
            : 'bg-white text-primary'
        "
        class="
          focus:outline-none
          mx-3
          border-2
          w-full
          sm:w-auto
          my-2
          border-primary
          px-4
          py-1
          rounded-md
          font-medium
          text-bold
        "
      >
        طلبات التجديد
      </button>
      <button
        @click="
          finishedLabsMemberShip();
          page = 1;
        "
        :class="
          $route.params.count == 'finishedLabsMemberShip'
            ? 'bg-primary text-white'
            : 'bg-white text-primary'
        "
        class="
          focus:outline-none
          mx-3
          border-2
          w-full
          sm:w-auto
          my-2
          border-primary
          px-4
          py-1
          rounded-md
          font-medium
          text-bold
        "
      >
        المختبرات الغير نشطون
      </button>
    </div>
    <button
      v-if="printData"
      @click="printReport"
      class="
        border
        rounded-lg
        mb-5
        w-full
        md:w-auto
        border-primary
        px-3
        hover:bg-primary
        text-md text-primary
        hover:text-white
        py-2
        font-bold
        flex
        justify-center
        items-center
      "
    >
      <span> طباعة اذن مزاولة المختبر {{ printData.lab_name }}</span>
      <i class="bx bx-printer mx-2 text-2xl"></i>
    </button>

    <div v-if="errMsg && !loading">
      <infoAlert :message="errMsg" />
    </div>

    <div
      v-if="Labs && !loading"
      class="bg-white rounded border border-inputBorder overflow-y-auto w-full"
    >
      <table
        v-if="Labs.data.length > 0"
        class="border-collapse whitespace-no-wrap bg-white w-full"
      >
        <thead>
          <tr class="text-right">
            <th
              v-for="heading in headings"
              :key="heading.id"
              class="
                bg-gray-100
                border-b border-gray-200
                px-2
                text-center
                py-2
                text-gray-600
                font-bold
                tracking-wider
                uppercase
                text-md
              "
            >
              {{ heading.value }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="lab in Labs.data"
            :key="lab.id"
            @click="printBtn(lab)"
            class="cursor-pointer"
            :class="
              printData
                ? printData.id == lab.id
                : false
                ? 'bg-gray-200 border border-gray-400 shadow-md z-20'
                : null
            "
          >
            <td class="border-dashed border-t border-gray-200 userId">
              <span class="text-gray-700 px-6 py-3 flex items-center">
                <label class="inline-flex items-center mt-3">
                  <input
                    type="checkbox"
                    class="form-checkbox h-5 w-5 text-blue-600"
                    :checked="printData ? printData.id == lab.id : null"
                  />
                </label>
              </span>
            </td>
            <td class="border-dashed border-t border-gray-200 text-center">
              <span
                class="text-gray-700 px-6 py-3 flex items-center text-center"
                >{{ lab.member_ship_id }}</span
              >
            </td>
            <td class="border-dashed border-t border-gray-200 text-center">
              <span
                class="text-gray-700 px-6 py-3 flex items-center text-center"
                >{{ lab.lab_name }}</span
              >
            </td>
            <td class="border-dashed border-t border-gray-200 text-center">
              <span
                class="text-gray-700 px-6 py-3 flex items-center text-center"
                >{{ lab.date_of_license_issue }}</span
              >
            </td>
            <td class="border-dashed border-t border-gray-200 text-center">
              <span
                class="text-gray-700 px-6 py-3 flex items-center text-center"
                >{{ lab.chamber_of_commerce_registration_number }}</span
              >
            </td>
            <td
              class="
                border-dashed border-t border-gray-200
                emailAddress
                text-center
              "
            >
              <span
                class="text-gray-700 px-6 py-3 flex items-center text-center"
                >{{ lab.lap_owner_name }}</span
              >
            </td>
            <td class="border-dashed border-t border-gray-200 gender">
              <span class="text-gray-700 py-3 flex items-center text-center">
                <select
                  name="status"
                  id="status"
                  @change="ChangeLabStatus($event, lab)"
                  :value="lab.status"
                  class="
                    p-1
                    border-inputBorder
                    bg-white
                    rounded-md
                    border
                    w-full
                    text-right
                    focus-within:border-primary
                    hover:border-primary
                    focus:border-primary focus:outline-none focus:shadow-md
                  "
                >
                  <option value="نشط">نشط</option>
                  <option value="حظر">حظر</option>
                  <option value="حذف">حذف</option>
                  <option
                    v-if="lab.status == 'طلب عضوية' || lab.status == 'غير نشط'"
                  >
                    {{ lab.status }}
                  </option>
                </select>
              </span>
            </td>
            <td
              class="border-dashed border-t border-gray-200 flex items-center"
            >
              <span class="text-gray-700 px-6 py-3 flex items-center">
                <button
                  class="
                    bg-primary
                    px-3
                    py-1
                    rounded-md
                    text-white
                    flex
                    items-center
                  "
                  @click="editLab(lab)"
                >
                  <span class="mx-2">تعديل</span>
                  <i class="bx bx-message-square-edit"></i>
                </button>
              </span>
              <router-link
                :to="{ name: 'LabDetails', params: { doc: lab.id } }"
                class="
                  border border-primary
                  hover:bg-primary
                  text-primary
                  hover:text-white
                  h-8
                  w-8
                  flex
                  items-center
                  justify-center
                  cursor-pointer
                  rounded-full
                  transition-all
                  duration-75
                "
                ><i class="bx bxs-left-arrow-alt"></i>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else class="my-8 text-center">لا يوجد بيانات لعرضها</p>
    </div>
    <p v-if="!Labs && !loading" class="my-8 text-center">
      لا يوجد بيانات لعرضها
    </p>

    <div v-if="loading" class="flex justify-center my-5">
      <i
        class="text-4xl block mx-auto text-primary bx bx-loader-circle bx-spin"
      ></i>
    </div>

    <div class="w-full my-12" v-if="Labs && !loading">
      <pagination
        :last_page="Labs.meta.last_page"
        :current_page="Labs.meta.current_page"
        :from="Labs.meta.from"
        @setPage="clickCallback"
      />
    </div>
  </div>
</template>
<script>
import infoAlert from "../../layout/infoAlert";
import Printlab from "../printDoc/lab";

export default {
  name: "labs",
  data() {
    return {
      branch_id: null,
      branch: null,
      loading: true,

      page: 1,
      printData: null,
      SortBy: null,
      headings: [
        {
          id: 0,
          value: "",
        },
        {
          id: 1,
          value: "رقم العضوية",
        },
        {
          id: 2,
          value: "اسم المختبر",
        },
        {
          id: 3,
          value: "تاريخ تسجيل بالغرفة",
        },
        {
          id: 4,
          value: "رقم التسجيل بالغرفة التجارية",
        },
        {
          id: 5,
          value: "اسم مالك المختبر",
        },
        {
          id: 6,
          value: " الحالة",
        },
        {
          id: 7,
          value: "",
        },
      ],
      Search: "",
      errMsg: "",
      sort: "Desc",
      QR_URL: process.env.VUE_APP_QR,
    };
  },
  created() {
    if (this.$route.params.count == "requestsCount") {
      this.requestsCount();
    } else if (this.$route.params.count == "RenewalRequestsCount") {
      this.RenewalRequestsCount();
    } else if (this.$route.params.count == "all") {
      this.alllabs();
    } else if (this.$route.params.count == "finishedLabsMemberShip") {
      this.finishedLabsMemberShip();
    }
  },
  watch: {
    branch_id() {
      this.filterByBranch();
    },
    SortBy() {
      this.SortByMethods();
    },
  },
  methods: {
    printBtn(data) {
      if (this.printData) {
        if (data.id == this.printData.id) {
          this.printData = null;
          return;
        }
      }
      this.printData = data;
    },
    filterByBranch() {
      if (this.$route.params.count == "requestsCount") {
        this.requestsCount();
      } else if (this.$route.params.count == "RenewalRequestsCount") {
        this.RenewalRequestsCount();
      } else if (this.$route.params.count == "all") {
        this.alllabs();
      } else if (this.$route.params.count == "finishedLabsMemberShip") {
        this.finishedLabsMemberShip();
      }
    },
    filterBySearch() {
      if (this.$route.params.count == "requestsCount") {
        this.requestsCount();
      } else if (this.$route.params.count == "RenewalRequestsCount") {
        this.RenewalRequestsCount();
      } else if (this.$route.params.count == "all") {
        this.alllabs();
      } else if (this.$route.params.count == "finishedLabsMemberShip") {
        this.finishedLabsMemberShip();
      }
    },
    SortByMethods() {
      if (this.SortBy == "الأقدم") {
        this.sort = "Asc";
        if (this.$route.params.count == "requestsCount") {
          this.requestsCount();
        } else if (this.$route.params.count == "RenewalRequestsCount") {
          this.RenewalRequestsCount();
        } else if (this.$route.params.count == "all") {
          this.alllabs();
        } else if (this.$route.params.count == "finishedLabsMemberShip") {
          this.finishedLabsMemberShip();
        }
      } else {
        this.sort = "Desc";
        if (this.$route.params.count == "requestsCount") {
          this.requestsCount();
        } else if (this.$route.params.count == "RenewalRequestsCount") {
          this.RenewalRequestsCount();
        } else if (this.$route.params.count == "all") {
          this.alllabs();
        } else if (this.$route.params.count == "finishedLabsMemberShip") {
          this.finishedLabsMemberShip();
        }
      }
    },
    clickCallback: function (pageNum) {
      if (!pageNum) return;
      this.page = pageNum;
      if (this.$route.params.count == "requestsCount") {
        this.requestsCount();
      } else if (this.$route.params.count == "RenewalRequestsCount") {
        this.RenewalRequestsCount();
      } else if (this.$route.params.count == "all") {
        this.alllabs();
      } else if (this.$route.params.count == "finishedLabsMemberShip") {
        this.finishedLabsMemberShip();
      }
    },
    alllabs() {
      this.errMsg = "";
      this.loading = true;
      this.$route.params.count !== "all"
        ? this.$router.push({ name: "LabsPanel", params: { count: "all" } })
        : null;
      this.$store
        .dispatch("getAllLabs", {
          page: this.page,
          sort: this.sort,
          search: this.Search,
          branch_id: this.branch_id ? this.branch_id.id : "",
        })
        .then((res) => {
          this.loading = false;
          if (res.labs.data == "") {
            this.errMsg = "لا يوجد مختبرات مسجلين بعد";
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    finishedLabsMemberShip() {
      this.errMsg = "";
      this.loading = true;
      this.$route.params.count !== "finishedLabsMemberShip"
        ? this.$router.push({
            name: "LabsPanel",
            params: { count: "finishedLabsMemberShip" },
          })
        : null;
      this.$store
        .dispatch("finishedLabsMemberShip", {
          page: this.page,
          sort: this.sort,
          search: this.Search,
          branch_id: this.branch_id ? this.branch_id.id : "",
        })
        .then((res) => {
          this.loading = false;
          if (res.labs.data == "") {
            this.errMsg = "لا يوجد باحثون مختبرات منتهية  عضويتهم";
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    requestsCount() {
      this.errMsg = "";
      this.loading = true;
      this.$route.params.count !== "requestsCount"
        ? this.$router.push({
            name: "LabsPanel",
            params: { count: "requestsCount" },
          })
        : null;
      this.$store
        .dispatch("LabsRequests", {
          page: this.page,
          sort: this.sort,
          search: this.Search,
          branch_id: this.branch_id ? this.branch_id.id : "",
        })
        .then((res) => {
          this.loading = false;
          if (res.labs.data == "") {
            this.errMsg = "لا يوجد طلبات تسجيل";
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    RenewalRequestsCount() {
      this.errMsg = "";
      this.loading = true;
      this.$route.params.count !== "RenewalRequestsCount"
        ? this.$router.push({
            name: "LabsPanel",
            params: { count: "RenewalRequestsCount" },
          })
        : null;
      this.$store
        .dispatch("renewalLabssRequests", {
          page: this.page,
          sort: this.sort,
          search: this.Search,
          branch_id: this.branch_id ? this.branch_id.id : "",
        })
        .then((res) => {
          this.loading = false;
          if (res.labs.data == "") {
            this.errMsg = "لا يوجد طلبات تجديد";
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    ChangeLabStatus(e, lab) {
      const status = e.target.value;
      this.loading = true;
      this.$store
        .dispatch("LabStatus", {
          status: status,
          _id: lab.id,
        })
        .then(() => {
          this.printData = null;
          this.SortByMethods();
        });
    },
    editLab(labDetail) {
      this.$store.commit("editLab", labDetail);
      this.$router.push({ name: "Labs" });
    },
    printReport() {
      if (this.printData.status !== "نشط") {
        this.errMsg = "يجب تنشيط هذا المختبر لطباعة إذن المزاولة الخاص به";
        setTimeout(() => {
          this.errMsg = null;
        }, 7000);
      } else {
        var tab = Printlab(this.printData, this.userData);

        const WinPrint = window.open(
          "",
          "",
          "right=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        );
        WinPrint.document.write(tab);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        setTimeout(() => {
          WinPrint.close();
        }, 1000);
      }
    },
  },
  computed: {
    Labs() {
      return this.$store.getters.getAllLabs;
    },
    Branches() {
      return this.$store.getters.getBranches;
    },
    userData() {
      return this.$store.getters.userData;
    },
  },
  components: {
    infoAlert,
  },
};
</script>
