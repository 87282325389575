<template>
  <div id="Dashboard" class="min-h-screen flex w-full bg-white relative">
    <transition name="slide-fade">
      <div
        v-if="slide"
        class="fixed h-screen z-30 w-3/4 sm:w-1/2 md:w-1/4 lg:w-1/5 bg-h1Text"
      >
        <div class="text-white m-5 mb-0 flex items-center justify-between">
          <i
            @click="slide = !slide"
            class="bx bx-menu text-3xl cursor-pointer"
          ></i>
          <img
            class="h-12 w-12"
            alt=""
            loading="lazy"
            src="../assets/imgs/libyan_labs_logo_dark_bg.png"
          />
        </div>
        <navigation @toggleSlide="slide = !slide" />
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="slide"
        @click="slide = !slide"
        class="absolute inset-0 w-full h-full z-20"
        style="background: rgba(0, 0, 0, 0.5)"
      ></div>
    </transition>
    <div class="w-full">
      <div class="p-5 bg-primary shadow-lg flex items-center text-white">
        <div class="flex items-center">
          <i
            @click="slide = !slide"
            class="bx bx-menu text-3xl cursor-pointer ml-5"
          ></i>
          <img
            @click="$router.push({ name: 'Dashboard' })"
            class="cursor-pointer"
            src="../assets/imgs/libyan_labs_logo_dark_bg.png"
            height="45"
            width="45"
            alt=""
            loading="lazy"
          />
          <h2 class="mx-5 text-2xl">نقابة طب المختبرات الليبية</h2>
        </div>
      </div>

      <transition name="fade">
        <router-view />
      </transition>
    </div>
  </div>
</template>
<script>
import navigation from "../components/dashboard/navigation";

export default {
  name: "Dashboard",
  data() {
    return {
      slide: false,
    };
  },
  created() {
    this.$store.dispatch("getBranches");
  },
  components: {
    navigation,
  },
};
</script>
