<template>
  <div id="twitter" class="py-12 container mx-auto px-5 lg:px-12">
    <div class="w-full flex justify-between items-center">
      <h1 class="text-h1Text text-3xl my-5">الهيكل التضيمي للنقابة</h1>
      <button
        @click="togglePopup = true"
        class="px-5 py-2 focus:outline-none hover:shadow-md rounded-md bg-primary text-white flex items-center"
      >
        اضافة<i class="bx bxs-plus-circle mx-1"></i>
      </button>
    </div>

    <div v-if="!loading" class="bg-white w-full">
      <table
        v-if="Strucuture.length > 0"
        class="rounded border border-inputBorder overflow-y-auto border-collapse whitespace-no-wrap bg-white w-full"
      >
        <thead>
          <tr class="text-right">
            <th
              v-for="heading in headings"
              :key="heading.id"
              class="bg-gray-100 border-b border-gray-400 px-2 text-center py-2 text-gray-600 font-bold tracking-wider uppercase text-md"
            >
              {{ heading.value }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="employee in Strucuture"
            :key="employee.id"
            class=" font-medium text-h1Text text-md tracking-wide leading-10"
          >
            <td class="border-dashed border-t border-gray-200 text-center">
              {{ employee.id }}
            </td>
            <td class="border-dashed border-t border-gray-200 text-center">
              {{ employee.name_ar }}
            </td>
            <td class="border-dashed border-t border-gray-200 text-center">
              {{ employee.job_description_ar }}
            </td>
            <td
              class="border-dashed border-t border-gray-200 text-center flex justify-between items-center"
            >
              <i
                @click="editItem(employee)"
                class="bx bx-edit text-primary cursor-pointer text-2xl font-medium"
              ></i>
              <i
                @click="deleteItem(employee.id)"
                class="bx bx-trash text-red-600 cursor-pointer text-2xl font-medium"
              ></i>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="mx-auto text-center text-2xl font-medium my-12">
        لا يوجد نتائج
      </div>
    </div>
    <div v-if="loading" class="flex justify-center my-5">
      <i
        class="text-4xl block mx-auto text-primary bx bx-loader-circle bx-spin"
      ></i>
    </div>

    <transition name="fade">
      <div
        v-if="togglePopup"
        class="fixed z-50 inset-0 flex items-center justify-center w-screen h-screen"
        style="background: rgba(0, 0, 0, 0.3)"
      >
        <div
          class="bg-white shadow-lg rounded-lg z-30 p-8 w-3/4 md:w-1/2 lg:w-1/2"
        >
          <div class="flex items-centet justify-between">
            <h3
              class="text-2xl text-medium text-h1Text mb-3 leading-relaxed tracking-wide"
            >
              اضافة موظف الى الهيكل التنظيمي
            </h3>
            <i
              class="bx bx-x rounded-full p-1 text-3xl text-gray-600 cursor-pointer"
              @click="togglePopup = false"
            ></i>
          </div>
          <form @submit.prevent="submit">
            <div class="my-5 w-full">
              <input
                type="text"
                name="name"
                v-model="form.name_ar"
                required
                placeholder="الإسم بالعربي"
                @blur="$v.form.name_ar.$touch()"
                :class="
                  defualtInput +
                  ($v.form.name_ar.$error
                    ? ' border-red-700'
                    : 'border-inputBorder')
                "
              />
            </div>
            <div class="my-5 w-full">
              <input
                type="text"
                name="name"
                v-model="form.name_en"
                required
                placeholder="الإسم بالأنجليزي"
                @blur="$v.form.name_en.$touch()"
                :class="
                  defualtInput +
                  ($v.form.name_en.$error
                    ? ' border-red-700'
                    : 'border-inputBorder')
                "
              />
            </div>
            <div class="my-5 w-full">
              <v-select
                dir="rtl"
                placeholder="المسمى الوظيفي بالعربي"
                v-model="form.job_description_ar"
                :options="['نقيب', 'مدير عام', 'نائب']"
                @blur="$v.form.job_description_ar.$touch()"
                :class="
                  defualtInput +
                  ($v.form.job_description_ar.$error
                    ? ' border-red-700'
                    : 'border-inputBorder')
                "
              />
            </div>
            <div class="my-5 w-full">
              <v-select
                placeholder="المسمى الوظيفي بالانجليزي"
                v-model="form.job_description_en"
                :options="['captain', 'general manager', 'surrogate']"
                @blur="$v.form.job_description_en.$touch()"
                :class="
                  defualtInput +
                  ($v.form.job_description_ar.$error
                    ? ' border-red-700'
                    : 'border-inputBorder')
                "
              />
            </div>
            <button
              class="bg-primary px-8 py-2 text-white font-medium rounded-md focus:outline-none"
            >
              <span class="mx-2">تأكيد </span>
              <i
                v-if="loadingSubmit"
                class="text-2xl bx bx-loader-circle bx-spin"
              ></i>
            </button>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "Structure",
  data() {
    return {
      loading: true,
      loadingSubmit: false,
      togglePopup: false,
      edit: false,
      defualtInput:
        "p-2  bg-white rounded-md border w-full text-right focus-within:border-primary hover:border-primary focus:border-primary focus:outline-none focus:shadow-md my-1",

      form: {
        name_ar: "",
        name_en: "",
        job_description_ar: "",
        job_description_en: "",
      },
      headings: [
        {
          id: 0,
          value: "",
        },
        {
          id: 1,
          value: "الأسم",
        },
        {
          id: 2,
          value: "المسمى الوظيفي",
        },
        {
          id: 3,
          value: "",
        },
      ],
    };
  },
  validations: {
    form: {
      name_ar: { required },
      name_en: { required },
      job_description_ar: { required },
      job_description_en: { required },
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store
        .dispatch("fetchStructureData")
        .finally(() => (this.loading = false));
    },
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$pending || this.$v.form.$error) return;
      this.loadingSubmit = true;
      if (this.edit) {
        this.$store
          .dispatch("editEmployeeToStructure", this.form)
          .finally(() => {
            this.loadingSubmit = false;
            this.togglePopup = false;
          });
        return;
      }
      this.$store.dispatch("addEmployeeToStructure", this.form).finally(() => {
        this.loadingSubmit = false;
        this.togglePopup = false;
      });
    },
    editItem(employee) {
      (this.togglePopup = true), (this.edit = true);
      this.form = { ...employee };
    },
    deleteItem(id) {
      this.$store.dispatch("deleteEmployeeFromStructure", id);
    },
  },

  computed: {
    Strucuture() {
      return this.$store.getters.Structure;
    },
  },
};
</script>
