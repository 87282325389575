<template>
  <div class="w-full h-full">
    <nav class="w-full z-50 bg-primary mb-5">
      <div class="container mx-auto px-8 flex items-center justify-start py-2">
        <img
          @click="$router.push('/')"
          src="../../assets/imgs/libyan_labs_logo_dark_bg.png"
          class="cursor-pointer"
          width="65"
          height="65"
          alt=""
          loading="lazy"
        />
        <h1 class="text-right text-white text-xl font-bold p-5">
          اضافة موظف جديد
        </h1>
      </div>
    </nav>
    <div class="w-full h-full mt-5">
      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
          بيانات الموظف
        </span>
        <div
          v-if="Branches && userData.status == 'super-admin'"
          class="p-5 flex items-center w-full md:w-1/2 flex-wrap"
        >
          <label for="branch_id" class="font-medium text-lg w-full text-right"
            >فرع النقابة</label
          >
          <v-select
            v-if="Branches"
            :options="Branches"
            dir="rtl"
            id="branch_id"
            name="branch_id"
            label="name_ar"
            @blur="$v.branch_id.$touch()"
            v-model="branch_id"
            :class="
              defualtInput +
              ($v.branch_id.$error ? ' border-red-700' : 'border-inputBorder')
            "
            class="p-0"
          ></v-select>
          <p
            v-if="$v.branch_id.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            الرجاء تعبئة هذا الحقل
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label for="name_ar" class="font-medium text-lg w-full text-right"
            >الاسم
          </label>
          <input
            id="name_ar"
            name="name_ar"
            v-model="name_ar"
            type="text"
            @blur="$v.name_ar.$touch()"
            required
            :class="
              defualtInput +
              ($v.name_ar.$error ? ' border-red-700' : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.name_ar.$error"
            class="text-red-600 text-md font-medium w-full text-right"
          >
            الرجاء ادخال الاسم
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label for="status" class="font-medium text-lg w-full text-right"
            >المسمى الوظيفي
          </label>
          <v-select
            dir="rtl"
            id="status"
            :options="
              userData.status == 'super-admin'
                ? ['موظف', 'نائب', 'مسؤول']
                : ['موظف']
            "
            name="status"
            v-model="status"
            type="text"
            @blur="$v.status.$touch()"
            required
            :class="
              defualtInput +
              ($v.status.$error ? ' border-red-700' : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.status.$error"
            class="text-red-600 text-md font-medium w-full text-right"
          >
            {{ `الرجاء ادخال المسمى الموظيفي للموظف ${name_ar}` }}
          </p>
        </div>
        <div
          v-if="status == 'مسؤول'"
          class="p-5 flex items-center w-full md:w-1/2 flex-wrap"
        >
          <label for="status" class="font-medium text-lg w-full text-right">
            البريد الالكتروني
          </label>
          <input
            id="email"
            name="email"
            v-model="email"
            type="email"
            @blur="$v.email.$touch()"
            required
            :class="
              defualtInput +
              ($v.email.$error ? ' border-red-700' : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.email.$error"
            class="text-red-600 text-md font-medium w-full text-right"
          >
            ادخل البريد الالكتروني
          </p>
        </div>
        <div
          v-if="status == 'مسؤول'"
          class="p-5 flex items-center w-full md:w-1/2 flex-wrap"
        >
          <label for="status" class="font-medium text-lg w-full text-right">
            كلمة المرور
          </label>
          <input
            id="password"
            name="password"
            v-model="password"
            type="password"
            @blur="$v.password.$touch()"
            required
            :class="
              defualtInput +
              ($v.password.$error ? ' border-red-700' : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.password.$error"
            class="text-red-600 text-md font-medium w-full text-right"
          >
            ادخل كلمة المرور
          </p>
        </div>
      </div>
      <div
        v-if="!successMsg"
        class="flex w-full lg:w-3/4 mx-auto items-center justify-end"
      >
        <router-link
          :to="{ name: 'employeesPanel' }"
          class="
            px-8
            rounded-md
            w-auto
            shadow
            py-2
            focus:outline-none
            hover:shadow-lg
            m-3
            md:m-5
            border border-primary
            text-primary
            bg-white
            text-lg
            font-medium
          "
        >
          رجوع
        </router-link>
        <button
          @click.prevent="submit"
          class="
            px-8
            rounded-md
            w-auto
            shadow
            flex
            items-center
            py-2
            focus:outline-none
            hover:shadow-lg
            m-3
            md:m-5
            bg-primary
            text-white text-lg
            font-medium
          "
        >
          <span>تأكيد </span
          ><i
            v-if="loading"
            class="text-2xl mx-3 bx bx-loader-circle bx-spin"
          ></i>
        </button>
      </div>
      <div
        v-if="successMsg"
        class="
          flex
          justify-between
          items-center
          my-5
          w-full
          lg:w-3/4
          mx-auto
          font-medium
          py-1
          px-2
          rounded-md
          text-green-100
          bg-green-700
          border border-green-700
        "
      >
        <div class="flex items-center">
          <div slot="avatar">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-check-circle w-5 h-5 mx-2"
            >
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
              <polyline points="22 4 12 14.01 9 11.01"></polyline>
            </svg>
          </div>
          <div class="text-xl font-normal text-right">
            <h1 class="py-2 font-medium">
              تم استلام طلبك بنجاح
              <p class="text-sm font-base">
                {{ successMsg }}
              </p>
            </h1>
          </div>
        </div>
        <button
          @click="toggleSuccessMsg"
          class="
            bg-white
            text-green-700
            focus:outline-none
            font-medium
            mx-3
            text-lg
            rounded-md
            px-3
            py-1
          "
        >
          حسناً
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { required, email, requiredIf } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      defualtInput:
        "p-2  bg-white rounded-md border w-full text-right focus-within:border-primary hover:border-primary focus:border-primary  focus:outline-none focus:shadow-md my-1",
      name_ar: "",
      branch_id: "",
      status: "",
      email: "",
      password: "",
      loading: false,
      successMsg: null,
    };
  },
  validations() {
    return {
      name_ar: { required },
      branch_id: { required },
      status: { required },
      email: { email, required: requiredIf(() => this.status == "مسؤول") },
      password: { required: requiredIf(() => this.status == "مسؤول") },
    };
  },
  methods: {
    submit() {
      this.$v.name_ar.$touch();
      this.$v.branch_id.$touch();
      if (
        this.$v.name_ar.$pending ||
        (this.$v.name_ar.$error && this.$v.branch_id.$pending) ||
        (this.$v.branch_id.$error && this.$v.status.$pending) ||
        this.$v.status.$error
      )
        return;
      this.loading = true;
      this.$store
        .dispatch("addEmployee", {
          name_ar: this.name_ar,
          branch_id:
            this.userData.status == "super-admin"
              ? this.branch_id
              : this.userData.branch_id,
          status: this.status,
        })
        .then(() => {
          this.loading = false;
          this.successMsg = `تم اضافة الموظف ${this.name_ar} بنجاح`;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    toggleSuccessMsg() {
      this.successMsg = null;
      this.$router.push({ name: "employeesPanel" });
    },
  },
  created() {
    this.$store.dispatch("getBranches");
    if (this.successMsg) {
      setTimeout(() => {
        this.toggleSuccessMsg();
      }, 5000);
    }
  },
  computed: {
    Branches() {
      return this.$store.getters.getBranches;
    },
    userData() {
      return this.$store.getters.userData;
    },
  },
};
</script>
