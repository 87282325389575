<template>
  <div>
    <div class="container mx-auto px-5 md:px-8">
      <div class="my-8 md:w-11/12 mx-auto">
        <input
          v-if="event.status != 'اكتمل الحدث'"
          id="event_date"
          name="event_date"
          v-model="event.event_date"
          class="w-full rounded-lg px-4 py-2 border mb-5 border-inputBorder"
          placeholder="تاريخ الحدث"
          type="date"
        />

        <!-- title -->
        <div class="w-full">
          <div class="w-full mb-5">
            <h4>عنوان الحدث بالعربي</h4>
            <input
              type="text"
              name="title"
              id="title_ar"
              v-model="event.title_ar"
              class="w-full rounded-lg px-4 py-2 border border-gray-300"
            />
            <p
              v-if="$v.event.title_ar.$error"
              class="text-red-500 text-sm font-medium tracking-wide"
            >
              يجب كتابة عنوان الحدث باللغة العربية
            </p>
          </div>
          <div class="w-full mb-5">
            <h4>عنوان الحدث بالانجليزي</h4>
            <input
              type="text"
              name="title_en"
              id="title_en"
              v-model="event.title_en"
              @blur="$v.event.title_en.$touch()"
              class="w-full rounded-lg px-4 py-2 border border-gray-300"
            />
            <p
              v-if="$v.event.title_en.$error"
              class="text-red-500 text-sm font-medium tracking-wide"
            >
              يجب كتابة عنوان الحدث باللغة الانجليزية
            </p>
          </div>
        </div>

        <!-- description -->

        <div class="w-full">
          <div class="w-full mb-5">
            <h4>وصف الحدث باللغة العربية</h4>
            <textarea
              name="description_ar"
              id="description_ar"
              v-model="event.description_ar"
              class="w-full rounded-lg px-4 py-2 border mb-5 border-gray-300"
            ></textarea>
            <p
              v-if="$v.event.description_ar.$error"
              class="text-red-500 text-sm font-medium tracking-wide"
            >
              يجب كتابة تفاصيل الحدث باللغة الانجليزية
            </p>
          </div>
          <div class="w-full mb-5">
            <h4>وصف الحدث باللغة الانجليزية</h4>
            <textarea
              name="description_en"
              id="description_en"
              v-model="event.description_en"
              class="w-full rounded-lg px-4 py-2 border mb-5 border-gray-300"
            ></textarea>
            <p
              v-if="$v.event.description_en.$error"
              class="text-red-500 text-sm font-medium tracking-wide"
            >
              يجب كتابة تفاصيل الحدث باللغة العربية
            </p>
          </div>
        </div>

        <!--  -->
        <div v-if="event.status == 'اكتمل الحدث'" class="w-full mb-5">
          <h4>الفيديو الخاص بالحدث</h4>
          <input
            name="video_url"
            id="video_url"
            v-model="event.video_url"
            class="w-full rounded-lg px-4 py-2 border border-gray-300"
            :class="
              $v.event.video_url.$error ? 'border-red-500' : 'border-gray-300'
            "
          />
          <p v-if="$v.event.video_url.$error" class="text-red-500">
            الرجاء التحقق من الرابط
          </p>
          <button
            @click="VideoHints = !VideoHints"
            class="underline text-blue-700 text-lg my-1"
          >
            {{ VideoHints ? "اخفاء" : "كيف يمكنني الحصول على رابط الفيديو ؟" }}
          </button>
          <ul v-if="VideoHints" class="list-decimal mx-2">
            <li>
              على الكمبيوتر، انتقِل إلى فيديو YouTube أو قائمة التشغيل التي تريد
              تضمينها.
            </li>
            <li>انقر على رمز المشاركة .</li>
            <li>من قائمة خيارات المشاركة، انقر على تضمين.</li>
            <li>
          من المربّع الذي يظهر، ابحث عن كلمة "src" وانسخ الرابط الموجود بها .
            </li>
            <img class="h-56 w-96 my-1 object-cover border border-secondry" loading="lazy" src="../../../assets/imgs/video_url_hint.png"/>
            <li>الصق الرابط في هذا الحقل.</li>
          </ul>
        </div>

        <!--add images  -->
        <div
          v-if="event.status == 'اكتمل الحدث'"
          class="my-5 flex items-center flex-wrap gap-12"
        >
          <input
            type="file"
            class="hidden"
            ref="uploadImg"
            accept="image/*"
            @change="OnPickerChange"
          />
          <!-- picker -->
          <button
            @click.prevent="pickPicture"
            class="
              flex
              tracking-wider
              items-center
              justify-center
              outline-none
              focus:outline-none
              h-48
              w-48
              rounded-md
              border border-secondry
              text-center
              overflow-hidden
              shadow-sm
            "
          >
            <div class="text-center">
              <i class="bx bxs-image text-5xl text-primary block mb-2"></i>
              <span class="text-gray-600 text-sm block"
                >اضافة صورة من الحدث</span
              >
              <span class="text-gray-600 text-xs block">
                Allowed(png, jpg, svg, jpeg)</span
              >
            </div>
          </button>
          <div
            v-for="(img, index) in event.photos"
            :key="index"
            class="relative h-48 w-48 rounded-md shadow-sm"
          >
            <img
              :src="'https://' + img.path"
              class="h-full w-full object-cover"
              alt=""
              loading="lazy"
            />
            <i
              class="
                bx bx-x
                absolute
                rounded-full
                p-1
                text-white text-lg
                cursor-pointer
              "
              @click="deleteImgSrc(img.id)"
              style="background: rgba(0, 0, 0, 0.7); top: -3px; left: -3px"
            ></i>
          </div>
          <div
            v-for="(img, index) in imgSrc"
            :key="index"
            class="relative h-48 w-48 rounded-md shadow-sm"
          >
            <img
              :src="img"
              class="h-full w-full object-cover"
              alt=""
              loading="lazy"
            />
            <i
              class="
                bx bx-x
                absolute
                rounded-full
                p-1
                text-white text-lg
                cursor-pointer
              "
              @click="deleteImg(index)"
              style="background: rgba(0, 0, 0, 0.7); top: -3px; left: -3px"
            ></i>
          </div>
        </div>

        <!-- submiting section -->
        <div class="flex items-center justify-start">
          <button
            @click.prevent="submit"
            class="
              bg-primary
              px-8
              py-2
              focus:outline-none
              text-white
              flex
              items-center
              text-sm
              my-3
              rounded-md
            "
          >
            <span>تأكيد</span>
            <i v-if="loading" class="mx-2 bx bx-loader-circle bx-spin"></i>
            <i v-else-if="success" class="bx bx-check-double mx-2"></i>
            <i
              v-else
              class="mx-2 bx bx-send transition-all transform rotate-180"
            ></i>
          </button>
          <button
            @click="$emit('undo')"
            class="mx-4 px-5 py-2 rounded-md text-white bg-gray-600"
          >
            الغاء
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, url } from "vuelidate/lib/validators";

export default {
  name: "editEvent",
  props: ["event_id", "event"],
  data() {
    return {
      success: false,
      errorMsg: "",
      loading: false,
      addImage: false,
      images: [],
      imgSrc: [],
      VideoHints: false,
    };
  },
  validations: {
    event: {
      title_ar: { required },
      title_en: { required },
      description_ar: { required },
      description_en: { required },
      event_date: { required },
      video_url: { url },
    },
  },
  methods: {
    pickPicture() {
      this.$refs.uploadImg.click();
    },
    validateImage(e) {
      if (!e.target.files.length) return;
      const file = e.target.files[0].type;
      if (
        file == "image/png" ||
        file == "image/jpeg" ||
        file == "image/jpg" ||
        file == "image/svg+xml"
      ) {
        return true;
      } else {
        let message = [
          "(png, jpg, svg, jpeg) هذا الملف غير صالح ، الرجاء تحميل الملف بصغة  ",
        ];
        this.$store.commit("toggleErrorMsg", message);
        return false;
      }
    },
    OnPickerChange(e) {
      if (this.validateImage(e)) {
        let file = e.target.files[0];
        this.images.push(file);
        this.imgSrc.push(URL.createObjectURL(e.target.files[0]));
      }
    },
    deleteImg(index) {
      this.imgSrc.splice(index, 1);
      this.images.splice(index, 1);
    },
    deleteImgSrc(imgID) {
      this.$axios.post(`/api/removePhoto/${imgID}`).then(() => {
        this.event.photos = this.event.photos.filter((img) => img.id != imgID);
      });
    },
    submit() {
      this.$v.event.$touch();
      if (this.$v.event.$error || this.$v.event.$pending) {
        this.errorMsg = "يجب تعبئة جمعيع الحقول قبل التأكيد";
        return;
      }
      this.errorMsg = "";
      this.loading = true;
      let form = new FormData();
      form.append("_method", "put");
      let ev = {
        ...this.event,
        event_date: this.$moment(this.event.event_date).format("YYYY-MM-DD"),
      };
      delete ev.photos;
      for (const [key, value] of Object.entries(ev)) {
        if (value && value != "null") {
          form.append(key, value);
        }
      }
      this.images.forEach((img) => {
        form.append("photos[]", img);
      });

      this.$axios.post(`/api/events/${this.event.id}`, form).then(() => {
        this.loading = false;
        this.$router.push({ name: "Events" });
      });
    },
  },
};
</script>

<style>
</style>