import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/axios';

Vue.use(Vuex);

import newRegistration from './modules/newRegistration';
import getDate from './modules/getData';
import Auth from './modules/Auth';
import status from './modules/changeStatus';

export default new Vuex.Store({
	state: {
		successMsg: null,
		editDoctor: null,
		editCompany: null,
		editLab: null,
		errorMsg: null,
		Dialog: null,
		EmailNotification: false,
		toggleResetPassword: false,
		editBranch: null,
		SavedImageSrc: null,
		participantEmail: null
	},
	mutations: {
		toggleSuccessMsg: (state, payload) => {
			state.successMsg = payload;
		},
		editDoctor: (state, payload) => {
			state.editDoctor = payload;
		},
		editCompany: (state, payload) => {
			state.editCompany = payload;
		},
		editLab: (state, payload) => {
			state.editLab = payload;
		},
		editBranch: (state, payload) => {
			state.editBranch = payload;
		},
		toggleErrorMsg: (state, payload) => {
			state.errorMsg = payload;
		},
		toggleDialog: (state, payload) => {
			state.Dialog = payload;
		},
		sendEmailNotification: (state, payload) => {
			state.EmailNotification = !state.EmailNotification;
			state.participantEmail = payload
		},
		toggleResetPassword: (state) => {
			state.toggleResetPassword = !state.toggleResetPassword;
		},
		SavedImageSrc: (state, payload) => {
			state.SavedImageSrc = payload;
		},
	},
	actions: {
		sendEmailNotifivationMethods({ getters }, payload) {
			if (getters.userData) {
				return new Promise((resolve, reject) => {
					axios
						.post('/api/sendEmail', payload)
						.then((res) => {
							resolve(res);
						})
						.catch((err) => {
							reject(err.response);
						});
				});
			}
		},
	},
	getters: {
		successMsg(state) {
			return state.successMsg;
		},
		editDoctor(state) {
			return state.editDoctor;
		},
		editCompany(state) {
			return state.editCompany;
		},
		editLab(state) {
			return state.editLab;
		},
		editBranch(state) {
			return state.editBranch;
		},
		errorMsg(state) {
			return state.errorMsg;
		},
		Dialog(state) {
			return state.Dialog;
		},
		sendEmailNotification(state) {
			return state.EmailNotification;
		},
		toggleResetPassword(state) {
			return state.toggleResetPassword;
		},
		SavedImageSrc(state) {
			return state.SavedImageSrc;
		},
		participantEmail(state) {
			return state.participantEmail
		}
	},
	modules: { newRegistration, getDate, Auth, status },
});
