import axios from "@/axios";

const Auth = {
    state: () => ({
        userData: null,
    }),
    mutations: {
        login: (state, payload) => {
            state.userData = payload;
        },
        logout: (state) => {
            state.userData = null;
        },
    },
    actions: {
        is_logger_in({ state, commit }) {
            return new Promise((resolve, reject) => {
                if (state.userData) {
                    resolve(state.userData);
                } else {
                    axios
                        .post("/api/is_logged_in")
                        .then((res) => {
                            commit("login", res.data.user ? res.data.user : null);
                            resolve(res);
                        })
                        .catch((err) => reject(err));
                }
            });
        },
        login({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.get("/sanctum/csrf-cookie").then(() => {
                    axios({ url: "/api/login", method: "post", data: payload })
                        .then(({ data }) => {
                            resolve(data);
                            commit("login", data.user);
                        })
                        .catch((err) => {
                            reject(err.response.data);
                        });
                });
            });
        },
        forgotPassword({ state }, payload) {
            if (!state) return
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/forgot-password", { email: payload })
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        resetpassword({ state }, payload) {
            if (!state) return
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/reset-password", payload)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        resetCurrentPassword({ state }, payload) {
            if (!state) return
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/user/changePassword", payload)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            })
        },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/logout")
                    .then((res) => {
                        resolve(res);
                        commit("logout");
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
    getters: {
        userData(state) {
            return state.userData;
        },
    },
};

export default Auth;