<template>
  <div class="py-12 container mx-auto px-5 lg:px-12">
    <div class="flex items-center mb-12">
      <button
        @click="$router.push('/News')"
        class="
          text-2xl
          bg-gray-100
          h-12
          w-12
          flex
          items-center
          justify-center
          rounded-full
        "
      >
        <i class="bx bx-right-arrow-alt"></i>
      </button>
      <h1 class="text-h1Text text-3xl">
        <span class="mx-3">اضافة خبر جديد</span>
      </h1>
    </div>

    <form class="my-5" @submit.prevent="submit">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
        <div class="w-full mb-5">
          <h4>عنوان الخبر بالعربي</h4>
          <input
            type="text"
            name="title_ar"
            id="title_ar"
            v-model="form.title_ar"
            @blur="$v.form.title_ar.$touch()"
            class="w-full rounded-lg px-4 py-2 border"
            placeholder=""
            :class="
              $v.form.title_ar.$error ? 'border-red-500' : 'border-gray-300'
            "
          />
          <p
            v-if="$v.form.title_ar.$error"
            class="text-red-500 text-sm font-medium tracking-wide"
          >
            يجب كتابة عنوان الخبر باللغة العربية
          </p>
        </div>
        <div class="w-full mb-5">
          <h4>عنوان الخبر بالانجليزي</h4>
          <input
            type="text"
            name="title_en"
            id="title_en"
            v-model="form.title_en"
            @blur="$v.form.title_en.$touch()"
            class="w-full rounded-lg px-4 py-2 border"
            placeholder=" "
            :class="
              $v.form.title_en.$error ? 'border-red-500' : 'border-gray-300'
            "
          />
          <p
            v-if="$v.form.title_en.$error"
            class="text-red-500 text-sm font-medium tracking-wide"
          >
            يجب كتابة عنوان الخبر باللغة الانجليزية
          </p>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
        <div class="w-full mb-5">
          <h4>المجموعة بالعربي</h4>
          <input
            type="text"
            name="category_ar"
            id="category_ar"
            v-model="form.category_ar"
            @blur="$v.form.category_ar.$touch()"
            class="w-full rounded-lg px-4 py-2 border"
            :class="
              $v.form.category_ar.$error ? 'border-red-500' : 'border-gray-300'
            "
          />
          <p
            v-if="$v.form.category_ar.$error"
            class="text-red-500 text-sm font-medium tracking-wide"
          >
            يجب كتابة المجموعة التي تصنف بها الخبر باللغة العربية
          </p>
        </div>
        <div class="w-full mb-5">
          <h4>المجموعة بالانجليزي</h4>
          <input
            type="text"
            name="category_en"
            id="category_en"
            v-model="form.category_en"
            @blur="$v.form.category_en.$touch()"
            class="w-full rounded-lg px-4 py-2 border"
            placeholder=""
            :class="
              $v.form.category_en.$error ? 'border-red-500' : 'border-gray-300'
            "
          />
          <p
            v-if="$v.form.category_en.$error"
            class="text-red-500 text-sm font-medium tracking-wide"
          >
            يجب كتابة المجموعة التي تصنف بها الخبر باللغة الانجليزية
          </p>
        </div>
      </div>

      <div class="my-5">
        <h4>محتوى الخبر بالعربي</h4>
        <vue-editor
          v-model="form.description_ar"
          placeholder=""
          :editor-toolbar="customToolbar"
        ></vue-editor>
      </div>
      <div class="my-5">
        <h4>محتوي الخبر بالانجليزي</h4>
        <vue-editor
          v-model="form.description_en"
          :editor-toolbar="customToolbar"
        ></vue-editor>
      </div>
      <div
        class="
          border
          rounded-md
          border-gray-300
          z-20
          text-gray-600
          bg-bgcolor
          px-5
          py-3
          -mt-2
          w-full
        "
      >
        <input
          type="file"
          class="hidden"
          ref="uploadImg"
          accept="image/*"
          @change="OnPickerChange"
        />
        <button
          @click.prevent="pickPicture"
          class="
            flex
            tracking-wider
            w-full
            items-center
            outline-none
            focus:outline-none
          "
        >
          <i class="bx bxs-image mx-2 text-primary"></i>
          <span class="ml-2 text-gray-600 text-sm"
            >اضافة صورة (Allowed: png, jpg)</span
          >
        </button>
        <div v-if="imgSrc" class="w-36 max-h-48 rounded-md relative my-5">
          <img
            :src="imgSrc"
            class="w-full h-full rounded-md object-cover"
            alt=""
            loading="lazy"
          />
          <button
            @click="
              imgSrc = null;
              form.image = null;
            "
            class="
              h-4
              w-4
              rounded-full
              p-2
              text-center text-white
              absolute
              -top-1
              -left-1
              flex
              items-center
              justify-center
            "
            style="background: rgba(0, 0, 0, 0.5)"
          >
            <span class="transform rotate-45 text-2xl">+</span>
          </button>
        </div>
      </div>
      <p
        v-if="errorMsg"
        class="
          my-5
          bg-redd-100
          text-red-600
          border-red-600
          py-2
          font-medium
          tracking-wide
          leading-relaxed
          px-5
          w-full
          border
          rounded-md
        "
      >
        {{ errorMsg }}
      </p>
      <p
        v-if="success"
        class="
          my-5
          bg-green-100
          text-green-600
          border-green-600
          py-2
          font-medium
          tracking-wide
          leading-relaxed
          px-5
          w-full
          border
          rounded-md
        "
      >
        <span>تم إضافة الخبر بنجاح</span>
        <button class="font-bold mx-1" @click="$router.back()">رجوع</button>
      </p>
      <button
        @click.prevent="submit"
        :disabled="loading"
        class="
          bg-primary
          px-8
          py-2
          focus:outline-none
          text-white
          flex
          items-center
          text-sm
          my-3
          rounded-md
        "
      >
        <span v-if="!loading">تأكيد</span>
        <i v-if="loading" class="mx-2 bx text-xl bx-loader-circle bx-spin"></i>
        <i v-else-if="success" class="bx bx-check-double mx-2"></i>
        <i
          v-else
          class="mx-2 bx bx-send transition-all transform rotate-180"
        ></i>
      </button>
    </form>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { VueEditor } from "vue2-editor";

export default {
  name: "AddNews",
  data() {
    return {
      form: {
        title_ar: "",
        title_en: "",
        description_ar: "",
        description_en: "",
        category_ar: "",
        category_en: "",
        image: "",
      },
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      imgSrc: "",
      success: false,
      errorMsg: "",
      loading: false,
    };
  },
  validations: {
    form: {
      title_ar: { required },
      title_en: { required },
      description_ar: { required },
      description_en: { required },
      category_ar: { required },
      category_en: { required },
      image: { required },
    },
  },
  methods: {
    pickPicture() {
      this.$refs.uploadImg.click();
    },
    OnPickerChange(e) {
      if (!e.target.files.length) return;
      let file = event.target.files[0];
      this.form.image = file;
      this.imgSrc = URL.createObjectURL(e.target.files[0]);
    },
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error || this.$v.form.$pending) {
        this.errorMsg = "يجب تعبئة جمعيع الحقول قبل التأكيد";
        return;
      }
      this.errorMsg = "";
      this.loading = true;
      let form = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        form.append(key, value);
      }
      this.$store
        .dispatch("addPostToNews", form)
        .then(() => {
          this.loading = false;
          this.success = true;

          this.form = {
            title_ar: "",
            title_en: "",
            description_ar: "",
            description_en: "",
            category_ar: "",
            category_en: "",
            image: "",
          };
          this.imgSrc = "";
          this.$v.form.$reset();
          this.$store.dispatch("fetchNews");
        })
        .catch(() => {
          this.errorMsg = "لقد حدث خطأ ما";
          this.loading = false;
        });
    },
  },
  components: {
    VueEditor,
  },
};
</script>