<template>
  <transition name="slideTop">
    <div
      v-if="animate"
      class="bg-white shadow-lg rounded-lg z-30 p-8 w-3/4 md:w-1/2 lg:w-1/3"
    >
      <div class="flex items-center justify-between mb-5">
        <h3 class="text-gray-700 text-lg font-medium">
          الرجاء إدخال البريد الإلكتروني وكلمة المرور الخاصة بالمسؤول الجديد
        </h3>
        <i
          @click="clearDialog"
          class="bx bx-x text-gray-500 cursor-pointer text-2xl"
        ></i>
      </div>
      <input
        type="email"
        required
        @blur="$v.form.adminEmail.$touch()"
        :class="
          $v.form.adminEmail.$error ? ' border-red-700' : 'border-inputBorder'
        "
        class="
          w-full
          pl-10
          pr-4
          py-2
          rounded-lg
          border
          focus:outline-none
          focus:shadow-outline
          text-gray-600
          hover:border-primary
          focus:border-primary
          font-medium
        "
        placeholder="البريد الالكتروني"
        v-model="form.adminEmail"
      />
      <p v-if="$v.form.adminEmail.$error" class="text-red-600 text-sm my-2">
        الرجاء تعبئة هذا الحقل
      </p>

      <input
        type="password"
        required
        @blur="$v.form.password.$touch()"
        :class="
          $v.form.password.$error ? ' border-red-700' : 'border-inputBorder'
        "
        class="
          w-full
          pl-10
          pr-4
          py-2
          mt-5
          rounded-lg
          border
          focus:outline-none
          focus:shadow-outline
          text-gray-600
          hover:border-primary
          focus:border-primary
          font-medium
        "
        placeholder="كلمة المرور"
        v-model="form.password"
      />
      <p v-if="$v.form.password.$error" class="text-red-600 text-sm my-2">
        الرجاء تعبئة هذا الحقل
      </p>
      <button
        @click="changeToAdmin"
        class="
          bg-primary
          mt-3
          flex
          items-center
          rounded-md
          my-2
          px-4
          py-1
          text-lg
          focus:outline-none
          text-white
        "
      >
        <span>تأكيد</span
        ><i
          v-if="loading"
          class="text-2xl mx-2 bx bx-loader-circle bx-spin"
        ></i>
      </button>
      <div
        v-if="success"
        class="
          flex
          justify-center
          items-center
          m-1
          font-medium
          py-1
          px-2
          rounded-md
          text-green-100
          bg-green-700
          border border-green-700
        "
      >
        <div slot="avatar">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-check-circle w-5 h-5 mx-2"
          >
            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
            <polyline points="22 4 12 14.01 9 11.01"></polyline>
          </svg>
        </div>
        <div class="text-xl font-normal text-right">
          <h1 class="py-2 font-medium">
            {{
              `تم ترقية الموظف ${dialog.name_ar} الى مدير فرع ${dialog.branch.name_ar}`
            }}
          </h1>
        </div>
        <div class="flex flex-auto">
          <button
            @click="toggleSuccessMsg"
            class="
              bg-white
              flex
              items-center
              text-green-700
              focus:outline-none
              font-medium
              mx-3
              text-lg
              rounded-md
              px-3
              py-1
            "
          >
            <span>حسناً</span>
            <i
              v-if="loadingSuccess"
              class="text-2xl mx-2 bx bx-loader-circle bx-spin"
            ></i>
          </button>
        </div>
      </div>
      <div v-if="errorMsg">
        <p
          v-for="err in errorMsg"
          :key="err"
          class="
            px-4
            py-2
            my-2
            border
            rounded-md
            border-red-600
            bg-red-100
            text-red-600
            font-medium
          "
        >
          {{ err }}
        </p>
      </div>
    </div>
  </transition>
</template>
<script>
import { required, email, minLength } from "vuelidate/lib/validators";
export default {
  name: "employeeEmail",
  data() {
    return {
      form: {
        adminEmail: null,
        password: null,
      },
      loading: false,
      loadingSuccess: false,
      success: false,
      errorMsg: null,
      animate: false,
    };
  },
  validations: {
    form: {
      adminEmail: {
        required,
        email,
      },
      password: { required, minLength: minLength(6) },
    },
  },
  computed: {
    dialog() {
      return this.$store.getters.Dialog;
    },
  },
  methods: {
    changeToAdmin() {
      this.$v.form.$touch();
      if (this.$v.form.$pending || this.$v.form.$error) return;
      this.errorMsg = null;
      this.loading = true;
      this.$store
        .dispatch("changeEmployeeToAdmin", {
          email: this.form.adminEmail,
          password: this.form.password,
          id: this.dialog.id,
        })
        .then(() => {
          this.success = true;
          this.loading = false;
        })
        .catch(({ data }) => {
          this.loading = false;
          this.errorMsg = data[0];
            // data.errors.email[0] == "The email has already been taken."
            //   ? " البريد الالكتروني مستخدم من قبل احدى مدراء الفروع"
            //   : data.errors.email[0];
          setTimeout(() => {
            this.errorMsg = null;
          }, 12000);
        });
    },
    toggleSuccessMsg() {
      this.errorMsg = null;
      this.loadingSuccess = true;
      this.$emit("fetchData");
    },
    clearDialog() {
      this.form.adminEmail = null;
      this.errorMsg = null;
      this.success = false;
      this.loadingSuccess = false;
      this.$store.commit("toggleDialog", null);
    },
  },

  created() {
    setTimeout(() => {
      this.animate = true;
    }, 10);
    document.addEventListener("keyup", (evt) => {
      if (evt.keyCode === 27) {
        this.clearDialog();
      }
    });
  },
};
</script>
