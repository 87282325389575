<template>
  <div>
    <div
      class="flex flex-wrap w-full p-5 mx-3 mt-8 border rounded-md  releative lg:w-3/4 lg:mx-auto border-formBorderColor"
    >
      <span class="absolute -mt-8 text-lg font-bold bg-white text-primary">
        نسخة من المستندات المطلوبة
      </span>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="statement_of_graduate"
          class="flex items-center w-full text-lg font-medium text-right"
          >صورة ضوئية طبق الاصل "سكانر" من افادة التخرج معتمدة من المؤسسات
          التعليمية</label
        >
        <input
          :class="defualtInput"
          class="hidden"
          ref="statement_of_graduate"
          id="statement_of_graduate"
          name="statement_of_graduate"
          accept="image/*,.pdf"
          @change="statement_of_graduateFunc"
          type="file"
          required
        />
        <div class="relative flex items-center w-full">
          <button
            @click="pickstatement_of_graduateFunc"
            class="absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none"
          >
            اختر ملف
          </button>
          <input
            @click="pickstatement_of_graduateFunc"
            type="text"
            class="pr-20 border-inputBorder"
            placeholder="لم يتم اختيار ملف"
            :value="
              form.statement_of_graduate
                ? form.statement_of_graduate.name || form.statement_of_graduate
                : null
            "
          />
        </div>
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="better_from_education_department"
          class="flex items-center w-full text-lg font-medium text-right"
          >رسالة من ادارة التعليم الخاص بوزارة التعليم لخريجي القطاع الخاص
          (دبلوم عالي - بكالوريس)</label
        >
        <input
          :class="defualtInput"
          class="hidden"
          ref="better_from_education_department"
          id="better_from_education_department"
          name="better_from_education_department"
          accept="image/*,.pdf"
          @change="better_from_education_departmentFunc"
          type="file"
          required
        />
        <div class="relative flex items-center w-full">
          <button
            @click="pickbetter_from_education_departmentFunc"
            class="absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none"
          >
            اختر ملف
          </button>
          <input
            @click="pickbetter_from_education_departmentFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            placeholder="لم يتم اختيار ملف"
            class="pr-20"
            :value="
              form.better_from_education_department
                ? form.better_from_education_department.name ||
                  form.better_from_education_department
                : null
            "
          />
        </div>
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="better_from_quality_department"
          class="w-full text-lg font-medium text-right"
          >رسالة من ادارة الجودة بما يفيد معادلة الشهادة للدارسين خارج
          ليبيا</label
        >
        <input
          :class="defualtInput"
          class="hidden"
          ref="better_from_quality_department"
          id="better_from_quality_department"
          name="better_from_quality_department"
          accept="image/*,.pdf"
          @change="better_from_quality_departmentFunc"
          type="file"
          required
        />
        <div class="relative flex items-center w-full">
          <button
            @click="pickbetter_from_quality_departmentFunc"
            class="absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none"
          >
            اختر ملف
          </button>
          <input
            @click="pickbetter_from_quality_departmentFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            placeholder="لم يتم اختيار ملف"
            class="pr-20"
            :value="
              form.better_from_quality_department
                ? form.better_from_quality_department.name ||
                  form.better_from_quality_department
                : null
            "
          />
        </div>
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="birth_certificate"
          class="flex items-center w-full text-lg font-medium text-right"
          >شهادة ميلاد مع الرقم الوطني معتمدة</label
        >
        <input
          :class="defualtInput"
          class="hidden"
          ref="birth_certificate"
          id="birth_certificate"
          name="birth_certificate"
          accept="image/*,.pdf"
          @change="birth_certificateFunc"
          type="file"
          required
        />
        <div class="relative flex items-center w-full">
          <button
            @click="pickbirth_certificateFunc"
            class="absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none"
          >
            اختر ملف
          </button>
          <input
            @click="pickbirth_certificateFunc"
            type="text"
            placeholder="لم يتم اختيار ملف"
            class="pr-20 border-inputBorder"
            :value="
              form.birth_certificate
                ? form.birth_certificate.name || form.birth_certificate
                : null
            "
          />
        </div>
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="proof_of_identity"
          class="flex items-center w-full text-lg font-medium text-right"
        >
          صورة اثبات الهوية (جواز السفر - البطاقة الشخصية) سارية الصلاحية</label
        >
        <input
          :class="defualtInput"
          class="hidden"
          ref="proof_of_identity"
          id="proof_of_identity"
          name="proof_of_identity"
          accept="image/*,.pdf"
          @change="proof_of_identityFunc"
          type="file"
          required
        />
        <div class="relative flex items-center w-full">
          <button
            @click="pickproof_of_identityFunc"
            class="absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none"
          >
            اختر ملف
          </button>
          <input
            @click="pickproof_of_identityFunc"
            type="text"
            placeholder="لم يتم اختيار ملف"
            class="pr-20 border-inputBorder"
            :value="
              form.proof_of_identity
                ? form.proof_of_identity.name || form.proof_of_identity
                : null
            "
          />
        </div>
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="personal_image"
          class="flex items-center w-full text-lg font-medium text-right"
          >صورة شخصية</label
        >
        <input
          class="hidden"
          ref="personal_image"
          id="personal_image"
          name="personal_image"
          accept="image/*,.pdf"
          @change="personal_imageFunc"
          type="file"
          required
        />
        <div class="relative flex items-center w-full">
          <button
            @click="pickpersonal_imageFunc"
            class="absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none"
          >
            اختر ملف
          </button>
          <input
            @click="pickpersonal_imageFunc"
            type="text"
            placeholder="لم يتم اختيار ملف"
            class="pr-20 border-inputBorder"
            :value="
              form.personal_image
                ? form.personal_image.name || form.personal_image
                : null
            "
          />
        </div>
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="image_of_subscription"
          class="flex items-center w-full text-lg font-medium text-right"
        >
          صورة من اشتراك النقابة
        </label>
        <input
          :class="defualtInput"
          class="hidden"
          ref="image_of_subscription"
          id="image_of_subscription"
          name="image_of_subscription"
          accept="image/*,.pdf"
          @change="image_of_subscriptionFunc"
          type="file"
          required
        />
        <div class="relative flex items-center w-full">
          <button
            @click="pickimage_of_subscriptionFunc"
            class="absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none"
          >
            اختر ملف
          </button>
          <input
            @click="pickimage_of_subscriptionFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            placeholder="لم يتم اختيار ملف"
            class="pr-20"
            :value="
              form.image_of_subscription
                ? form.image_of_subscription.name || form.image_of_subscription
                : null
            "
          />
        </div>
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="copy_of_a_valid_license_to_practice_the_profession"
          class="flex items-center w-full text-lg font-medium text-right"
        >
          صورة من اذن مزاولة المهنة سارية الصلاحية
        </label>
        <input
          :class="defualtInput"
          class="hidden"
          ref="copy_of_a_valid_license_to_practice_the_profession"
          id="copy_of_a_valid_license_to_practice_the_profession"
          name="copy_of_a_valid_license_to_practice_the_profession"
          accept="image/*,.pdf"
          @change="copy_of_a_valid_license_to_practice_the_professionFunc"
          type="file"
          required
        />
        <div class="relative flex items-center w-full">
          <button
            @click="pickcopy_of_a_valid_license_to_practice_the_professionFunc"
            class="absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none"
          >
            اختر ملف
          </button>
          <input
            @click="pickcopy_of_a_valid_license_to_practice_the_professionFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            placeholder="لم يتم اختيار ملف"
            class="pr-20"
            :value="
              form.copy_of_a_valid_license_to_practice_the_profession
                ? form.copy_of_a_valid_license_to_practice_the_profession
                    .name ||
                  form.copy_of_a_valid_license_to_practice_the_profession
                : null
            "
          />
        </div>
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="former_permission_of_company"
          class="flex items-center w-full text-lg font-medium text-right"
          >صورة من اذن فتح الشركة السابق</label
        >
        <input
          class="hidden"
          ref="former_permission_of_company"
          id="former_permission_of_company"
          name="former_permission_of_company"
          accept="image/*,.pdf"
          @change="former_permission_of_companyFunc"
          type="file"
          required
        />
        <div class="relative flex items-center w-full">
          <button
            @click="pickformer_permission_of_companyFunc"
            class="absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none"
          >
            اختر ملف
          </button>
          <input
            @click="pickformer_permission_of_companyFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            placeholder="لم يتم اختيار ملف"
            class="pr-20"
            :value="
              form.former_permission_of_company
                ? form.former_permission_of_company.name ||
                  form.former_permission_of_company
                : null
            "
          />
        </div>
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="experience_certificate"
          class="flex items-center w-full text-lg font-medium text-right"
          >خبرة عمل في مجال المختبرات الطبية لا تقل عن 5 سنوات , صورة من شهادة
          الخبرة</label
        >
        <input
          class="hidden"
          ref="experience_certificate"
          id="experience_certificate"
          name="experience_certificate"
          accept="image/*,.pdf"
          @change="experience_certificateFunc"
          type="file"
          required
        />
        <div class="relative flex items-center w-full">
          <button
            @click="pickexperience_certificateFunc"
            class="absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none"
          >
            اختر ملف
          </button>
          <input
            @click="pickexperience_certificateFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            placeholder="لم يتم اختيار ملف"
            class="pr-20"
            :value="
              form.experience_certificate
                ? form.experience_certificate.name ||
                  form.experience_certificate
                : null
            "
          />
        </div>
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="contract_of_establishment_of_company"
          class="flex items-center w-full text-lg font-medium text-right"
          >عقد تأسيس الشركة
        </label>
        <input
          class="hidden"
          ref="contract_of_establishment_of_company"
          id="contract_of_establishment_of_company"
          name="contract_of_establishment_of_company"
          accept="image/*,.pdf"
          @change="contract_of_establishment_of_companyFunc"
          type="file"
          required
        />
        <div class="relative flex items-center w-full">
          <button
            @click="pickcontract_of_establishment_of_companyFunc"
            class="absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none"
          >
            اختر ملف
          </button>
          <input
            @click="pickcontract_of_establishment_of_companyFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            placeholder="لم يتم اختيار ملف"
            class="pr-20"
            :value="
              form.contract_of_establishment_of_company
                ? form.contract_of_establishment_of_company.name ||
                  form.contract_of_establishment_of_company
                : null
            "
          />
        </div>
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="minutes_of_the_meeting"
          class="flex items-center w-full text-lg font-medium text-right"
        >
          محضر الاجتماع
        </label>
        <input
          class="hidden"
          ref="minutes_of_the_meeting"
          id="minutes_of_the_meeting"
          name="minutes_of_the_meeting"
          accept="image/*,.pdf"
          @change="minutes_of_the_meetingFunc"
          type="file"
          required
        />
        <div class="relative flex items-center w-full">
          <button
            @click="pickminutes_of_the_meetingFunc"
            class="absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none"
          >
            اختر ملف
          </button>
          <input
            @click="pickminutes_of_the_meetingFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            placeholder="لم يتم اختيار ملف"
            class="pr-20"
            :value="
              form.minutes_of_the_meeting
                ? form.minutes_of_the_meeting.name ||
                  form.minutes_of_the_meeting
                : null
            "
          />
        </div>
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="the_general_assembly"
          class="flex items-center w-full text-lg font-medium text-right"
        >
          الجمعية العمومية</label
        >
        <input
          class="hidden"
          ref="the_general_assembly"
          id="the_general_assembly"
          name="the_general_assembly"
          accept="image/*,.pdf"
          @change="the_general_assemblyFunc"
          type="file"
          required
        />
        <div class="relative flex items-center w-full">
          <button
            @click="pickthe_general_assemblyFunc"
            class="absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none"
          >
            اختر ملف
          </button>
          <input
            @click="pickthe_general_assemblyFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            placeholder="لم يتم اختيار ملف"
            class="pr-20"
            :value="
              form.the_general_assembly
                ? form.the_general_assembly.name || form.the_general_assembly
                : null
            "
          />
        </div>
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="commercial_record"
          class="flex items-center w-full text-lg font-medium text-right"
          >السجل التجاري</label
        >
        <input
          class="hidden"
          ref="commercial_record"
          id="commercial_record"
          name="commercial_record"
          accept="image/*,.pdf"
          @change="commercial_recordFunc"
          type="file"
          required
        />
        <div class="relative flex items-center w-full">
          <button
            @click="pickcommercial_recordFunc"
            class="absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none"
          >
            اختر ملف
          </button>
          <input
            @click="pickcommercial_recordFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            placeholder="لم يتم اختيار ملف"
            class="pr-20"
            :value="
              form.commercial_record
                ? form.commercial_record.name || form.commercial_record
                : null
            "
          />
        </div>
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="chamber_of_commerce"
          class="flex items-center w-full text-lg font-medium text-right"
          >الغرفة التجارية</label
        >
        <input
          class="hidden"
          ref="chamber_of_commerce"
          id="chamber_of_commerce"
          name="chamber_of_commerce"
          accept="image/*,.pdf"
          @change="chamber_of_commerceFunc"
          type="file"
          required
        />
        <div class="relative flex items-center w-full">
          <button
            @click="pickchamber_of_commerceFunc"
            class="absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none"
          >
            اختر ملف
          </button>
          <input
            @click="pickchamber_of_commerceFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            placeholder="لم يتم اختيار ملف"
            class="pr-20"
            :value="
              form.chamber_of_commerce
                ? form.chamber_of_commerce.name || form.chamber_of_commerce
                : null
            "
          />
        </div>
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="former_license"
          class="w-full text-lg font-medium text-right"
          >صورة من الترخيص الممنوح سابقا لمزاولة النشاط (ان وجد)</label
        >
        <input
          class="hidden"
          ref="former_license"
          id="former_license"
          name="former_license"
          accept="image/*,.pdf"
          @change="former_licenseFunc"
          type="file"
          required
        />
        <div class="relative flex items-center w-full">
          <button
            @click="pickformer_licenseFunc"
            class="absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none"
          >
            اختر ملف
          </button>
          <input
            @click="pickformer_licenseFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            placeholder="لم يتم اختيار ملف"
            class="pr-20"
            :value="
              form.former_license
                ? form.former_license.name || form.former_license
                : null
            "
          />
        </div>
      </div>
    </div>
    <div class="flex items-center justify-end w-full mx-auto lg:w-3/4">
      <p v-if="$v.form.$error" class="font-medium text-red-600 text-md">
        الرجاء تكملة الحقول المطلوبة
      </p>
      <button
        @click="$router.go(-1)"
        class="w-auto px-8 py-2 m-3 text-lg font-medium bg-white border rounded-md shadow  focus:outline-none hover:shadow-lg md:m-5 border-primary text-primary"
      >
        رجوع
      </button>
      <button
        @click.prevent="submit"
        class="flex items-center w-auto px-8 py-2 m-3 text-lg font-medium text-white rounded-md shadow  focus:outline-none hover:shadow-lg md:m-5 bg-primary"
      >
        <span>تأكيد </span
        ><i
          v-if="loading"
          class="mx-2 text-2xl bx bx-loader-circle bx-spin"
        ></i>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      defualtInput:
        "p-2  bg-white rounded-md border w-full text-right focus-within:border-primary hover:border-primary focus:border-primary focus:outline-none focus:shadow-md my-1",
      loading: false,
      form: {
        statement_of_graduate: "",
        better_from_education_department: "",
        better_from_quality_department: "",
        birth_certificate: "",
        proof_of_identity: "",
        personal_image: "",
        image_of_subscription: "",
        minutes_of_the_meeting: "",
        the_general_assembly: "",
        copy_of_a_valid_license_to_practice_the_profession: "",
        former_permission_of_company: "",
        experience_certificate: "",
        contract_of_establishment_of_company: "",
        commercial_record: "",
        chamber_of_commerce: "",
        former_license: "",
      },
    };
  },

  computed: {
    editCompany() {
      return this.$store.getters.editCompany;
    },
    companyData() {
      return this.$store.getters.companyData;
    },
  },
  created() {
    if (this.companyData) {
      this.form = { ...this.form, ...this.companyData };
    }
  },
  mounted() {
    if (this.editCompany) {
      this.form = {
        statement_of_graduate:
          this.editCompany.photos.statement_of_graduate.length > 0
            ? this.editCompany.photos.statement_of_graduate[0].name
            : "",
        better_from_education_department:
          this.editCompany.photos.better_from_education_department.length > 0
            ? this.editCompany.photos.better_from_education_department[0].name
            : "",
        better_from_quality_department:
          this.editCompany.photos.better_from_quality_department.length > 0
            ? this.editCompany.photos.better_from_quality_department[0].name
            : "",
        birth_certificate:
          this.editCompany.photos.birth_certificate.length > 0
            ? this.editCompany.photos.birth_certificate[0].name
            : "",
        minutes_of_the_meeting:
          this.editCompany.photos.minutes_of_the_meeting.length > 0
            ? this.editCompany.photos.minutes_of_the_meeting[0].name
            : "",
        proof_of_identity:
          this.editCompany.photos.proof_of_identity.length > 0
            ? this.editCompany.photos.proof_of_identity[0].name
            : "",
        the_general_assembly:
          this.editCompany.photos.the_general_assembly.length > 0
            ? this.editCompany.photos.the_general_assembly[0].name
            : "",
        personal_image:
          this.editCompany.photos.personal_image.length > 0
            ? this.editCompany.photos.personal_image[0].name
            : "",
        copy_of_a_valid_license_to_practice_the_profession:
          this.editCompany.photos
            .copy_of_a_valid_license_to_practice_the_profession.length > 0
            ? this.editCompany.photos
                .copy_of_a_valid_license_to_practice_the_profession[0].name
            : "",
        image_of_subscription:
          this.editCompany.photos.image_of_subscription.length > 0
            ? this.editCompany.photos.image_of_subscription[0].name
            : "",
        former_permission_of_company:
          this.editCompany.photos.former_permission_of_company.length > 0
            ? this.editCompany.photos.former_permission_of_company[0].name
            : "",
        experience_certificate:
          this.editCompany.photos.experience_certificate.length > 0
            ? this.editCompany.photos.experience_certificate[0].name
            : "",
        contract_of_establishment_of_company:
          this.editCompany.photos.contract_of_establishment_of_company.length >
          0
            ? this.editCompany.photos.contract_of_establishment_of_company[0]
                .name
            : "",
        commercial_record:
          this.editCompany.photos.commercial_record.length > 0
            ? this.editCompany.photos.commercial_record[0].name
            : "",
        chamber_of_commerce:
          this.editCompany.photos.chamber_of_commerce.length > 0
            ? this.editCompany.photos.chamber_of_commerce[0].name
            : "",
        former_license:
          this.editCompany.photos.former_license.length > 0
            ? this.editCompany.photos.former_license[0].name
            : "",
      };
    }
  },

  methods: {
    validateImage(e) {
      if (!e.target.files.length) return;
      const file = e.target.files[0].type;
      if (
        file == "image/png" ||
        file == "application/pdf" ||
        file == "image/jpeg" ||
        file == "image/jpg"
      ) {
        return true;
      } else {
        let message = [
          " هذا الملف غير صالح ، الرجاء تحميل الملف بصغة pdf او صورة",
        ];
        this.$store.commit("toggleErrorMsg", message);
        return false;
      }
    },
    pickstatement_of_graduateFunc() {
      this.$refs.statement_of_graduate.click();
    },
    pickbetter_from_education_departmentFunc() {
      this.$refs.better_from_education_department.click();
    },
    pickcopy_of_a_valid_license_to_practice_the_professionFunc() {
      this.$refs.copy_of_a_valid_license_to_practice_the_profession.click();
    },
    pickbetter_from_quality_departmentFunc() {
      this.$refs.better_from_quality_department.click();
    },
    pickbirth_certificateFunc() {
      this.$refs.birth_certificate.click();
    },
    pickthe_general_assemblyFunc() {
      this.$refs.the_general_assembly.click();
    },
    pickproof_of_identityFunc() {
      this.$refs.proof_of_identity.click();
    },
    pickpersonal_imageFunc() {
      this.$refs.personal_image.click();
    },
    pickimage_of_subscriptionFunc() {
      this.$refs.image_of_subscription.click();
    },
    pickformer_permission_of_companyFunc() {
      this.$refs.former_permission_of_company.click();
    },
    pickexperience_certificateFunc() {
      this.$refs.experience_certificate.click();
    },
    pickcontract_of_establishment_of_companyFunc() {
      this.$refs.contract_of_establishment_of_company.click();
    },
    pickminutes_of_the_meetingFunc() {
      this.$refs.minutes_of_the_meeting.click();
    },
    pickcommercial_recordFunc() {
      this.$refs.commercial_record.click();
    },
    pickchamber_of_commerceFunc() {
      this.$refs.chamber_of_commerce.click();
    },
    pickformer_licenseFunc() {
      this.$refs.former_license.click();
    },
    // it will be used to submit the image chosen by the user
    submitImageFunc(e, formName) {
      if (this.validateImage(e)) {
        this.form[formName] = e.target.files[0];
        this.$store.commit(formName, this.form);
      }
    },
    statement_of_graduateFunc(e) {
      if (this.validateImage(e)) {
        this.form.statement_of_graduate = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      }
    },
    minutes_of_the_meetingFunc(e) {
      if (this.validateImage(e)) {
        this.form.minutes_of_the_meeting = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      }
    },
    better_from_education_departmentFunc(e) {
      if (this.validateImage(e)) {
        this.form.better_from_education_department = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      }
    },
    better_from_quality_departmentFunc(e) {
      if (this.validateImage(e)) {
        this.form.better_from_quality_department = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      }
    },
    birth_certificateFunc(e) {
      if (this.validateImage(e)) {
        this.form.birth_certificate = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      }
    },
    proof_of_identityFunc(e) {
      if (this.validateImage(e)) {
        this.form.proof_of_identity = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      }
    },
    personal_imageFunc(e) {
      if (this.validateImage(e)) {
        this.form.personal_image = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      }
    },
    image_of_subscriptionFunc(e) {
      if (this.validateImage(e)) {
        this.form.image_of_subscription = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      }
    },
    former_permission_of_companyFunc(e) {
      if (this.validateImage(e)) {
        this.form.former_permission_of_company = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      }
    },
    experience_certificateFunc(e) {
      if (this.validateImage(e)) {
        this.form.experience_certificate = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      }
    },
    contract_of_establishment_of_companyFunc(e) {
      if (this.validateImage(e)) {
        this.form.contract_of_establishment_of_company = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      }
    },
    commercial_recordFunc(e) {
      if (this.validateImage(e)) {
        this.form.commercial_record = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      }
    },
    copy_of_a_valid_license_to_practice_the_professionFunc(e) {
      if (this.validateImage(e)) {
        this.form.copy_of_a_valid_license_to_practice_the_profession =
          e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      }
    },
    chamber_of_commerceFunc(e) {
      if (this.validateImage(e)) {
        this.form.chamber_of_commerce = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      }
    },
    former_licenseFunc(e) {
      if (this.validateImage(e)) {
        this.form.former_license = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      }
    },
    the_general_assemblyFunc(e) {
      if (this.validateImage(e)) {
        this.form.the_general_assembly = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      }
    },
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$pending || this.$v.form.$error) return;
      this.loading = true;

      this.$store.commit("companyFormFilling", this.form);
      if (!this.editCompany) {
        this.$store
          .dispatch("companySubmiting")
          .then(() => {
            this.loading = false;
            this.$store.commit(
              "toggleSuccessMsg",
              "سيتم مراجعة الطلب وارسال القبول على بريدك الإلكتروني"
            );
          })
          .catch((message) => {
            this.loading = false;
            this.$store.commit("toggleErrorMsg", message);
          });
      } else {
        this.$store
          .dispatch("editCompanySubmiting", this.editCompany.id)
          .then(() => {
            this.loading = false;
            this.$store.commit("toggleSuccessMsg", "تم التعديل بنجاح");
          })
          .catch(({ message }) => {
            this.loading = false;
            this.$store.commit("toggleErrorMsg", message);
          });
      }
    },
  },
};
</script>
