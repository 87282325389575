<template>
  <div class="container mx-auto px-5 md:px-8 my-12">
    <div v-if="post && !loadingPage">
      <div class="w-full mx-auto px-5">
        <div class="flex items-center justify-end my-3">
          <button
            v-if="!edit"
            @click="edit = true"
            class="
              px-5
              py-2
              border
              outline-none
              focus:outline-none
              border-btn
              text-btn
              rounded-md
              mx-5
              flex
              items-center
              text-center
            "
          >
            <span class="mx-1">تعديل</span>
            <i class="bx bx-edit"></i>
          </button>
          <button
            v-else
            @click="edit = false"
            class="
              px-5
              py-2
              border
              outline-none
              focus:outline-none
              border-btn
              text-btn
              rounded-md
              mx-5
              flex
              items-center
              text-center
            "
          >
            <span class="mx-1">الغاء</span>
          </button>
          <button
            @click="DeletePost"
            class="
              px-5
              py-2
              border border-red-600
              bg-red-600
              focus:outline-none
              text-white
              rounded-md
              flex
              items-center
            "
          >
            <span class="mx-1">حذف</span>
            <i class="bx bx-trash"></i>
          </button>
        </div>
        <img
          v-if="!edit && post.photo"
          class="w-3/4 border border-secondry object-cover mx-auto"
          style="max-height: 460px"
          alt=""
          loading="lazy"
          :src="'https://' + post.photo"
        />
        <div class="w-3/4 mx-auto py-12">
          <div
            v-if="!edit"
            class="flex items-center text-dateText my-1 tracking-wide w-full"
          >
            <span>{{ post.category_ar }} - </span>
            <span class="mx-1">
              {{ $moment(post.created_at).format("YYYY-MM-DD") }}
            </span>
          </div>
          <h1 v-if="!edit" class="text-footerBg text-2xl my-4 font-bold w-full">
            {{ post.title }}
          </h1>
          <p
            v-if="!edit"
            v-html="post.description_ar"
            class="text-newsDescription py-5 font-medium tracking-wide text-lg"
          ></p>
          <div v-if="edit">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div class="w-full mb-5">
                <h4>عنوان الخبر بالعربي</h4>
                <input
                  type="text"
                  name="title_ar"
                  id="title_ar"
                  v-model="post.title_ar"
                  @blur="$v.post.title_ar.$touch()"
                  class="w-full rounded-lg px-4 py-2 border"
                  :class="
                    $v.post.title_ar.$error
                      ? 'border-red-500'
                      : 'border-gray-300'
                  "
                />
                <p
                  v-if="$v.post.title_ar.$error"
                  class="text-red-500 text-sm font-medium tracking-wide"
                >
                  يجب الا تترك عنوان الخبر فارغاً
                </p>
              </div>
              <div class="w-full mb-5">
                <h4>عنوان الخبر بالانجليزي</h4>
                <input
                  type="text"
                  name="title_en"
                  id="title_en"
                  v-model="post.title_en"
                  @blur="$v.post.title_en.$touch()"
                  class="w-full rounded-lg px-4 py-2 border"
                  :class="
                    $v.post.title_en.$error
                      ? 'border-red-500'
                      : 'border-gray-300'
                  "
                />
                <p
                  v-if="$v.post.title_en.$error"
                  class="text-red-500 text-sm font-medium tracking-wide"
                >
                  يجب كتابة عنوان الخبر باللغة الانجليزية
                </p>
              </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div class="w-full mb-5">
                <h4>المجموعة بالعربي</h4>
                <input
                  type="text"
                  name="category_ar"
                  id="category_ar"
                  v-model="post.category_ar"
                  @blur="$v.post.category_ar.$touch()"
                  class="w-full rounded-lg px-4 py-2 border"
                  :class="
                    $v.post.category_ar.$error
                      ? 'border-red-500'
                      : 'border-gray-300'
                  "
                />
                <p
                  v-if="$v.post.category_ar.$error"
                  class="text-red-500 text-sm font-medium tracking-wide"
                >
                  يجب كتابة المجموعة التي تصنف بها الخبر باللغة العربية
                </p>
              </div>
              <div class="w-full mb-5">
                <h4>المجموعة بالانجليزي</h4>
                <input
                  type="text"
                  name="category_en"
                  id="category_en"
                  v-model="post.category_en"
                  @blur="$v.post.category_en.$touch()"
                  class="w-full rounded-lg px-4 py-2 border"
                  :class="
                    $v.post.category_en.$error
                      ? 'border-red-500'
                      : 'border-gray-300'
                  "
                />
                <p
                  v-if="$v.post.category_en.$error"
                  class="text-red-500 text-sm font-medium tracking-wide"
                >
                  يجب كتابة المجموعة التي تصنف بها الخبر باللغة الانجليزية
                </p>
              </div>
            </div>
            <div class="my-5">
              <h4>محتوى الخبر بالعربي</h4>
              <vue-editor
                v-model="post.description_ar"
                :editor-toolbar="customToolbar"
              ></vue-editor>
            </div>
            <div class="my-5">
              <h4>محتوي الخبر بالانجليزي</h4>
              <vue-editor
                v-model="post.description_en"
                :editor-toolbar="customToolbar"
              ></vue-editor>
            </div>

            <div
              class="
                border
                rounded-md
                border-gray-300
                z-20
                text-gray-600
                bg-white
                px-5
                py-3
                -mt-2
                w-full
              "
            >
              <input
                type="file"
                class="hidden"
                ref="uploadImg"
                accept="image/*"
                @change="OnPickerChange"
              />
              <button
                @click.prevent="pickPicture"
                class="
                  flex
                  items-center
                  tracking-wider
                  w-full
                  outline-none
                  focus:outline-none
                "
              >
                <i class="bx bxs-image mx-2 text-primary"></i>
                <span class="ml-2 text-gray-600 text-sm">{{
                  `${
                    post.photo || imgSrc ? "تبديل الصورة" : "اضافة صورة"
                  } (Allowed: png, jpg)`
                }}</span>
              </button>
              <img
                :src="imgSrc || 'https://' + post.photo"
                class="w-full object-cover mt-3"
                alt=""
                loading="lazy"
              />
            </div>
            <p
              v-if="errorMsg"
              class="
                my-5
                bg-red-100
                text-red-600
                border-red-600
                py-2
                font-medium
                tracking-wide
                leading-relaxed
                px-5
                w-full
                border
                rounded-md
              "
            >
              {{ errorMsg }}
            </p>
            <p
              v-if="success"
              class="
                my-5
                bg-green-100
                text-green-600
                border-green-600
                py-2
                font-medium
                tracking-wide
                leading-relaxed
                px-5
                w-full
                border
                rounded-md
              "
            >
              <span>تم التعديل بنجاح</span>
              <button class="font-bold mx-1" @click="back">رجوع</button>
            </p>
            <button
              @click.prevent="submit"
              class="
                bg-primary
                px-8
                py-2
                text-white
                flex
                items-center
                text-sm
                my-3
                rounded-md
                float-left
              "
            >
              <span>حفط</span>
              <i v-if="loading" class="mx-2 bx bx-loader-circle bx-spin"></i>
              <i v-else-if="success" class="bx bx-check-double mx-2"></i>
              <i v-else class="mx-2 bx bx-save"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="my-12 text-center mx-auto">
      <i class="text-5xl text-primary bx bx-loader-circle bx-spin"></i>
    </div>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import { required } from "vuelidate/lib/validators";

export default {
  name: "newsdetails",
  data() {
    return {
      post: null,
      URL: process.env.VUE_APP_URL,
      imgSrc: null,
      edit: false,
      errorMsg: "",
      showEditIcon: false,
      showEditImgIcon: false,
      loading: false,
      success: false,
      loadingPage: false,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  },
  created() {
    this.fetchDetails();
  },
  validations: {
    post: {
      title_ar: { required },
      title_en: { required },
      description_ar: { required },
      description_en: { required },
      category_ar: { required },
      category_en: { required },
      photo: { required },
    },
  },
  components: {
    VueEditor,
  },
  methods: {
    pickPicture() {
      this.$refs.uploadImg.click();
    },
    OnPickerChange(e) {
      if (!e.target.files.length) return;
      let file = event.target.files[0];
      this.post.photo = file;
      this.imgSrc = URL.createObjectURL(e.target.files[0]);
    },
    fetchDetails() {
      this.loadingPage = true;
      this.$store
        .dispatch("NewsDetails", this.$route.params.id)
        .then((data) => {
          this.post = { ...data, photo: data.photo.length > 0 ? data.photo[0].path : "" };
          this.loadingPage = false;
        });
    },
    back() {
      this.fetchDetails();
      this.success = false;
      this.edit = false;
    },
    DeletePost() {
      this.$store
        .dispatch("DeletePostFromNews", this.$route.params.id)
        .then(() => {
          this.$store.dispatch("fetchNews");
          this.$router.go(-1);
        });
    },
    submit() {
      this.$v.post.$touch();
      if (this.$v.post.$error || this.$v.post.$pending) {
        this.errorMsg = "يجب تعبئة جمعيع الحقول قبل التأكيد";
        return;
      }
      this.loading = true;

      const form = new FormData();

      if (typeof this.post.photo == "string") {
        delete this.post.photo;
      } else {
        form.append("image", this.post.photo);
        delete this.post.photo;
      }

      for (let [key, value] of Object.entries(this.post)) {
        form.append(key, value);
      }
      form.append("_method", "put");
      this.$store
        .dispatch("editNewsPost", {
          id: this.$route.params.id,
          form,
        })
        .then(() => {
          this.success = true;
        })
        .catch((err) => {
          this.errorMsg = err;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
