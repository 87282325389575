<template>
  <div class="py-8 md:w-11/12 mx-auto">
    <h1 class="text-h1Text text-3xl mt-lgMargin">المشاركون في الحدث</h1>
    <div v-if="participant">
      <table
        v-if="participant.length > 0"
        class="min-w-full divide-y divide-gray-200"
      >
        <thead class="bg-gray-50">
          <tr class="text-center">
            <th
              class="
                px-6
                py-3
                text-xs
                font-medium
                text-gray-500
                uppercase
                tracking-wider
              "
            >
              الاسم
            </th>
            <th
              class="
                px-6
                py-3
                text-xs
                font-medium
                text-gray-500
                uppercase
                tracking-wider
              "
            >
              البريد الألكتروني
            </th>
            <th
              class="
                px-6
                py-3
                text-xs
                font-medium
                text-gray-500
                uppercase
                tracking-wider
              "
            >
              رقم الهاتف
            </th>
            <th
              class="
                px-6
                py-3
                text-xs
                font-medium
                text-gray-500
                uppercase
                tracking-wider
              "
            ></th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200 my-5">
          <tr
            v-for="(item, index) in participant"
            :key="index"
            class="text-center"
          >
            <td class="px-6 py-4 text-gray-700">{{ item.name }}</td>
            <td class="px-6 py-4 text-gray-700">
              <button
                class="outline-none focus:outline-none text-blue-700"
                @click="$store.commit('sendEmailNotification', item.email)"
              >
                {{ item.email }}
              </button>
            </td>
            <td class="px-6 py-4 text-gray-700">
              <a
                :href="'tel:' + item.phone_number"
                class="outline-none focus:outline-none text-blue-700"
              >
                {{ item.phone_number }}</a
              >
            </td>
            <td class="px-6 py-4 text-blue-600 text-md">
              <a
                :href="'https://' + item.photos[0].path"
                :download="item.photos[0]"
                target="_blank"
                class="px-3 py-2 text-sm text-white rounded-md bg-primary"
              >
                تفاصيل المسجل
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <h3 v-else class="text-center text-lg text-gray-700 my-8">
        {{
          status == "اكتمل الحدث"
            ? "لم يسجل احد في الحدث"
            : "لا يوجد احد سجل في هذا الحدث بعد"
        }}
      </h3>
    </div>
    <div v-else class="my-12 text-center mx-auto">
      <i class="text-5xl text-primary bx bx-loader-circle bx-spin"></i>
    </div>
  </div>
</template>
<script>
export default {
  props: ["event_id", "status"],
  name: "",
  data() {
    return {
      participant: null,
      url: process.env.VUE_APP_URL,
    };
  },
  created() {
    this.$axios
      .get(`/api/event/participant/${this.event_id}`)
      .then(({ data }) => {
        this.participant = data.data;
      });
  },
};
</script>