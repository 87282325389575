<template>
  <div class="container mx-auto py-6 px-4">
    <div class="flex justify-between items-center py-4 border-b mb-10">
      <h1 class="text-3xl">
        {{
          $route.params.count == "requestsCount"
            ? "طلبات عضوية الباحثين"
            : $route.params.count == "RenewalRequestsCount"
            ? "طلبات تجديد عضوية الباحثين"
            : "الباحثين"
        }}
      </h1>
      <router-link
        :to="{ name: 'indivualGeneral' }"
        class="
          px-5
          py-2
          focus:outline-none
          hover:shadow-md
          rounded-md
          bg-primary
          text-white
          flex
          items-center
        "
      >
        اضافة<i class="bx bxs-plus-circle mx-1"></i>
      </router-link>
    </div>

    <div class="mb-4 flex items-center flex-wrap">
      <div class="relative w-full md:w-auto flex items-center">
        <input
          type="text"
          class="
            w-full
            pl-10
            pr-4
            py-2
            rounded-lg
            border border-inputBorder
            focus:outline-none focus:shadow-outline
            text-gray-600
            font-medium
          "
          placeholder="بحث"
          v-model="Search"
          @keyup.enter="filterBySearch"
        />
        <i
          @click="filterBySearch"
          class="bx bx-search-alt z-20 text-gray-500 -mr-8 cursor-pointer"
        ></i>
      </div>
      <v-select
        dir="rtl"
        v-if="Branches && userData.status == 'super-admin'"
        :options="Branches"
        label="name_ar"
        v-model="branch_id"
        placeholder="النقابة"
        class="
          mx-0
          my-2
          md:my-0 md:mx-3
          w-full
          md:w-1/4
          bg-white
          rounded-md
          border border-inputBorder
          p-1
        "
      ></v-select>
      <v-select
        dir="rtl"
        :options="['الأحدث', 'الأقدم']"
        v-model="SortBy"
        placeholder="ترتيب بأستخدام"
        class="
          mx-0
          my-2
          md:mx-3
          w-full
          md:w-1/4
          bg-white
          rounded-md
          border border-inputBorder
          p-1
        "
      ></v-select>
    </div>

    <div class="flex items-center w-full sm:3/4 lg:w-3/4 my-5 flex-wrap">
      <button
        @click="
          allDoctors();
          page = 1;
        "
        :class="
          $route.params.count == 'all'
            ? 'bg-primary text-white'
            : 'bg-white text-primary'
        "
        class="
          focus:outline-none
          mx-3
          border-2
          w-full
          sm:w-auto
          my-2
          border-primary
          px-4
          py-1
          rounded-md
          font-medium
          text-bold
        "
      >
        جميع الباحثين
      </button>
      <button
        @click="
          requestsCount();
          page = 1;
        "
        :class="
          $route.params.count == 'requestsCount'
            ? 'bg-primary text-white'
            : 'bg-white text-primary'
        "
        class="
          focus:outline-none
          mx-3
          border-2
          w-full
          sm:w-auto
          my-2
          border-primary
          px-4
          py-1
          rounded-md
          font-medium
          text-bold
        "
      >
        طلبات العضوية
      </button>
      <button
        @click="
          RenewalRequestsCount();
          page = 1;
        "
        :class="
          $route.params.count == 'RenewalRequestsCount'
            ? 'bg-primary text-white'
            : 'bg-white text-primary'
        "
        class="
          focus:outline-none
          mx-3
          border-2
          w-full
          sm:w-auto
          my-2
          border-primary
          px-4
          py-1
          rounded-md
          font-medium
          text-bold
        "
      >
        طلبات التجديد
      </button>
      <button
        @click="
          finishedDoctorsMemberShip();
          page = 1;
        "
        :class="
          $route.params.count == 'finishedDoctorsMemberShip'
            ? 'bg-primary text-white'
            : 'bg-white text-primary'
        "
        class="
          focus:outline-none
          mx-3
          border-2
          w-full
          sm:w-auto
          my-2
          border-primary
          px-4
          py-1
          rounded-md
          font-medium
          text-bold
        "
      >
        الباحثون الغير نشطون
      </button>
    </div>
    <button
      v-if="printData"
      @click="printReport"
      class="
        border
        rounded-lg
        w-full
        mb-5
        md:w-auto
        border-primary
        px-3
        hover:bg-primary
        text-md text-primary
        hover:text-white
        py-2
        font-bold
        flex
        justify-center
        items-center
      "
    >
      <span> طباعة اذن مزاولة الباحث {{ printData.name }}</span>
      <i class="bx bx-printer mx-2 text-2xl"></i>
    </button>

    <div v-if="loading" class="flex justify-center">
      <i class="text-4xl mx-auto text-primary bx bx-loader-circle bx-spin"></i>
    </div>

    <div v-if="errMsg && !loading" class="mb-4">
      <infoAlert :message="errMsg" />
    </div>

    <div
      v-if="Doctors && !loading"
      class="bg-white rounded border border-inputBorder overflow-y-auto w-full"
    >
      <table
        v-if="Doctors.data.length > 0"
        class="border-collapse whitespace-no-wrap bg-white w-full"
      >
        <thead>
          <tr class="text-right">
            <th
              v-for="heading in headings"
              :key="heading.id"
              class="
                bg-gray-50
                border-b border-gray-200
                px-6
                py-2
                text-gray-600
                font-bold
                tracking-wider
                uppercase
                text-lg
              "
              v-text="heading.value"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="doctor in Doctors.data"
            :key="doctor.id"
            @click="printBtn(doctor)"
            class="cursor-pointer"
            :class="
              printData
                ? printData.id == doctor.id
                : false
                ? 'bg-gray-200  border border-gray-400 shadow-md z-20'
                : null
            "
          >
            <td
              class="border-dashed border-t border-gray-200 userId text-center"
            >
              <span class="text-gray-700 px-6 py-3 flex items-center">
                <label class="inline-flex items-center mt-3">
                  <input
                    type="checkbox"
                    class="form-checkbox h-5 w-5 text-blue-600"
                    :checked="printData ? printData.id == doctor.id : null"
                  />
                </label>
              </span>
            </td>
            <td class="border-dashed border-t border-gray-200">
              <span class="text-gray-700 px-6 py-3 flex items-center">{{
                doctor.member_ship_id
              }}</span>
            </td>
            <td class="border-dashed border-t border-gray-200">
              <span class="text-gray-700 px-6 py-3 flex items-center">{{
                doctor.name
              }}</span>
            </td>
            <td class="border-dashed border-t border-gray-200">
              <span class="text-gray-700 px-6 py-3 flex items-center">{{
                doctor.activation_date || "-"
              }}</span>
            </td>
            <td class="border-dashed border-t border-gray-200">
              <span class="text-gray-700 px-6 py-3 flex items-center">{{
                doctor.specialization
              }}</span>
            </td>
            <td class="border-dashed border-t border-gray-200">
              <span class="text-gray-700 px-6 py-3 flex items-center">{{
                doctor.current_place_of_work || "-"
              }}</span>
            </td>
            <td class="border-dashed border-t border-gray-200">
              <span class="text-gray-700 py-3 flex items-center">
                <select
                  name="status"
                  id="status"
                  @change="ChangeDoctorStatus($event, doctor)"
                  :value="doctor.status"
                  class="
                    p-1
                    border-inputBorder
                    bg-white
                    rounded-md
                    border
                    w-full
                    text-right
                    focus-within:border-primary
                    hover:border-primary
                    focus:border-primary focus:outline-none focus:shadow-md
                  "
                >
                  <option value="نشط">نشط</option>
                  <option value="حظر">حظر</option>
                  <option value="حذف">حذف</option>
                  <option
                    v-if="
                      doctor.status == 'طلب عضوية' || doctor.status == 'غير نشط'
                    "
                  >
                    {{ doctor.status }}
                  </option>
                </select>
              </span>
            </td>
            <td
              class="
                border-dashed border-t border-gray-200
                flex
                items-center
                justify-center
                px-3
              "
            >
              <span class="text-gray-700 px-6 py-3 flex items-center">
                <button
                  class="bg-primary px-3 py-1 rounded-md text-white flex"
                  @click="editDoctor(doctor)"
                >
                  <span class="mx-2">تعديل</span>
                  <i class="bx bx-message-square-edit"></i>
                </button>
              </span>
              <router-link
                :to="{ name: 'doctorDetails', params: { doc: doctor.id } }"
                class="
                  border border-primary
                  hover:bg-primary
                  text-primary
                  hover:text-white
                  h-8
                  w-8
                  flex
                  items-center
                  justify-center
                  cursor-pointer
                  rounded-full
                "
                ><i class="bx bxs-left-arrow-alt"></i>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else class="my-8 text-center">لا يوجد بيانات لعرضها</p>
    </div>
    <p v-if="!Doctors && !loading" class="my-8 text-center">
      لا يوجد بيانات لعرضها
    </p>

    <div class="w-full my-12" v-if="Doctors && !loading">
      <pagination
        :last_page="Doctors.meta.last_page"
        :current_page="Doctors.meta.current_page"
        :from="Doctors.meta.from"
        @setPage="clickCallback"
      />
    </div>
  </div>
</template>
<script>
import infoAlert from "../../layout/infoAlert";
import printDoc from "../printDoc/doctor";
export default {
  name: "doctors",
  data() {
    return {
      branch_id: null,

      page: 1,
      printData: null,
      SortBy: null,
      headings: [
        {
          id: 0,
          value: "",
        },
        {
          id: 1,
          value: "رقم العضوية",
        },
        {
          id: 2,
          value: "اسم الباحث",
        },
        {
          id: 3,
          value: "تاريخ القبول ",
        },
        {
          id: 4,
          value: "التخصص",
        },
        {
          id: 5,
          value: "مكان العمل الحالي",
        },
        {
          id: 6,
          value: "الحالة",
        },
        {
          id: 7,
          value: "",
        },
      ],
      Search: "",
      hover: false,
      loading: true,
      errMsg: "",
      sort: "Desc",
    };
  },
  created() {
    if (this.$route.params.count == "requestsCount") {
      this.requestsCount();
    } else if (this.$route.params.count == "RenewalRequestsCount") {
      this.RenewalRequestsCount();
    } else if (this.$route.params.count == "all") {
      this.allDoctors();
    } else if (this.$route.params.count == "finishedDoctorsMemberShip") {
      this.finishedDoctorsMemberShip();
    }
  },
  watch: {
    branch_id() {
      this.filterByBranch();
    },
    SortBy() {
      this.SortByMethods();
    },
  },
  methods: {
    printBtn(data) {
      if (this.printData) {
        if (data.id == this.printData.id) {
          this.printData = null;
          return;
        }
      }
      this.printData = data;
    },
    filterByBranch() {
      if (this.$route.params.count == "requestsCount") {
        this.requestsCount();
      } else if (this.$route.params.count == "RenewalRequestsCount") {
        this.RenewalRequestsCount();
      } else if (this.$route.params.count == "finishedDoctorsMemberShip") {
        this.finishedDoctorsMemberShip();
      }
    },
    filterBySearch() {
      if (this.$route.params.count == "requestsCount") {
        this.requestsCount();
      } else if (this.$route.params.count == "RenewalRequestsCount") {
        this.RenewalRequestsCount();
      } else if (this.$route.params.count == "all") {
        this.allDoctors();
      } else if (this.$route.params.count == "finishedDoctorsMemberShip") {
        this.finishedDoctorsMemberShip();
      }
    },
    SortByMethods() {
      if (this.SortBy == "الأقدم") {
        this.sort = "Asc";
        if (this.$route.params.count == "requestsCount") {
          this.requestsCount();
        } else if (this.$route.params.count == "RenewalRequestsCount") {
          this.RenewalRequestsCount();
        } else if (this.$route.params.count == "all") {
          this.allDoctors();
        } else if (this.$route.params.count == "finishedDoctorsMemberShip") {
          this.finishedDoctorsMemberShip();
        }
      } else {
        this.sort = "Desc";
        if (this.$route.params.count == "requestsCount") {
          this.requestsCount();
        } else if (this.$route.params.count == "RenewalRequestsCount") {
          this.RenewalRequestsCount();
        } else if (this.$route.params.count == "all") {
          this.allDoctors();
        } else if (this.$route.params.count == "finishedDoctorsMemberShip") {
          this.finishedDoctorsMemberShip();
        }
      }
    },
    editDoctor(doctorDetail) {
      this.$store.commit("editDoctor", doctorDetail);
      this.$router.push({ name: "indivualGeneral" });
    },
    ChangeDoctorStatus(e, doctor) {
      const status = e.target.value;
      this.loading = true;
      this.$store
        .dispatch("DoctorStatus", {
          status: status,
          _id: doctor.id,
        })
        .then(() => {
          this.printData = null;
          this.SortByMethods();
        });
    },
    clickCallback: function (pageNum) {
      if (!pageNum) return;
      this.page = pageNum;
      if (this.$route.params.count == "requestsCount") {
        this.requestsCount();
      } else if (this.$route.params.count == "RenewalRequestsCount") {
        this.RenewalRequestsCount();
      } else if (this.$route.params.count == "all") {
        this.allDoctors();
      } else if (this.$route.params.count == "finishedDoctorsMemberShip") {
        this.finishedDoctorsMemberShip();
      }
    },
    allDoctors() {
      this.errMsg = "";
      this.loading = true;
      this.$route.params.count !== "all"
        ? this.$router.push({ name: "DoctorsPanel", params: { count: "all" } })
        : null;
      this.$store
        .dispatch("getAllDoctors", {
          page: this.page,
          sort: this.sort,
          search: this.Search,
          branch_id: this.branch_id ? this.branch_id.id : "",
        })
        .then((res) => {
          this.loading = false;
          if (res.doctors.data == "") {
            this.errMsg = "لا يوجد باحثون مسجلين بعد";
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    finishedDoctorsMemberShip() {
      this.errMsg = "";
      this.loading = true;
      this.$route.params.count !== "finishedDoctorsMemberShip"
        ? this.$router.push({
            name: "DoctorsPanel",
            params: { count: "finishedDoctorsMemberShip" },
          })
        : null;
      this.$store
        .dispatch("finishedDoctorsMemberShip", {
          page: this.page,
          sort: this.sort,
          search: this.Search,
          branch_id: this.branch_id ? this.branch_id.id : "",
        })
        .then((res) => {
          this.loading = false;
          if (res.doctors.data == "") {
            this.errMsg = "لا يوجد باحثون منتهية عضويتهم";
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    requestsCount() {
      this.errMsg = "";
      this.loading = true;
      this.$route.params.count !== "requestsCount"
        ? this.$router.push({
            name: "DoctorsPanel",
            params: { count: "requestsCount" },
          })
        : null;
      this.$store
        .dispatch("doctorRequests", {
          page: this.page,
          sort: this.sort,
          search: this.Search,
          branch_id: this.branch_id ? this.branch_id.id : "",
        })
        .then((res) => {
          this.loading = false;
          if (res.doctors.data == "") {
            this.errMsg = "لا يوجد طلبات تسجيل";
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    RenewalRequestsCount() {
      this.errMsg = "";
      this.loading = true;
      this.$route.params.count !== "RenewalRequestsCount"
        ? this.$router.push({
            name: "DoctorsPanel",
            params: { count: "RenewalRequestsCount" },
          })
        : null;
      this.$store
        .dispatch("renewalDoctorsRequests", {
          page: this.page,
          sort: this.sort,
          search: this.Search,
          branch_id: this.branch_id ? this.branch_id.id : "",
        })
        .then((res) => {
          this.loading = false;
          if (res.doctors.data == "") {
            this.errMsg = "لا يوجد طلبات تجديد";
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    printReport() {
      if (this.printData.status !== "نشط") {
        this.errMsg = "يجب تنشيط هذا الطبيب لطباعة إذن المزاولة الخاص به";
        setTimeout(() => {
          this.errMsg = null;
        }, 7000);
      } else {
        let tab = printDoc(this.printData, this.userData);
        const WinPrint = window.open(
          "",
          "",
          "right=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        );
        WinPrint.document.write(tab);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        setTimeout(() => {
          WinPrint.close();
        }, 1000);
      }
    },
  },
  computed: {
    Doctors() {
      return this.$store.getters.getAllDoctors;
    },
    getPageCount() {
      // to order pages on paginataion by numbers
      return this.$store.getters.getAllDoctors;
    },
    Branches() {
      return this.$store.getters.getBranches;
    },
    userData() {
      return this.$store.getters.userData;
    },
  },
  components: {
    infoAlert,
  },
};
</script>
