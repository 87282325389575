<template>
  <div>
    <div v-if="event" class="container mx-auto px-5 md:px-8">
      <div v-if="!edit" class="my-8 md:w-11/12 mx-auto">
        <span class="text-dateText text-3xl">
          {{ new Date(event.event_date).toDateString() }}
        </span>

        <h1 class="text-footerBg text-2xl my-5 font-medium">
          {{ event.title_ar }}
        </h1>

        <p class="text-newsDescription text-lg">
          {{ event.description_ar }}
        </p>

        <div v-if="event.photos && event.photos.length > 0">
          <h5 class="text-dateText mt-12">صور من الحدث</h5>
          <swiper
            ref="mySwiper"
            class="w-full outline-none my-5 focus:outline-none"
            :options="swiperOption"
          >
            <swiper-slide
              class="w-full h-64"
              v-for="img in event.photos"
              :key="img.id"
            >
              <img :src="'https://' + img.path" class="object-cover h-full w-full" />
            </swiper-slide>
          </swiper>
          <div
            v-if="event.photos && event.photos.length > 0"
            class="flex items-center justify-start"
          >
            <button
              @click="slideNext"
              class="
                border border-CarusoleButton
                mx-3
                bg-white
                hover:border-btn
                focus:ring-2
                text-CarusoleButton
                hover:text-white
                p-3
                flex
                items-center
                justify-center
                text-2xl
                outline-none
                focus:outline-none
                hover:bg-btn
              "
            >
              <i class="bx bxs-chevron-right"></i>
            </button>
            <button
              @click="slidePrev"
              class="
                border border-CarusoleButton
                mx-3
                bg-white
                hover:border-btn
                focus:ring-2
                text-CarusoleButton
                hover:text-white
                p-3
                flex
                items-center
                justify-center
                text-2xl
                outline-none
                focus:outline-none
                hover:bg-btn
              "
            >
              <i class="bx bxs-chevron-left"></i>
            </button>
          </div>
        </div>

        <div id="actions" class="flex items-center justify-start mt-5">
          <button
            @click="edit = true"
            class="mx-4 px-5 py-2 rounded-md text-white bg-gray-600"
          >
            تعديل
          </button>
          <button
            @click="confirmDelete = !confirmDelete"
            class="mx-4 px-5 py-2 rounded-md text-white bg-red-600"
          >
            حذف
          </button>
        </div>
      </div>
      <Participant v-if="!edit" :event_id="event.id" :status="event.status" />

      <!-- edit form -->
      <EditEvent
        v-if="edit"
        :event_id="event.id"
        :event="event"
        @undo="edit = false"
      />
      <!-- delete confirmation pop up-->
      <div
        v-if="confirmDelete"
        class="fixed inset-0 h-full w-full flex items-center justify-center"
        style="background: rgba(0, 0, 0, 0.5)"
      >
        <div class="bg-white p-8 rounded-md border border-gray-300">
          <h6 class="text-2xl text-newsDescription text-right w-full">
            هل انت متأكد من حذف الحدث ؟
          </h6>
          <div class="flex items-center mt-5">
            <button
              @click="Delete"
              class="
                mx-3
                focus:outline-none
                px-5
                py-2
                rounded-md
                text-white
                bg-red-700
              "
            >
              تأكيد
            </button>
            <button
              @click="confirmDelete = !confirmDelete"
              class="
                mx-3
                focus:outline-none
                px-5
                py-2
                rounded-md
                text-white
                bg-gray-700
              "
            >
              تراجع
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="my-12 text-center mx-auto">
      <i class="text-5xl text-primary bx bx-loader-circle bx-spin"></i>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import Participant from "../participant";
import EditEvent from "./EditEvent.vue";
import 'swiper/css/swiper.css'



export default {
  name: "eventDetails",
  data() {
    return {
      event: null,
      confirmDelete: false,
      edit: false,
      swiperOption: {
        spaceBetween: 30,
        breakpoints: {
          1024: {
            slidesPerView: 2.5,
          },
          768: {
            slidesPerView: 2,
          },
          590: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1.25,
          },
        },
      },
    };
  },
  created() {
    this.getEvent();
  },
  methods: {
    slidePrev() {
      this.swiper.slidePrev();
    },
    slideNext() {
      this.swiper.slideNext();
    },

    Delete() {
      this.loading = true;
      this.$axios
        .post(`/api/events/${this.$route.params.id}`, { _method: "delete" })
        .then(() => {
          this.loading = false;
          this.$router.push({ name: "Events" });
        })
    },
    getEvent() {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`/api/events/${this.$route.params.id}`)
          .then(({ data }) => {
            this.loading = false;
            this.event = data.data;
            resolve(data.data);
          })
          .catch((err) => reject(err.response));
      });
    },
  },
  components: {
    Swiper,
    Participant,
    SwiperSlide,
    EditEvent,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  directives: {
    swiper: directive,
  },
};
</script>