<template>
  <div>
    <div
      class="releative border w-full lg:w-3/4 mx-3 lg:mx-auto mt-8 border-formBorderColor rounded-md p-5 flex flex-wrap"
    >
      <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
        البيانات الشخصية
      </span>

      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="official_representative"
          class="font-medium text-lg w-full text-right text-h1Text flex items-center"
          ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>الممثل الرسمي للشركة</label
        >
        <input
          id="official_representative"
          name="official_representative"
          v-model="form.official_representative"
          type="text"
          required
          @blur="$v.form.official_representative.$touch()"
            :class="
              defualtInput +
                ($v.form.official_representative.$error
                  ? ' border-red-700'
                  : 'border-inputBorder')
            "
        />
        <p v-if="$v.form.official_representative.$error" class="text-red-600 text-sm my-1 w-full">الرجاء تعبئة هذا الحقل</p>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="official_representative_describe"
          class="font-medium text-lg w-full text-righ text-h1Textt flex items-center"
          ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>الصفة</label
        >
        <input
          id="official_representative_describe"
          name="official_representative_describe"
          v-model="form.official_representative_describe"
          type="text"
          required
          @blur="$v.form.official_representative_describe.$touch()"
            :class="
              defualtInput +
                ($v.form.official_representative_describe.$error
                  ? ' border-red-700'
                  : 'border-inputBorder')
            "
        />
        <p v-if="$v.form.official_representative_describe.$error" class="text-red-600 text-sm my-1 w-full">الرجاء تعبئة هذا الحقل</p>
      </div>
      <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="qualification"
            class="font-medium text-lg w-full text-right flex items-center"
            ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>المؤهل العلمي</label
          >
          <input
            id="qualification"
            name="qualification"
            v-model="form.qualification"
            type="text"
            required
            @blur="$v.form.qualification.$touch()"
            :class="
              defualtInput +
              ($v.form.qualification.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.qualification.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            الرجاء تعبئة هذا الحقل
          </p>
        </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="official_representative_number"
          class="font-medium text-lg w-full text-right text-h1Text flex items-center"
          ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>رقم الهاتف</label
        >
        <input
          id="official_representative_number"
          name="official_representative_number"
          v-model="form.official_representative_number"
          type="text"
          required
          @blur="$v.form.official_representative_number.$touch()"
            :class="
              defualtInput +
                ($v.form.official_representative_number.$error
                  ? ' border-red-700'
                  : 'border-inputBorder')
            "
        />
        <p v-if="$v.form.official_representative_number.$error" class="text-red-600 text-sm my-1 w-full">الرجاء تعبئة هذا الحقل</p>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="official_representative_email"
          class="font-medium text-lg w-full text-right text-h1Text flex items-center"
          >البريد الالكتروني</label
        >
        <input
          id="official_representative_email"
          name="official_representative_email"
          v-model="form.official_representative_email"
          type="email"
          required
            :class=" defualtInput + 'border-inputBorder'"
        />
      </div>
    </div>
    <div
      class="releative border w-full lg:w-3/4 mx-3 lg:mx-auto mt-8 border-formBorderColor rounded-md p-5 flex flex-wrap"
    >
      <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
        عنوان الشركة
      </span>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="city"
          class="font-medium text-lg w-full text-right text-h1Text flex items-center"
          ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>المدينة</label
        >
        <input
          id="city"
          name="city"
          v-model="form.city"
          type="address"
          required
          @blur="$v.form.city.$touch()"
            :class="
              defualtInput +
                ($v.form.city.$error
                  ? ' border-red-700'
                  : 'border-inputBorder')
            "
        />
        <p v-if="$v.form.city.$error" class="text-red-600 text-sm my-1 w-full">الرجاء تعبئة هذا الحقل</p>
      </div>

      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="municipal"
          class="font-medium text-lg w-full text-right text-h1Text flex items-center"
          ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>البلدية</label
        >
        <input
          id="municipal"
          name="municipal"
          v-model="form.municipal"
          type="address"
          required
          @blur="$v.form.municipal.$touch()"
            :class="
              defualtInput +
                ($v.form.municipal.$error
                  ? ' border-red-700'
                  : 'border-inputBorder')
            "
        />
        <p v-if="$v.form.municipal.$error" class="text-red-600 text-sm my-1 w-full">الرجاء تعبئة هذا الحقل</p>
      </div>

      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="street"
          class="font-medium text-lg w-full text-right text-h1Textt flex items-center"
          ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>الشارع</label
        >
        <input
          id="street"
          name="street"
          v-model="form.street"
          type="address"
          required
          @blur="$v.form.street.$touch()"
            :class="
              defualtInput +
                ($v.form.street.$error
                  ? ' border-red-700'
                  : 'border-inputBorder')
            "
        />
        <p v-if="$v.form.street.$error" class="text-red-600 text-sm my-1 w-full">الرجاء تعبئة هذا الحقل</p>
      </div>
    </div>
    <div class="flex w-full lg:w-3/4 mx-auto items-center justify-end">
     <p v-if="$v.form.$error" class="text-red-600 text-md font-medium">
        الرجاء تكملة الحقول المطلوبة
      </p>
      <button
        @click="$router.go(-1)"
        class="px-8 rounded-md w-auto shadow py-2 focus:outline-none hover:shadow-lg m-3 md:m-5 border border-primary text-primary bg-white text-lg font-medium"
      >
        رجوع
      </button>
      <button
        @click="next"
        class="px-8 rounded-md w-auto shadow py-2 focus:outline-none hover:shadow-lg m-3 md:m-5 bg-primary text-white text-lg font-medium"
      >
        التالي
      </button>
    </div>
  </div>
</template>
<script>
import {
  required,
  email,
  numeric,
} from "vuelidate/lib/validators";
export default {
  data() {
    return {
      defualtInput:
        "p-2  bg-white rounded-md border w-full text-right focus-within:border-primary hover:border-primary focus:border-primary  focus:outline-none focus:shadow-md my-1",
      form: {
        official_representative: "",
        official_representative_describe: "",
        official_representative_email: "",
        official_representative_number: "",
        qualification: "",
        city: "",
        municipal: "",
        street: "",
      },
    };
  },
  validations: {
        form: {
          official_representative: { required },
          official_representative_describe: { required },
          official_representative_email: { email },
          official_representative_number: {
            required,
            numeric,
          },
          qualification: { required },
          city: { required },
          municipal: { required },
          street: {required },
        },
  },
  created(){
    if (this.companyData) {
      this.form = {...this.form , ...this.companyData}
    }
  },
    computed: {
    Branches(){
      return this.$store.getters.getBranches
    },
    editCompany(){
      return this.$store.getters.editCompany
    } ,
    companyData(){
      return this.$store.getters.companyData
    }
  },
  mounted(){
    if(this.editCompany){
      this.form = {
        official_representative: this.editCompany.official_representative,
        official_representative_describe: this.editCompany.official_representative_describe,
        official_representative_email: this.editCompany.official_representative_email,
        official_representative_number: this.editCompany.official_representative_number,
        qualification: this.editCompany.qualification,
        city: this.editCompany.city,
        municipal: this.editCompany.municipal,
        street: this.editCompany.street,
      }
    }
  },
  methods: {
    next() {
       this.$v.form.$touch();
      if (this.$v.form.$pending || this.$v.form.$error) return;
        this.$store.commit("companyFormFilling", this.form);
      this.$router.push({ name: "companyDocuments" });
    },
  },
};
</script>