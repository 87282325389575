import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";

import registrationRoutes from "./registration/registration";
import Signin from "@/views/AdminSignin";
import controlPanel from "./adminsRoutes";

import ResetPassword from "../views/SetPassword";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Signin,
    // beforeEnter: async (to, from, next) => {
    //   try {
    //     //if (store.getters.userData) next("/Dashboard");
    //     let user = await store.dispatch("is_logged_in");
    //     if (user.message == "success") next("/Dashboard");
    //     else next();
    //   } catch {
    //     next();
    //   }
    // },
  },
  ...controlPanel,
  ...registrationRoutes,
  {
    path: "/password/reset/:token",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      title: "تعيين كلمة المرور",
    },
    // beforeEnter: async (to, from, next) => {
    //   try {
    //     if (store.getters.userData) next("/Dashboard");
    //     let user = await store.dispatch("is_logged_in");
    //     if (user.message == "success") next("/Dashboard");
    //     else next();
    //   } catch {
    //     next();
    //   }
    // },
  },
  // else for 404 pages redirect to home page ...
  {
    path: "*",
    redirect: "/",
    meta: {
      title: "404",
    },
  },
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.getters.userData) {
    return next();
  } else {
    store
      .dispatch("is_logger_in")
      .then((res) => {
        if (to.name == "Home" || to.name == "ResetPassword") {
          if (res.status != 200 || res.data.message == "failed") {
            return next();
          } else {
            return next("/Dashboard");
          }
        } else {
          if (res.status == 200 && res.data.message == "success") {
            return next();
          } else {
            return next("/");
          }
        }
      })
      .catch(() => {
        if (to.name == "Home" || to.name == "ResetPassword") {
          return next();
        } else {
          return next("/");
        }
      });
  }
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
