<template>
  <transition name="slideTop">
    <div
      v-if="animate"
      class="bg-white p-5  md:rounded-lg border border-formBorderColor overflow-hidden shadow-2xl h-full md:h-auto w-full md:w-1/2 lg:w-1/3 z-50"
    >
      <form @submit.prevent="submit" autocomplete="on" class="w-full">
        <div class="flex justify-between items-center w-full mb-5">
          <h1 class="text-primary text-2xl">تغيير كلمة المرور</h1>
          <i
            @click="$store.commit('toggleResetPassword')"
            class="bx bx-x text-3xl text-gray-800 cursor-pointer"
          ></i>
        </div>

        <div class="flex items-center justify-start my-5">
          <input
            id="currentPassword"
            v-model="form.currentPassword"
            @blur="$v.form.currentPassword.$touch()"
            :class="
              $v.form.currentPassword.$error
                ? ' border-red-700'
                : 'border-inputBorder'
            "
            class="w-full p-3 rounded-md border shadow outline-none focus:outline-none  focus:bg-gray-100 focus:border-primary focus:shadow-md"
            required
            :type="currentPasswordpasswordtype"
            placeholder=" كلمة المرور الحالية"
          />
          <i
            class="-mr-12 text-2xl text-gray-500 cursor-pointer "
            @click="
              currentPasswordpasswordtype == 'password'
                ? (currentPasswordpasswordtype = 'text')
                : (currentPasswordpasswordtype = 'password')
            "
            :class="currentPasswordpasswordtype == 'password' ? 'bx bx-show' : 'bx bx-hide'"
          ></i>
        </div>

        <div class="flex items-center  my-5">
          <input
            id="password"
            v-model="form.password"
            @blur="$v.form.password.$touch()"
            :class="
              $v.form.password.$error ? ' border-red-700' : 'border-inputBorder'
            "
            class="w-full p-3 rounded-md border shadow outline-none focus:outline-none  focus:bg-gray-100 focus:border-primary focus:shadow-md"
            required
            :type="passwordtype"
            placeholder="كلمة المرور"
          />
          <i
            class="-mr-12 text-2xl text-gray-500 cursor-pointer"
            @click="
              passwordtype == 'password'
                ? (passwordtype = 'text')
                : (passwordtype = 'password')
            "
            :class="passwordtype == 'password' ? 'bx bx-show' : 'bx bx-hide'"
          ></i>
        </div>

        <div class="flex items-center  my-5">
          <input
            id="password_confirmation"
            v-model="form.password_confirmation"
            @blur="$v.form.password_confirmation.$touch()"
            :class="
              $v.form.password_confirmation.$error
                ? ' border-red-700'
                : 'border-inputBorder'
            "
            class="w-full p-3 rounded-md border shadow outline-none focus:outline-none  focus:bg-gray-100 focus:border-primary focus:shadow-md"
            required
            :type="password_confirmationPasswordtype"
            placeholder="كلمة المرور"
          />
          <i class=''></i>
          <i
            class="-mr-12 text-2xl text-gray-500 cursor-pointer z-20"
            @click="
              password_confirmationPasswordtype == 'password'
                ? (password_confirmationPasswordtype = 'text')
                : (password_confirmationPasswordtype = 'password')
            "
            :class="
              password_confirmationPasswordtype == 'password' ? 'bx bx-show' : 'bx bx-hide'
            "
          ></i>
        </div>

        <button
          type="submit"
          v-if="!success"
          @click.prevent="submit"
          class="bg-primary text-white flex items-center text-lg px-4 py-1 rounded-md focus:outline-none"
        >
          <span class="mx-1">تأكيد</span>
          <i v-if="!loading" class="bx bxs-lock"></i>
          <i
            v-if="loading"
            class="text-2xl mx-2 bx bx-loader-circle bx-spin text-white"
          ></i>
        </button>
      </form>
      <div
        v-if="success"
        class="flex w-full justify-between items-center my-5 font-medium py-1 px-2 rounded-md text-green-100 bg-green-700 border border-green-700"
      >
        <div class="flex items-center">
          <div slot="avatar">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-check-circle w-5 h-5 mx-2"
            >
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
              <polyline points="22 4 12 14.01 9 11.01"></polyline>
            </svg>
          </div>
          <h1 class="text-xl py-2 font-medium">
            تم تغيير كلمة المرور بنجاح
          </h1>
        </div>

        <button
          @click="toggleSuccessMsg"
          class="bg-white text-green-700 focus:outline-none font-medium mx-3 text-lg rounded-md px-3 py-1"
        >
          حسناً
        </button>
      </div>
      <p
        v-if="errorMsg"
        @click="errorMsg = null"
        class="px-4 py-2 my-2 border flex items-center rounded-md border-red-600 bg-red-100 text-red-600 font-medium cursor-pointer"
      >
        <i class="bx bxs-error-circle mx-2 text-xl"></i>
        <span class="">{{ errorMsg }}</span>
      </p>
    </div>
  </transition>
</template>
<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      defualtInput:
        "p-2  bg-white rounded-md border w-full text-right focus-within:border-primary hover:border-primary focus:border-primary focus:outline-none focus:shadow-md my-1",
      loading: false,
      animate: false,
      success: false,
      currentPasswordpasswordtype: "password",
      passwordtype: "password",
      password_confirmationPasswordtype: "password",
      form: {
        currentPassword: null,
        password: null,
        password_confirmation: null,
      },
      errorMsg: null
    };
  },
  validations: {
    form: {
      currentPassword: { required },
      password: { required, minLength: minLength(6) },
      password_confirmation: {
        required,
        minLength: minLength(6),
        sameAsPassword: sameAs("password"),
      },
    },
  },
  created() {
    document.addEventListener("keyup", (evt) => {
      if (evt.keyCode === 27) {
        this.toggleSuccessMsg();
      }
    });
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$pending || this.$v.form.$error) return;
      this.loading = true;
      this.$store
        .dispatch("resetCurrentPassword", this.form)
        .then(() => {
          this.loading = false;
          this.success = true;
        })
        .catch((err) => {
          this.loading = false;
          this.errorMsg = err.message
        });
    },
    toggleSuccessMsg() {
      this.success = false;
      this.loading = false;
      this.$store.commit("toggleResetPassword");
    },
  },
  mounted() {
    setTimeout(() => {
      this.animate = true;
    }, 10);
  },
};
</script>
