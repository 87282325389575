<template>
  <div
    v-if="current_page && last_page"
    class="my-8 flex items-center justify-center z-50"
  >
    <button
      :disabled="current_page === from"
      :class="
        current_page === from
          ? `opacity-30 cursor-not-allowed`
          : `transform transition-all duration-500 hover:translate-x-1`
      "
      class="mx-3 flex items-cente"
      @click="() => setPage(current_page - 1)"
    >
      <i class="bx bx-chevron-right"></i>
      <span>السابق</span>
    </button>

    <ul class="mx-3 flex items-center justify-between w-3/4 overflow-scroll">
      <li v-for="index in last_page" :key="index">
        <button
          @click="() => setPage(index)"
          class="
            mx-2
            h-8
            w-8
            flex
            items-center
            justify-center
            rounded-full
            leading-loose
            tracking-wide
          "
          :class="
            index === current_page
              ? 'text-white bg-primary'
              : 'text-gray-800 bg-gray-50'
          "
        >
          <span>{{ index }}</span>
        </button>
      </li>
    </ul>

    <button
      :disabled="current_page === last_page"
      :class="
        current_page === last_page
          ? `opacity-30 cursor-not-allowed`
          : `transform transition-all duration-500 hover:-translate-x-1`
      "
      class="mx-3 flex items-cente"
      @click="() => setPage(current_page + 1)"
    >
      <span>التالي</span>
      <i class="bx bx-chevron-left"></i>
    </button>
  </div>
</template>


<script>
export default {
  name: "pagination",
  props: ["last_page", "current_page", "from"],
  data() {
    return {};
  },
  methods: {
    setPage(prop) {
      this.$emit("setPage", prop);
    },
  },
};
</script>