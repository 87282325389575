<template>
  <div class="py-12 container mx-auto px-5 lg:px-12">
    <div class="flex justify-between items-center py-4 border-b mb-10">
      <h1 class="text-h1Text text-3xl">اخر اخبار النقابة</h1>
      <router-link
        :to="{ name: 'AddNews' }"
        class="
          px-5
          py-2
          focus:outline-none
          hover:shadow-md
          rounded-md
          bg-primary
          text-white
          flex
          items-center
        "
      >
        اضافة<i class="bx bxs-plus-circle mx-1"></i>
      </router-link>
    </div>

    <div v-if="posts">
      <Post v-if="posts.length > 0" :posts="posts" />
      <p v-else class="my-8 text-center">لا يوجد بيانات لعرضها</p>
    </div>
    <div v-else class="my-8 mx-auto text-center w-full">
      <i class="text-3xl bx bx-loader-circle bx-spin text-primary"></i>
    </div>
  </div>
</template>
<script>
import Post from "./Post.vue";

export default {
  name: "News",
  created() {
    this.fetchPosts();
  },

  methods: {
    fetchPosts() {
      this.$store.dispatch("fetchNews");
    },
  },
  computed: {
    posts() {
      return this.$store.getters.news;
    },
  },
  components: {
    Post,
  },
};
</script>
