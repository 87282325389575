import axios from "axios";

const baseURL = "https://api.llms.ly";

export default axios.create({
    baseURL,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
    },
    // You can add your headers here
});