<template>
  <div class="container mx-auto py-6 px-4">
    <div class="flex justify-between items-center py-4 border-b mb-10">
      <h1 class="text-3xl">فروع النقابة</h1>
      <router-link
        :to="{ name: 'Branch' }"
        class="
          px-5
          py-2
          focus:outline-none
          hover:shadow-md
          rounded-md
          bg-primary
          text-white
          flex
          items-center
        "
      >
        اضافة<i class="bx bxs-plus-circle mx-1"></i>
      </router-link>
    </div>
    <div class="mb-4 flex">
      <div class="relative md:w-1/3">
        <input
          type="text"
          class="
            w-full
            pl-10
            pr-4
            py-2
            rounded-lg
            border border-inputBorder
            focus:outline-none
            focus:shadow-outline
            text-gray-600
            font-medium
          "
          placeholder="بحث"
          v-model="Search"
        />
      </div>
    </div>

    <div v-if="loading" class="flex justify-center my-5">
      <i
        class="text-4xl block mx-auto text-primary bx bx-loader-circle bx-spin"
      ></i>
    </div>

    <div v-else class="bg-white w-full">
      <table
        v-if="branches.length > 0"
        class="
          border-collapse
          rounded
          border border-inputBorder
          overflow-y-auto
          whitespace-no-wrap
          bg-white
          w-full
        "
      >
        <thead>
          <tr class="text-right">
            <th
              v-for="heading in headings"
              :key="heading.id"
              class="
                bg-gray-100
                border-b border-gray-200
                px-6
                py-2
                text-gray-600
                font-bold
                tracking-wider
                uppercase
                text-md
              "
              v-text="heading.value"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="branch in branches" :key="branch.id" class="">
            <td class="border-dashed border-t border-gray-200">
              <span class="text-gray-700 px-6 py-3 flex items-center">{{
                branch.name_ar
              }}</span>
            </td>
            <td class="border-dashed border-t border-gray-200">
              <span class="text-gray-700 px-6 py-3 flex items-center">{{
                $moment(branch.created_at).format("DD MM YYYY")
              }}</span>
            </td>
            <td class="border-dashed border-t border-gray-200">
              <span class="flex items-center">
                <button
                  class="
                    bg-primary
                    px-3
                    py-1
                    rounded-md
                    text-white
                    focus:outline-none
                  "
                  @click="editBranch(branch)"
                >
                  <span class="mx-2">تعديل</span>
                  <i class="bx bx-message-square-edit"></i>
                </button>
              </span>
            </td>
            <td class="border-dashed border-t border-gray-200">
              <span class="flex items-center">
                <button
                  class="
                    bg-red-600
                    px-3
                    py-1
                    rounded-md
                    text-white
                    focus:outline-none
                  "
                  @click="DeleteBranch(branch)"
                >
                  <span class="mx-2">حذف</span>
                  <i class="bx bx-trash"></i>
                </button>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="mx-auto text-center text-2xl font-medium my-12">
        لا يوجد نتائج
      </div>
      <transition name="fade">
        <div
          v-if="confirmDelete"
          class="
            fixed
            z-50
            inset-0
            flex
            items-center
            justify-center
            w-screen
            h-screen
          "
          style="background: rgba(0, 0, 0, 0.4)"
        >
          <div
            class="
              bg-white
              p-5
              flex
              items-center
              flex-wrap
              md:rounded-lg
              border border-red-600
              overflow-hidden
              shadow-2xl
              sm:h-auto
              w-3/4
              sm:w-1/2
              lg:w-1/3
              rounded-md
              z-50
            "
          >
            <div class="flex items-center text-red-700 my-2">
              <i class="bx bxs-error-circle text-5xl mx-2"></i>
              <h1 class="py-2 font-medium text-xl">
                {{ `هل انت متأكد من حذف فرع ${confirmDelete.name_ar} نهائياً` }}
              </h1>
            </div>

            <p class="text-red-700 text-lg my-2">
              <span class="font-bold">ملاحظة: </span>سيتم حذف جميع الاعضاء
              والموضفين المسجلين في هذا الفرع
            </p>

            <div class="flex flex-auto w-full my-5">
              <button
                @click="YesconfirmDelete"
                class="
                  text-white
                  bg-red-600
                  focus:outline-none
                  font-medium
                  mx-3
                  text-lg
                  rounded-md
                  px-3
                  py-1
                "
              >
                نعم
              </button>
              <button
                @click="confirmDelete = null"
                class="
                  bg-white
                  border
                  font-bold
                  border-gray-600
                  text-gray-600
                  focus:outline-none
                  mx-3
                  text-lg
                  rounded-md
                  px-4
                  py-1
                "
              >
                لا
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  name: "branches",
  data() {
    return {
      headings: [
        {
          id: 0,
          value: "الفرع",
        },
        {
          id: 1,
          value: "تاريخ التسجيل ",
        },
        {
          id: 2,
          value: "",
        },
        {
          id: 3,
          value: "",
        },
      ],
      Search: "",
      loading: false,
      confirmDelete: null,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.userData.status == "super-admin") {
        this.loading = true;
        this.$store.dispatch("getBranches").then(() => {
          this.loading = false;
        });
      } else {
        this.$router.push({ name: "Signin" });
      }
    },
    editBranch(branch) {
      this.$store.commit("editBranch", branch);
      this.$router.push({ name: "Branch" });
    },
    DeleteBranch(branch) {
      this.confirmDelete = branch;
    },
    YesconfirmDelete() {
      this.$store.dispatch("DeleteBranch", this.confirmDelete.id).then(() => {
        this.confirmDelete = null;
        this.getData();
      });
    },
  },
  computed: {
    branches() {
      if (this.$store.getters.getBranches) {
        return this.$store.getters.getBranches.filter((branch) => {
          return branch.name_ar.match(this.Search);
        });
      } else {
        return null;
      }
    },
    userData() {
      return this.$store.getters.userData;
    },
  },
};
</script>
