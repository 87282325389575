<template>
  <div class="container mx-auto py-6 px-4">
    <div class="flex justify-between items-center py-4 border-b mb-10">
      <h1 class="text-3xl">الموظفين</h1>
      <router-link
        :to="{ name: 'Employees' }"
        class="
          px-5
          py-2
          focus:outline-none
          hover:shadow-md
          rounded-md
          bg-primary
          text-white
          flex
          items-center
        "
      >
        اضافة<i class="bx bxs-plus-circle mx-1"></i>
      </router-link>
    </div>
    <div class="mb-4 flex items-center flex-wrap">
      <div class="relative w-full md:w-auto flex items-center">
        <input
          type="text"
          class="
            w-full
            pl-10
            pr-4
            py-2
            rounded-lg
            border border-inputBorder
            focus:outline-none focus:shadow-outline
            text-gray-600
            font-medium
          "
          placeholder="بحث"
          v-model="Search"
          @keyup.enter="filterBySearch"
        />
        <i
          @click="filterBySearch"
          class="bx bx-search-alt z-20 text-gray-500 -mr-8 cursor-pointer"
        ></i>
      </div>
      <v-select
        dir="rtl"
        v-if="Branches && userData.status == 'super-admin'"
        :options="Branches"
        label="name_ar"
        v-model="branch_id"
        placeholder="النقابة"
        class="
          mx-0
          my-2
          md:my-0 md:mx-3
          w-full
          md:w-1/4
          bg-white
          rounded-md
          border border-inputBorder
          p-1
        "
      ></v-select>
      <v-select
        dir="rtl"
        :options="['الأحدث', 'الأقدم']"
        v-model="SortBy"
        placeholder="ترتيب بأستخدام"
        class="
          mx-0
          my-2
          md:mx-3
          w-full
          md:w-1/4
          bg-white
          rounded-md
          border border-inputBorder
          p-1
        "
      ></v-select>
    </div>

    <div v-if="loading" class="flex justify-center">
      <i class="text-4xl mx-auto text-primary bx bx-loader-circle bx-spin"></i>
    </div>

    <div
      v-else
      class="bg-white rounded border border-inputBorder overflow-y-auto w-full"
    >
      <table
        v-if="employees.data.length > 0"
        class="border-collapse whitespace-no-wrap bg-white w-full"
      >
        <thead>
          <tr class="text-right">
            <th
              v-for="heading in headings"
              :key="heading.id"
              class="
                bg-gray-100
                border-b border-gray-200
                px-6
                py-2
                text-gray-600
                font-bold
                tracking-wider
                uppercase
                text-md
              "
              v-text="heading.value"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="employee in employees.data" :key="employee.id" class="">
            <td class="border-dashed border-t border-gray-200">
              <span class="text-gray-700 px-6 py-3 flex items-center">{{
                employee.name_ar
              }}</span>
            </td>
            <td class="border-dashed border-t border-gray-200">
              <span class="text-gray-700 px-6 py-3 flex items-center">{{
                employee.job_description
              }}</span>
            </td>
            <td class="border-dashed border-t border-gray-200">
              <span class="text-gray-700 px-6 py-3 flex items-center">{{
                employee.branch.name_ar
              }}</span>
            </td>
            <td
              class="border-dashed border-t border-gray-200 flex items-center"
            >
              <span class="text-gray-700 px-6 py-3 flex items-center">
                <label class="inline-flex items-center">
                  <input
                    type="checkbox"
                    class="form-checkbox h-5 w-5 text-blue-600"
                    :class="
                      userData.status != 'super-admin'
                        ? 'cursor-not-allowed'
                        : null
                    "
                    :disabled="userData.status != 'super-admin'"
                    :checked="dialog == employee || employee.status == 'مسؤول'"
                    @click.prevent="setAdmin(employee)"
                  />
                </label>
              </span>
            </td>
            <td class="border-dashed border-t border-gray-200">
              <button
                @click="deleteConfirmation = employee"
                class="
                  bg-red-600
                  flex
                  text-sm
                  items-center
                  text-white
                  rounded-md
                  px-2
                  py-1
                "
              >
                <span class="mx-1">حذف</span>
                <i class="bx bx-trash text-xl"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else class="my-8 text-center">لا يوجد بيانات لعرضها</p>

      <transition name="fade">
        <div
          v-if="dialog"
          class="fixed h-screen inset-0 flex items-center justify-center z-40"
          style="background: rgba(0, 0, 0, 0.3)"
        >
          <employeeEmail @fetchData="fetchData()" />
        </div>
      </transition>
      <transition name="fade">
        <div
          v-if="removeEmployeeConfirmation"
          class="fixed h-screen inset-0 flex items-center justify-center z-40"
          style="background: rgba(0, 0, 0, 0.3)"
        >
          <div class="bg-white rounded-lg w-1/2 p-12">
            <h4 class="text-2xl">
              هل انت متأكد من ازلة المسؤول
              {{ removeEmployeeConfirmation.name_ar }} من قائمة المسؤوليين ؟
            </h4>
            <p class="text-xl my-1 text-gray-700">
              <b>ملاحظة:</b>سيتم حذف جميع الصلاحيات من الموظف
            </p>
            <div class="flex items-center justify-start mt-3">
              <button
                @click="removeSupervisor(removeEmployeeConfirmation)"
                class="px-5 py-2 rounded-lg bg-red-600 text-white"
              >
                تأكيد
              </button>
              <button
                @click="removeEmployeeConfirmation = null"
                class="px-5 py-2 rounded-lg mx-8 bg-primary text-white"
              >
                تراجع
              </button>
            </div>
          </div>
        </div>
      </transition>
      <!-- delete confirmation -->
      <transition name="fade">
        <div
          v-if="deleteConfirmation"
          class="fixed h-screen inset-0 flex items-center justify-center z-40"
          style="background: rgba(0, 0, 0, 0.3)"
        >
          <div class="bg-white rounded-lg w-1/2 p-12">
            <h4 class="text-2xl">
              هل انت متأكد من حذف الموظف
              {{ deleteConfirmation.name_ar }} ؟
            </h4>

            <div class="flex items-center justify-start mt-3">
              <button
                @click="deleteEmployee(deleteConfirmation)"
                class="px-5 py-2 rounded-lg bg-red-600 text-white"
              >
                تأكيد
              </button>
              <button
                @click="deleteConfirmation = null"
                class="px-5 py-2 rounded-lg mx-8 bg-primary text-white"
              >
                تراجع
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div class="w-full my-12" v-if="employees">
      <pagination
        :last_page="employees.last_page"
        :current_page="employees.current_page"
        :from="employees.from"
        @setPage="clickCallback"
      />
    </div>

    <div v-if="errMsg && !loading">
      <infoAlert :message="errMsg" />
    </div>

    <transition name="fade">
      <div
        v-if="successMsg"
        class="
          fixed
          z-50
          inset-0
          flex
          items-start
          justify-center
          text-center
          w-screen
          h-screen
        "
        style="background: rgba(0, 0, 0, 0.3)"
      >
        <transition name="slideTop">
          <div
            class="
              flex
              justify-center
              items-center
              m-1
              font-medium
              py-1
              px-2
              rounded-md
              text-green-100
              bg-green-700
              border border-green-700
            "
          >
            <div slot="avatar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-check-circle w-5 h-5 mx-2"
              >
                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                <polyline points="22 4 12 14.01 9 11.01"></polyline>
              </svg>
            </div>
            <div class="text-xl font-normal text-right">
              <h1 class="py-2 font-medium">
                تم استلام طلبك بنجاح
                <p class="text-sm font-base">
                  {{ successMsg }}
                </p>
              </h1>
            </div>
            <div class="flex flex-auto">
              <button
                @click="successMsg = null"
                class="
                  bg-white
                  text-green-700
                  focus:outline-none
                  font-medium
                  mx-3
                  text-lg
                  rounded-md
                  px-3
                  py-1
                "
              >
                حسناً
              </button>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>
<script>
import infoAlert from "../../layout/infoAlert";
import employeeEmail from "../../layout/employeeEmail";
export default {
  name: "employees",
  data() {
    return {
      branch_id: null,
      loading: true,
      page: 1,
      sort: "Desc",
      SortBy: null,
      errorMsg: null,
      removeEmployeeConfirmation: null,
      deleteConfirmation: null,
      headings: [
        {
          id: 0,
          value: "اسم الموظف",
        },
        {
          id: 1,
          value: "صفته",
        },
        {
          id: 2,
          value: "الفرع",
        },
        {
          id: 3,
          value: "مسؤول",
        },
        {
          id: 4,
          value: "",
        },
      ],
      Search: "",
      errMsg: "",
      successMsg: null,
    };
  },
  components: {
    infoAlert,
    employeeEmail,
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.errMsg = "";
      this.$store
        .dispatch("getAllemployees", {
          page: this.page,
          sort: this.sort,
          search: this.Search,
          branch_id: this.branch_id ? this.branch_id.id : "",
        })
        .then((res) => {
          this.$store.commit("toggleDialog", null);
          if (res.data.data == "") {
            this.errMsg = "لا يوجد موظفيين مسجلين بعد";
          }
        })

        .finally(() => (this.loading = false));
    },
    filterBySearch() {
      this.fetchData();
    },
    clickCallback: function (pageNum) {
      if (!pageNum) return;
      this.page = pageNum;
      this.fetchData();
    },
    SortByMethods() {
      if (this.SortBy == "الأقدم") {
        this.sort = "Asc";
        this.fetchData();
      } else {
        this.sort = "Desc";
        this.fetchData();
      }
    },
    setAdmin(employee) {
      if (employee.status == "مسؤول") {
        this.removeEmployeeConfirmation = employee;
        return;
      } else {
        this.$store.commit("toggleDialog", employee);
      }
    },
    deleteEmployee(employee) {
      this.loading = true;
      this.$store.dispatch("DeleteEmployee", employee.id).finally(() => {
        this.loading = false;
        this.successMsg = `تم حذف الموظف ${employee.name_ar}   `;
        this.fetchData();
        this.deleteConfirmation = null;
      });
    },
    removeSupervisor(employee) {
      this.loading = true;
      this.$store
        .dispatch("removeSupervisor", employee.id)
        .then(() => {
          this.loading = false;
          this.successMsg = `تم ازلة الموظف ${employee.name_ar} من قائمة المسؤوليين`;
          this.fetchData();
          this.removeEmployeeConfirmation = null;
        })
        .catch((err) => {
          this.errMsg = err.message;
          this.loading = false;
        });
    },
  },
  watch: {
    branch_id() {
      this.fetchData();
    },
    SortBy() {
      this.SortByMethods();
    },
  },
  computed: {
    employees() {
      return this.$store.getters.getEmployees;
    },
    Branches() {
      return this.$store.getters.getBranches;
    },
    dialog() {
      return this.$store.getters.Dialog;
    },
    userData() {
      return this.$store.getters.userData;
    },
  },
};
</script>
