import axios from "@/axios";

const newRegistration = {
    state: () => ({
        individualData: null,
        companyData: null,
    }),
    mutations: {
        individualFormFilling(state, payload) {
            if (payload) {
                state.individualData = {
                    ...state.individualData,
                    ...payload,
                };
            } else {
                state.individualData = null;
            }
        },
        companyFormFilling(state, payload) {
            if (payload) {
                state.companyData = {...state.companyData, ...payload };
            } else {
                state.companyData = null;
            }
        },
    },
    actions: {
        submitIndividual({ state }) {
            const formData = new FormData();

            const newForm = {
                ...state.individualData,
            };

            if (newForm.nationality == "Libyan") {
                delete newForm.passport_copy;
                delete newForm.residency_photo;
            } else {
                delete newForm.id_number,
                    delete newForm.type_of_identity,
                    delete newForm.identity_number;
            }

            for (const [key, value] of Object.entries(newForm)) {
                if (value && value != "null") {
                    formData.append(key, value);
                }
            }

            return new Promise((resolve, reject) => {
                axios
                    .post("/api/doctors", formData, {
                        headers: {
                            "content-type": "multipart/form-data",
                        },
                    })
                    .then((res) => {
                        resolve(res);
                        state.individualData = null;
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        editSubmitIndividual({ state }, id) {
            const formData = new FormData();

            const newForm = {
                ...state.individualData,
            };

            if (newForm.nationality == "Libyan") {
                delete newForm.passport_copy;
                delete newForm.residency_photo;
            } else {
                delete newForm.id_number,
                    delete newForm.type_of_identity,
                    delete newForm.identity_number;
            }

            for (const [key, value] of Object.entries(newForm)) {
                if (value && value != "null") {
                    formData.append(key, value);
                }
            }

            formData.append("_method", "put");

            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/doctors/${id}`, formData, {
                        headers: {
                            "content-type": "multipart/form-data",
                        },
                    })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        labsSubmiting(_, payload) {
            return new Promise((resolve, reject) => {
                axios({
                        url: "/api/labs",
                        method: "post",
                        data: {...payload },
                    })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        editLabsSubmiting({ state }, payload) {
            if (!state) return;
            return new Promise((resolve, reject) => {
                axios({
                        url: `/api/labs/${payload.req_id}`,
                        method: "post",
                        data: {...payload.newFormat, _method: "put" },
                    })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        companySubmiting({ state }) {
            const formData = new FormData();

            const newForm = {
                ...state.companyData,
                registration_date: state.companyData.registration_date,
            };

            for (const [key, value] of Object.entries(newForm)) {
                if (value && value != "null") {
                    formData.append(key, value);
                }
            }
            return new Promise((resolve, reject) => {
                axios({
                        url: "/api/companies",
                        method: "post",
                        headers: {
                            "content-type": "multipart/form-data",
                        },
                        data: formData,
                    })
                    .then((res) => {
                        resolve(res);
                        state.companyData = null;
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        editCompanySubmiting({ state }, id) {
            const formData = new FormData();

            let newForm = {...state.companyData };

            if (typeof newForm.statement_of_graduate == "string") {
                delete newForm.statement_of_graduate;
            }
            if (typeof newForm.better_from_education_department == "string") {
                delete newForm.better_from_education_department;
            }
            if (typeof newForm.better_from_quality_department == "string") {
                delete newForm.better_from_quality_department;
            }
            if (typeof newForm.birth_certificate == "string") {
                delete newForm.birth_certificate;
            }
            if (typeof newForm.proof_of_identity == "string") {
                delete newForm.proof_of_identity;
            }
            if (typeof newForm.personal_image == "string") {
                delete newForm.personal_image;
            }
            if (typeof newForm.image_of_subscription == "string") {
                delete newForm.image_of_subscription;
            }
            if (typeof newForm.minutes_of_the_meeting == "string") {
                delete newForm.minutes_of_the_meeting;
            }
            if (typeof newForm.the_general_assembly == "string") {
                delete newForm.the_general_assembly;
            }
            if (
                typeof newForm.copy_of_a_valid_license_to_practice_the_profession ==
                "string"
            ) {
                delete newForm.copy_of_a_valid_license_to_practice_the_profession;
            }
            if (typeof newForm.former_permission_of_company == "string") {
                delete newForm.former_permission_of_company;
            }
            if (typeof newForm.experience_certificate == "string") {
                delete newForm.experience_certificate;
            }
            if (typeof newForm.contract_of_establishment_of_company == "string") {
                delete newForm.contract_of_establishment_of_company;
            }
            if (typeof newForm.commercial_record == "string") {
                delete newForm.commercial_record;
            }
            if (typeof newForm.chamber_of_commerce == "string") {
                delete newForm.chamber_of_commerce;
            }
            if (typeof newForm.former_license == "string") {
                delete newForm.former_license;
            }
            if (typeof newForm.doctor_image == "string") {
                delete newForm.doctor_image;
            }

            for (const [key, value] of Object.entries(newForm)) {
                if (value && value != "null") {
                    formData.append(key, value);
                }
            }

            formData.append("_method", "put");
            return new Promise((resolve, reject) => {
                axios({
                        url: `/api/companies/${id}`,
                        method: "post",
                        headers: {
                            "content-type": "multipart/form-data",
                        },
                        data: formData,
                    })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        addEmployee({ getters }, payload) {
            if (getters.userData) {
                return new Promise((resolve, reject) => {
                    axios
                        .post("/api/employees", payload)
                        .then((res) => {
                            resolve(res);
                        })
                        .catch((err) => {
                            reject(err.response);
                        });
                });
            }
        },
        editBranch({ getters }, payload) {
            if (getters.userData.status == "super-admin") {
                return new Promise((resolve, reject) => {
                    axios
                        .post(`/api/branches/${payload.id}`, {...payload, _method: "put" })
                        .then((res) => {
                            resolve(res);
                        })
                        .catch((err) => {
                            reject(err.response.data);
                        });
                });
            }
        },
        DeleteBranch({ getters }, payload) {
            if (getters.userData.status == "super-admin") {
                return new Promise((resolve, reject) => {
                    axios
                        .post(`/api/branches/${payload}`, { _method: "delete" })
                        .then((res) => {
                            resolve(res);
                        })
                        .catch((err) => {
                            reject(err.response.data);
                        });
                });
            }
        },
        addNewBranch({ getters }, payload) {
            if (getters.userData.status == "super-admin") {
                return new Promise((resolve, reject) => {
                    axios
                        .post("/api/branches", payload)
                        .then((res) => {
                            resolve(res);
                        })
                        .catch((err) => {
                            reject(err.response.data);
                        });
                });
            }
        },
        renew_req_doctor(context, payload) {
            if (!context.state) return;
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/membership/renewalDoctorRequest", { id: +payload })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err.response);
                    });
            });
        },
        renew_req_company(context, payload) {
            if (!context.state) return;
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/membership/renewalCompanyRequest", { id: +payload })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err.response);
                    });
            });
        },
        renew_req_lab(context, payload) {
            if (!context.state) return;
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/membership/renewalLabRequest", { id: +payload })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err.response);
                    });
            });
        },
    },
    getters: {
        individualData(state) {
            return state.individualData;
        },
        companyData(state) {
            return state.companyData;
        },
    },
};

export default newRegistration;