import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import Vuelidate from "vuelidate";
import vSelect from "vue-select";
import moment from "moment";
import axios from "./axios";
import pagination from './components/layout/pagination'

import "vue-select/dist/vue-select.css";
import "./styles.css";

Vue.prototype.$moment = moment;
Vue.prototype.$axios = axios;

Vue.use(Vuelidate);

Vue.component("v-select", vSelect);
Vue.component(pagination.name, pagination)
Vue.config.productionTip = true;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
