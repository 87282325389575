<template>
  <div class="w-full h-full">
    <nav class="w-full z-50 bg-primary mb-5">
      <div class="container mx-auto px-8 flex items-center justify-start py-2">
        <img
          @click="$router.push('/')"
          src="../../assets/imgs/libyan_labs_logo_dark_bg.png"
          class="cursor-pointer"
          width="65"
          height="65"
          alt=""
        />
        <h1 class="text-right text-white text-xl font-bold p-5">
          نموذج بيانات تسجيل المختبرات الطبية
        </h1>
      </div>
    </nav>
    <div class="w-full h-full flex flex-wrap mt-5">
      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-2
          md:p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
          المعلومات الاساسية
        </span>
        <div
          v-if="Branches"
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="branch_id"
            class="font-medium text-lg w-full text-right flex items-center"
            ><span class="text-red-600 mx-2 text-3xl mt-2">*</span> فرع
            النقابة</label
          >
          <v-select
            v-if="Branches"
            :options="Branches"
            label="name_ar"
            dir="rtl"
            id="branch_id"
            @blur="$v.form.branch_id.$touch()"
            name="branch_id"
            v-model="form.branch_id"
            :reduce="(branch_id) => branch_id.id"
            :class="
              defualtInput +
              ($v.form.branch_id.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
            class="p-0"
          ></v-select>
          <p
            v-if="$v.form.branch_id.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            الرجاء تعبئة هذا الحقل
          </p>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="member_ship_id"
            class="font-medium text-lg w-full text-right flex items-center"
            >رقم العضوية</label
          >
          <input
            id="member_ship_id"
            name="member_ship_id"
            v-model="form.member_ship_id"
            type="text"
            required
            @blur="handleMemberShipIdBlur"
            :class="
              defualtInput +
              (memeberShipExsistError
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />

          <p
            v-if="memeberShipExsistError"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ memeberShipExsistError }}
          </p>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="lab_name"
            class="font-medium text-lg w-full text-right flex items-center"
          >
            <span class="text-red-600 mx-2 text-3xl mt-2">*</span> اسم المختبر
            الطبي
          </label>
          <input
            id="lab_name"
            name="lab_name"
            v-model="form.lab_name"
            type="text"
            required
            @blur="$v.form.lab_name.$touch()"
            :class="
              defualtInput +
              ($v.form.lab_name.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.lab_name.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            الرجاء تعبئة هذا الحقل
          </p>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="chamber_of_commerce_registration_number"
            class="font-medium text-lg w-full text-right flex items-center"
          >
            رقم التسجيل بالغرفة التجارية
          </label>
          <input
            id="chamber_of_commerce_registration_number"
            name="chamber_of_commerce_registration_number"
            v-model="form.chamber_of_commerce_registration_number"
            type="text"
            required
            :class="defualtInput + 'border-inputBorder'"
          />
        </div>

        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="email"
            class="font-medium text-lg w-full text-right flex items-center"
          >
            البريد الالكتروني
          </label>
          <input
            id="email"
            name="email"
            v-model="form.email"
            type="text"
            required
            :class="defualtInput + 'border-inputBorder'"
          />
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="website"
            class="font-medium text-lg w-full text-right flex items-center"
          >
            الموقع الالكتروني ان وجد
          </label>
          <input
            id="website"
            name="website"
            v-model="form.website"
            type="text"
            required
            :class="defualtInput + 'border-inputBorder'"
          />
        </div>
      </div>
      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
          بيانات الترخيص
        </span>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="license_number"
            class="font-medium text-lg w-full text-right flex items-center"
          >
            رقم الترخيص
          </label>
          <input
            id="license_number"
            name="license_number"
            v-model="form.license_number"
            type="text"
            required
            :class="defualtInput + 'border-inputBorder'"
          />
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="Where_license_issued"
            class="
              font-medium
              text-lg
              w-full
              text-right text-h1Text
              flex
              items-center
            "
          >
            جهة صدوره</label
          >
          <input
            id="Where_license_issued"
            name="Where_license_issued"
            v-model="form.Where_license_issued"
            type="text"
            required
            :class="defualtInput + 'border-inputBorder'"
          />
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="date_of_license_issue"
            class="font-medium text-lg w-full text-right flex items-center"
            >التاريخ</label
          >
          <input
            id="date_of_license_issue"
            name="date_of_license_issue"
            v-model="form.date_of_license_issue"
            :class="defualtInput + 'border-inputBorder'"
            type="date"
          />
        </div>
      </div>
      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-2
          md:p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
          بيانات اذن المزاولة
        </span>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="Service_practice_number"
            class="font-medium text-lg w-full text-right flex items-center"
          >
            رقم اذن المزاولة
          </label>
          <input
            id="Service_practice_number"
            name="Service_practice_number"
            v-model="form.Service_practice_number"
            type="text"
            required
            :class="defualtInput + 'border-inputBorder'"
          />
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="where_service_practice_issued"
            class="
              font-medium
              text-lg
              w-full
              text-right text-h1Text
              flex
              items-center
            "
            >جهة صدوره</label
          >
          <input
            id="where_service_practice_issued"
            name="where_service_practice_issued"
            v-model="form.where_service_practice_issued"
            type="text"
            required
            :class="defualtInput + 'border-inputBorder'"
          />
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="date_of_service_practice_issue"
            class="font-medium text-lg w-full text-right flex items-center"
          >
            التاريخ</label
          >
          <input
            id="date_of_service_practice_issue"
            name="date_of_service_practice_issue"
            v-model="form.date_of_service_practice_issue"
            :class="defualtInput + 'border-inputBorder'"
            type="date"
          />
        </div>
      </div>
      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-2
          md:p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
          البيانات الشخصية
        </span>

        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="lap_owner_name"
            class="
              font-medium
              text-lg
              w-full
              text-right text-h1Text
              flex
              items-center
            "
            ><span class="text-red-600 mx-2 text-3xl mt-2">*</span> اسم صاحب
            المختبر</label
          >
          <input
            id="lap_owner_name"
            name="lap_owner_name"
            v-model="form.lap_owner_name"
            type="text"
            required
            @blur="$v.form.lap_owner_name.$touch()"
            :class="
              defualtInput +
              ($v.form.lap_owner_name.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.lap_owner_name.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            الرجاء تعبئة هذا الحقل
          </p>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="qualification"
            class="font-medium text-lg w-full text-right flex items-center"
            ><span class="text-red-600 mx-2 text-3xl mt-2">*</span> المؤهل
            العلمي</label
          >
          <input
            id="qualification"
            name="qualification"
            v-model="form.qualification"
            type="text"
            required
            @blur="$v.form.qualification.$touch()"
            :class="
              defualtInput +
              ($v.form.qualification.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.qualification.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            الرجاء تعبئة هذا الحقل
          </p>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="owner_phone_number"
            class="
              font-medium
              text-lg
              w-full
              text-right text-h1Text
              flex
              items-center
            "
            ><span class="text-red-600 mx-2 text-3xl mt-2">*</span> رقم
            الهاتف</label
          >
          <input
            id="owner_phone_number"
            name="owner_phone_number"
            v-model="form.owner_phone_number"
            type="tel"
            required
            @blur="$v.form.owner_phone_number.$touch()"
            :class="
              defualtInput +
              ($v.form.owner_phone_number.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="
              $v.form.owner_phone_number.$error &&
              !$v.form.owner_phone_number.required
            "
            class="text-red-600 text-md font-medium w-full text-right"
          >
            الرجاء كتابة رقم الهاتف
          </p>
          <p
            v-if="!$v.form.owner_phone_number.minLength"
            class="text-red-600 text-md font-medium w-full text-right"
          >
            الرجاء التحقق من صحة رقم الهاتف، رقم الهاتف يجب ن يكون على النحو
            التالي 09xxxxxxxx
          </p>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="owner_email"
            class="
              font-medium
              text-lg
              w-full
              text-right text-h1Text
              flex
              items-center
            "
            >البريد الالكتروني</label
          >
          <input
            id="owner_email"
            name="owner_email"
            v-model="form.owner_email"
            type="email"
            required
            :class="defualtInput + 'border-inputBorder'"
          />
        </div>
      </div>
      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
          عنوان المختبر
        </span>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="city"
            class="
              font-medium
              text-lg
              w-full
              text-right text-h1Text
              flex
              items-center
            "
            ><span class="text-red-600 mx-2 text-3xl mt-2">*</span
            >المدينة</label
          >
          <input
            id="city"
            name="city"
            v-model="form.city"
            type="address"
            required
            @blur="$v.form.city.$touch()"
            :class="
              defualtInput +
              ($v.form.city.$error ? ' border-red-700' : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.city.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            الرجاء تعبئة هذا الحقل
          </p>
        </div>

        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="region"
            class="
              font-medium
              text-lg
              w-full
              text-right text-h1Text
              flex
              items-center
            "
            ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>
            المنطقة</label
          >
          <input
            id="region"
            name="region"
            v-model="form.region"
            type="address"
            required
            @blur="$v.form.region.$touch()"
            :class="
              defualtInput +
              ($v.form.region.$error ? ' border-red-700' : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.region.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            الرجاء تعبئة هذا الحقل
          </p>
        </div>

        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="street"
            class="
              font-medium
              text-lg
              w-full
              text-right text-h1Text
              flex
              items-center
            "
            ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>
            الشارع</label
          >
          <input
            id="street"
            name="street"
            v-model="form.street"
            type="address"
            required
            @blur="$v.form.street.$touch()"
            :class="
              defualtInput +
              ($v.form.street.$error ? ' border-red-700' : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.street.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            الرجاء تعبئة هذا الحقل
          </p>
        </div>
      </div>
      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
          بيانات اضافية
        </span>
        <div
          class="
            p-5
            flex
            items-center
            justify-center
            w-full
            lg:w-3/4
            mx-auto
            flex-wrap
          "
        >
          <label
            for="notes"
            class="font-medium text-h1Text text-lg w-full text-right"
            >مذكرة</label
          >
          <textarea
            v-model="form.notes"
            class="form-textarea"
            :class="defualtInput"
          >
          </textarea>
        </div>
      </div>
    </div>
    <div class="flex w-full lg:w-3/4 mx-auto items-center justify-end">
      <p v-if="$v.form.$error" class="text-red-600 text-md font-medium">
        الرجاء تكملة الحقول المطلوبة
      </p>
      <button
        @click="
          clear;
          $router.go(-1);
        "
        class="
          px-8
          rounded-md
          w-auto
          shadow
          py-2
          focus:outline-none
          hover:shadow-lg
          m-3
          md:m-5
          border border-primary
          text-primary
          bg-white
          text-lg
          font-medium
        "
      >
        رجوع
      </button>
      <button
        :disabled="$v.form.$error"
        @click.prevent="submit"
        class="
          px-8
          rounded-md
          w-auto
          flex
          items-center
          shadow
          py-2
          focus:outline-none
          hover:shadow-lg
          m-3
          md:m-5
          bg-primary
          text-white text-lg
          font-medium
        "
      >
        <span>تأكيد </span
        ><i
          v-if="loading"
          class="text-2xl mx-2 bx bx-loader-circle bx-spin"
        ></i>
      </button>
    </div>
  </div>
</template>
<script>
import {
  required,
  email,
  numeric,
  url,
  minLength,
} from "vuelidate/lib/validators";
import moment from "moment";
export default {
  name: "labs",
  data() {
    return {
      defualtInput:
        "p-2  bg-white rounded-md border w-full text-right focus-within:border-primary hover:border-primary focus:border-primary focus:outline-none focus:shadow-md my-1",
      maxDate: moment().toISOString(),
      loading: false,
      memeberShipExsistError: "",
      form: {
        branch_id: null,
        member_ship_id: "",
        lab_name: null,
        chamber_of_commerce_registration_number: null,
        email: null,
        website: "",
        license_number: null,
        Where_license_issued: null,
        date_of_license_issue: null,
        Service_practice_number: null,
        where_service_practice_issued: null,
        date_of_service_practice_issue: null,
        lap_owner_name: null,
        qualification: null,
        owner_email: null,
        owner_phone_number: null,
        city: null,
        region: null,
        street: null,
        notes: null,
      },
    };
  },
  computed: {
    userData() {
      return this.$store.getters.userData;
    },
    Branches() {
      return this.$store.getters.getBranches;
    },
    editLab() {
      return this.$store.getters.editLab;
    },
  },
  mounted() {
    this.$store.dispatch("getBranches");
    if (this.editLab) {
      this.form = {
        branch_id: this.editLab.branch_id,
        lab_name: this.editLab.lab_name,
        chamber_of_commerce_registration_number:
          this.editLab.chamber_of_commerce_registration_number,
        email: this.editLab.email,
        member_ship_id: this.editLab.member_ship_id,
        license_number: this.editLab.license_number,
        Where_license_issued: this.editLab.where_license_issued,
        date_of_license_issue: this.editLab.date_of_license_issue,
        Service_practice_number: this.editLab.service_practice_number,
        where_service_practice_issued:
          this.editLab.where_service_practice_issued,
        date_of_service_practice_issue:
          this.editLab.date_of_service_practice_issue,
        lap_owner_name: this.editLab.lap_owner_name,
        owner_email: this.editLab.owner_email,
        qualification: this.editLab.qualification,
        owner_phone_number: this.editLab.owner_phone_number,
        city: this.editLab.city,
        region: this.editLab.region,
        street: this.editLab.street,
        notes: this.editLab.notes,
      };
    }
  },
  created() {
    window.onpopstate = () => {
      if (this.$route.name == "Home" || this.$route.name == "LabsPanel") {
        this.clear();
      }
    };
    if (this.userData) {
      if (this.userData.status !== "super-admin") {
        this.form.branch_id = this.userData.branch_id;
      }
    }
  },
  validations() {
    return {
      form: {
        branch_id: { required, numeric },
        lab_name: { required },
        email: { email },
        website: { url },
        lap_owner_name: { required },
        qualification: { required },
        owner_phone_number: { required, minLength: minLength(10) },
        city: { required },
        region: { required },
        street: { required },
      },
    };
  },
  methods: {
    clear() {
      this.$store.commit("editLab", null);
    },
    async handleMemberShipIdBlur(e) {
      try {
        await this.$axios.post("/api/labs/search", {
          member_ship_id: e.target.value,
        });
        this.memeberShipExsistError = "";
      } catch (err) {
        if (e.response.status == 409) {
          this.memeberShipExsistError = e.response.data.error;
        }
      }
    },
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$pending || this.$v.form.$error) return;
      this.loading = true;
      const newFormat = {
        ...this.form,
        date_of_license_issue: moment(this.date_of_license_issue).format(
          "YYYY/MM/DD"
        ),
        date_of_service_practice_issue: moment(
          this.date_of_service_practice_issue
        ).format("YYYY/MM/DD"),
      };

      if (!this.editLab) {
        this.$store
          .dispatch("labsSubmiting", newFormat)
          .then(() => {
            this.loading = false;
            this.$store.commit(
              "toggleSuccessMsg",
              "سيتم مراجعة الطلب وارسال القبول على بريدك الإلكتروني"
            );
          })
          .catch((messages) => {
            this.loading = false;
            this.$store.commit("toggleErrorMsg", messages);
          });
      } else {
        this.$store
          .dispatch("editLabsSubmiting", {
            newFormat,
            req_id: this.editLab.id,
          })
          .then(() => {
            this.loading = false;
            this.$store.commit("toggleSuccessMsg", "تم التعديل بنجاح");
          })
          .catch((messages) => {
            this.loading = false;
            this.$store.commit("toggleErrorMsg", messages);
          });
      }
    },
  },
};
</script>
