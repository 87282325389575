<template>
  <div class="py-12 container mx-auto px-5 lg:px-12">
    <h1 class="text-h1Text text-3xl mt-lgMargin">اضافة حدث جديد</h1>

    <form class="my-5" @submit.prevent="submit">
      <div class="grid grid-cols-1 md:grid-cols-2 md:gap-12">
        <div class="w-full mb-5">
          <input
            type="text"
            name="title"
            id="title"
            v-model="form.title_ar"
            @blur="$v.form.title_ar.$touch()"
            class="w-full rounded-lg px-4 py-2 border"
            placeholder="عنوان الحدث بالعربي"
            :class="
              $v.form.title_ar.$error ? 'border-red-500' : 'border-gray-300'
            "
          />
          <p
            v-if="$v.form.title_ar.$error"
            class="text-red-500 text-sm font-medium tracking-wide"
          >
            يجب كتابة عنوان الحدث باللغة العربية
          </p>
        </div>
        <div class="w-full mb-5">
          <input
            type="text"
            name="title_en"
            id="title_en"
            v-model="form.title_en"
            @blur="$v.form.title_en.$touch()"
            class="w-full rounded-lg px-4 py-2 border"
            placeholder=" عنوان الحدث بالانجليزي"
            :class="
              $v.form.title_en.$error ? 'border-red-500' : 'border-gray-300'
            "
          />
          <p
            v-if="$v.form.title_en.$error"
            class="text-red-500 text-sm font-medium tracking-wide"
          >
            يجب كتابة عنوان الحدث باللغة الانجليزية
          </p>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 md:gap-12">
        <div class="w-full mb-5">
          <textarea
            name="description_ar"
            id="description_ar"
            v-model="form.description_ar"
            @blur="$v.form.description_ar.$touch()"
            class="w-full rounded-lg px-4 py-2 border"
            placeholder="وصف الحدق باللغة العربية"
            :class="
              $v.form.description_ar.$error
                ? 'border-red-500'
                : 'border-gray-300'
            "
          ></textarea>
          <p
            v-if="$v.form.description_ar.$error"
            class="text-red-500 text-sm font-medium tracking-wide"
          >
            يجب كتابة محتوى الحدث التي تصنف بها الخبر باللغة العربية
          </p>
        </div>
        <div class="w-full mb-5">
          <textarea
            name="description_en"
            id="description_en"
            v-model="form.description_en"
            @blur="$v.form.description_en.$touch()"
            class="w-full rounded-lg px-4 py-2 border"
            placeholder="وصف الحدث باللغة الانجليزية"
            :class="
              $v.form.description_en.$error
                ? 'border-red-500'
                : 'border-gray-300'
            "
          ></textarea>
          <p
            v-if="$v.form.description_en.$error"
            class="text-red-500 text-sm font-medium tracking-wide"
          >
            يجب كتابة محتوى الحدث التي تصنف بها الخبر باللغة الانجليزية
          </p>
        </div>
      </div>

      <div class="w-full md:w-1/2 mb-5">
        <input
          id="event_date"
          name="event_date"
          v-model="form.event_date"
          class="w-full rounded-lg px-4 py-2 border"
          placeholder="تاريخ الحدث"
          @blur="$v.form.event_date.$touch()"
          :class="
            $v.form.event_date.$error ? ' border-red-700' : 'border-inputBorder'
          "
          type="date"
        />
        <p
          v-if="$v.form.event_date.$error"
          class="text-red-500 text-sm font-medium tracking-wide"
        >
          يجب وضع التاريخ الخاص بالحدث
        </p>
      </div>

      <p
        v-if="errorMsg"
        class="
          my-5
          bg-redd-100
          text-red-600
          border-red-600
          py-2
          font-medium
          tracking-wide
          leading-relaxed
          px-5
          w-full
          border
          rounded-md
        "
      >
        {{ errorMsg }}
      </p>
      <button
        @click.prevent="submit"
        class="
          bg-primary
          px-8
          py-2
          focus:outline-none
          text-white
          flex
          items-center
          text-sm
          my-3
          rounded-md
        "
      >
        <span>تأكيد</span>
        <i v-if="loading" class="mx-2 text-xl bx bx-loader-circle bx-spin"></i>
        <i v-else-if="success" class="mx-2 text-xl bx bx-check-double"></i>
        <i
          v-else
          class="mx-2 bx bx-send transition-all transform rotate-180"
        ></i>
      </button>
    </form>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "addEvent",
  data() {
    return {
      form: {
        title_ar: "",
        title_en: "",
        description_ar: "",
        description_en: "",
        event_date: "",
      },
      toggle: null,
      success: false,
      errorMsg: "",
      loading: false,
    };
  },
  validations: {
    form: {
      title_ar: { required },
      title_en: { required },
      description_ar: { required },
      description_en: { required },
      event_date: { required },
    },
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error || this.$v.form.$pending) {
        this.errorMsg = "يجب تعبئة جمعيع الحقول قبل التأكيد";
        return;
      }
      this.loading = true;
      let form = {
        ...this.form,
        event_date: this.$moment(this.form.event_date).format("YYYY-MM-DD"),
      };
      this.$store
        .dispatch("addEvent", form)
        .then(() => {
          this.loading = false;
          this.success = true;
          this.form = {
            title_ar: "",
            title_en: "",
            description_ar: "",
            description_en: "",
            event_date: "",
          };
          this.$store.commit("toggleSuccessMsg", "تم إضافة الحدث بنجاح");
          this.$v.form.$reset();
          setTimeout(() => {
            this.success = false;
          }, 6000);
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>