<template>
  <div
    class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-12"
  >
    <div v-for="(post, index) in posts" :key="index" class="my-2">
      <img
        v-if="post.photo.length > 0"
        :src="'https://' + post.photo[0].path"
        alt=""
        class="w-full h-44 border border-secondry object-contain"
      />
      <div class="flex items-center text-gray-500 my-2">
        <span>{{ post.category_ar }} - </span>
        <span class="mx-1">
          {{ $moment(post.created_at).format("YYYY - MM - DD") }}
        </span>
      </div>
      <h1
        id="newsCard"
        class="newsCard text-gray-900 font-medium line-clamp-title"
      >
        {{ post.title_ar }}
      </h1>
      <router-link
        :to="{ name: 'NewsDetail', params: { id: post.id } }"
        class="text-btn mt-3 flex items-center focus:outline-none"
      >
        <span class="mx-2">عرض المزيد</span>
        <i class="text-btn bx bxs-chevron-left"></i>
      </router-link>
    </div>
  </div>
</template>
<script>
import lineClamp from "line-clamp";

export default {
  name: "posts",
  props: ["posts"],
  data() {
    return {
      URL: process.env.VUE_APP_URL,
    };
  },
  mounted() {
    const element = document.querySelector(".line-clamp-title");
    lineClamp(element, 2);
  },
};
</script>
<style scoped>
.line-clamp-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
