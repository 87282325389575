export default [
  { value: "Afghan", name: "أفغاني" },
  { value: "Albanian", name: "ألباني" },
  { value: "Algerian", name: "جزائري" },
  { value: "American", name: "أمريكي" },
  { value: "Andorran", name: "أندوري" },
  { value: "Angolan", name: "أنغولي" },
  { value: "Antiguans", name: "انتيغوا" },
  { value: "Argentinean", name: "أرجنتيني" },
  { value: "Armenian", name: "أرميني" },
  { value: "Australian", name: "أسترالي" },
  { value: "Austrian", name: "نمساوي" },
  { value: "Azerbaijani", name: "أذربيجاني" },
  { value: "Bahamian", name: "باهامى" },
  { value: "Bahraini", name: "بحريني" },
  { value: "Bangladeshi", name: "بنجلاديشي" },
  { value: "Barbadian", name: "باربادوسي" },
  { value: "Barbudans", name: "بربودا" },
  { value: "Batswana", name: "بوتسواني" },
  { value: "Belarusian", name: "بيلاروسي" },
  { value: "Belgian", name: "بلجيكي" },
  { value: "Belizean", name: "بليزي" },
  { value: "Beninese", name: "بنيني" },
  { value: "Bhutanese", name: "بوتاني" },
  { value: "Bolivian", name: "بوليفي" },
  { value: "Bosnian", name: "بوسني" },
  { value: "Brazilian", name: "برازيلي" },
  { value: "British", name: "بريطاني" },
  { value: "Bruneian", name: "بروناى" },
  { value: "Bulgarian", name: "بلغاري" },
  { value: "Burkinabe", name: "بوركيني" },
  { value: "Burmese", name: "بورمي" },
  { value: "Burundian", name: "بوروندي" },
  { value: "Cambodian", name: "كمبودي" },
  { value: "Cameroonian", name: "كاميروني" },
  { value: "Canadian", name: "كندي" },
  { value: "Cape Verdean", name: "االرأس الأخضر" },
  { value: "Central African", name: "وسط أفريقيا" },
  { value: "Chadian", name: "تشادي" },
  { value: "Chilean", name: "شيلي" },
  { value: "Chinese", name: "صينى" },
  { value: "Colombian", name: "كولومبي" },
  { value: "Comoran", name: "جزر القمر" },
  { value: "Congolese", name: "  كونغولي" },
  { value: "Costa Rican", name: "كوستاريكي" },
  { value: "Croatian", name: "كرواتية" },
  { value: "Cuban", name: "كوبي" },
  { value: "Cypriot", name: "قبرصي" },
  { value: "Czech", name: "تشيكي" },
  { value: "Danish", name: "دانماركي" },
  { value: "Djibouti", name: "جيبوتي" },
  { value: "Dominican", name: "دومينيكاني" },
  { value: "Dutch", name: "هولندي" },
  { value: "East Timorese", name: "تيموري شرقي" },
  { value: "Ecuadorean", name: "اكوادوري" },
  { value: "Egyptian", name: "مصري" },
  { value: "Emirian", name: "إماراتي" },
  { value: "Equatorial Guinean", name: "غيني  استوائي" },
  { value: "Eritrean", name: "إريتري" },
  { value: "Estonian", name: "إستوني" },
  { value: "Ethiopian", name: "حبشي" },
  { value: "Fijian", name: "فيجي" },
  { value: "Filipino", name: "فلبيني" },
  { value: "Finnish", name: "فنلندي" },
  { value: "French", name: "فرنسي" },
  { value: "Gabonese", name: "جابوني" },
  { value: "Gambian", name: "غامبيي" },
  { value: "Georgian", name: "جورجي" },
  { value: "German", name: "ألماني" },
  { value: "Ghanaian", name: "غاني" },
  { value: "Greek", name: "إغريقي" },
  { value: "Grenadian", name: "جرينادي" },
  { value: "Guatemalan", name: "غواتيمالي" },
  { value: "Guinea-Bissauan", name: "غيني بيساوي" },
  { value: "Guinean", name: "غيني" },
  { value: "Guyanese", name: "جوياني" },
  { value: "Haitian", name: "هايتي" },
  { value: "Herzegovinian", name: "هرسكي" },
  { value: "Honduran", name: "هندوراسي" },
  { value: "Hungarian", name: "هنغاري" },
  { value: "Icelander", name: "إيسلندي" },
  { value: "Indian", name: "هندي" },
  { value: "Indonesian", name: "إندونيسي" },
  { value: "Iranian", name: "إيراني" },
  { value: "Iraqi", name: "عراقي" },
  { value: "Irish", name: "إيرلندي" },
  { value: "Italian", name: "إيطالي" },
  { value: "Ivorian", name: "إفواري" },
  { value: "Jamaican", name: "جامايكي" },
  { value: "Japanese", name: "ياباني" },
  { value: "Jordanian", name: "أردني" },
  { value: "Kazakhstani", name: "  كازاخستاني" },
  { value: "Kenyan", name: "كيني" },
  { value: "Kittian and Nevisian", name: "كيتياني ونيفيسياني" },
  { value: "Kuwaiti", name: "كويتي" },
  { value: "Kyrgyz", name: "قيرغيزستان" },
  { value: "Laotian", name: "لاوسي" },
  { value: "Latvian", name: "  لاتفي" },
  { value: "Lebanese", name: "لبناني" },
  { value: "Liberian", name: "ليبيري" },
  { value: "Libyan", name: "ليبي" },
  { value: "Liechtensteiner", name: "ليختنشتايني" },
  { value: "Lithuanian", name: "لتواني" },
  { value: "Luxembourger", name: "لكسمبرغي" },
  { value: "Macedonian", name: "مقدوني" },
  { value: "Malagasy", name: "مدغشقري" },
  { value: "Malawian", name: "مالاوى" },
  { value: "Malaysian", name: "ماليزي" },
  { value: "Maldivan", name: "مالديفي" },
  { value: "Malian", name: "مالي" },
  { value: "Maltese", name: "مالطي" },
  { value: "Marshallese", name: "مارشالي" },
  { value: "Mauritanian", name: "موريتاني" },
  { value: "Mauritian", name: "موريشيوسي" },
  { value: "Mexican", name: "مكسيكي" },
  { value: "Micronesian", name: "ميكرونيزي" },
  { value: "Moldovan", name: "مولدوفي" },
  { value: "Monacan", name: "موناكو" },
  { value: "Mongolian", name: "منغولي" },
  { value: "Moroccan", name: "مغربي" },
  { value: "Mosotho", name: "ليسوتو" },
  { value: "Motswana", name: "لتسواني" },
  { value: "Mozambican", name: "موزمبيقي" },
  { value: "Namibian", name: "ناميبي" },
  { value: "Nauruan", name: "ناورو" },
  { value: "Nepalese", name: "نيبالي" },
  { value: "New Zealander", name: "نيوزيلندي" },
  { value: "Ni-Vanuatu", name: "ني فانواتي" },
  { value: "Nicaraguan", name: "نيكاراغوا" },
  { value: "Nigerien", name: "نيجري" },
  { value: "North Korean", name: "كوري شمالي" },
  { value: "Northern Irish", name: "إيرلندي شمالي" },
  { value: "Norwegian", name: "نرويجي" },
  { value: "Omani", name: "عماني" },
  { value: "Pakistani", name: "باكستاني" },
  { value: "Palauan", name: "بالاوي" },
  { value: "Palestinian", name: "فلسطيني" },
  { value: "Panamanian", name: "بنمي" },
  { value: "Papua New Guinean", name: "بابوا غينيا الجديدة" },
  { value: "Paraguayan", name: "باراغواياني" },
  { value: "Peruvian", name: "بيروفي" },
  { value: "Polish", name: "بولندي" },
  { value: "Portuguese", name: "برتغالي" },
  { value: "Qatari", name: "قطري" },
  { value: "Romanian", name: "روماني" },
  { value: "Russian", name: "روسي" },
  { value: "Rwandan", name: "رواندي" },
  { value: "Saint Lucian", name: "لوسياني" },
  { value: "Salvadoran", name: "سلفادوري" },
  { value: "Samoan", name: "ساموايان" },
  { value: "San Marinese", name: "سان مارينيز" },
  { value: "Sao Tomean", name: "ساو توميان" },
  { value: "Saudi", name: "سعودي" },
  { value: "Scottish", name: "سكتلندي" },
  { value: "Senegalese", name: "سنغالي" },
  { value: "Serbian", name: "صربي" },
  { value: "Seychellois", name: "سيشلي" },
  { value: "Sierra Leonean", name: "سيرا ليوني" },
  { value: "Singaporean", name: "سنغافوري" },
  { value: "Slovakian", name: "سلوفاكي" },
  { value: "Slovenian", name: "سلوفيني" },
  { value: "Solomon Islande", name: "جزر سليمان" },
  { value: "Somali", name: "صومالي" },
  { value: "South African", name: "جنوب افريقيي" },
  { value: "South Korean", name: "كوري جنوبي" },
  { value: "Spanish", name: "إسباني" },
  { value: "Sri Lankan", name: "سري لانكي" },
  { value: "Sudanese", name: "سوداني" },
  { value: "Surinamer", name: "سورينامي" },
  { value: "Swazi", name: "سوازي" },
  { value: "Swedish", name: "سويدي" },
  { value: "Swiss", name: "سويسري" },
  { value: "Syrian", name: "سوري" },
  { value: "Taiwanese", name: "تايواني" },
  { value: "Tajik", name: "طاجيكي" },
  { value: "Tanzanian", name: "تنزاني" },
  { value: "Thai", name: "التايلاندي" },
  { value: "Togolese", name: "توغواني" },
  { value: "Tongan", name: "تونجاني" },
  { value: "Trinidadian or Tobagonian", name: "ترينيدادي أو توباغوني" },
  { value: "Tunisian", name: "تونسي" },
  { value: "Turkish", name: "تركي" },
  { value: "Tuvaluan", name: "توفالي" },
  { value: "Ugandan", name: "أوغندي" },
  { value: "Ukrainian", name: "أوكراني" },
  { value: "Uruguayan", name: "أوروجواي" },
  { value: "Uzbekistani", name: "أوزبكستاني" },
  { value: "Venezuelan", name: "فنزويلي" },
  { value: "Vietnamese", name: "فيتنامي" },
  { value: "Welsh", name: "ويلزي" },
  { value: "Yemenite", name: "يمني" },
  { value: "Zambian", name: "زامبي" },
  { value: "Zimbabwean", name: "زيمبابوي" },
];
