<template>
  <div id="app">
    <router-view />

    <transition name="fade">
      <div
        v-if="successMsg"
        class="
          fixed
          z-50
          inset-0
          flex
          items-start
          justify-center
          text-center
          w-screen
          h-screen
        "
        style="background: rgba(0, 0, 0, 0.3)"
      >
        <SuccessMsg v-if="successMsg" />
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="errorMsg"
        class="
          fixed
          z-50
          inset-0
          flex
          items-start
          justify-center
          text-center
          w-screen
          h-screen
        "
        style="background: rgba(0, 0, 0, 0.3)"
      >
        <errorAler v-if="errorMsg" />
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="sendEmailNotification"
        class="
          fixed
          z-50
          inset-0
          flex
          items-center
          justify-center
          text-center
          w-screen
          h-screen
        "
        style="background: rgba(0, 0, 0, 0.3)"
      >
        <formNotification v-if="sendEmailNotification" />
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="toggleResetPassword"
        class="
          fixed
          z-50
          inset-0
          flex
          items-center
          justify-center
          text-center
          w-screen
          h-screen
        "
        style="background: rgba(0, 0, 0, 0.3)"
      >
        <ResetPassword v-if="toggleResetPassword" />
      </div>
    </transition>
  </div>
</template>
<script>
import SuccessMsg from "./components/layout/SuccessMsg";
import errorAler from "./components/layout/errorAler";
import formNotification from "./components/layout/sendEmailNotification";
import ResetPassword from "./components/layout/ResetPassword";
export default {
  name: "syndicate-of-doctors",
  data() {
    return {};
  },
  components: {
    SuccessMsg,
    errorAler,
    formNotification,
    ResetPassword,
  },
  computed: {
    successMsg() {
      return this.$store.getters.successMsg;
    },
    errorMsg() {
      return this.$store.getters.errorMsg;
    },
    sendEmailNotification() {
      return this.$store.getters.sendEmailNotification;
    },
    toggleResetPassword() {
      return this.$store.getters.toggleResetPassword;
    },
  },
};
</script>

