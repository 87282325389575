const printCompany = (printData, admin_data) => {
        return `
<!DOCTYPE html>
<html style="width: 100%; height:100%; display: flex; flex-direction: column; justify-content: center; ">
<head>
  <meta charset="utf-8">
  <meta name="language" content="arabic" />
  <style>    
        body {
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
        }
        * {
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            text-align:right;
        }
        .page {
          padding: 45mm 10mm 22mm;
            width: 100%;
            height: 100%;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          }
      
        @page {
          size: A4;
          margin: 0;
        } 
      </style>
</head>
<body  dir="rtl">
<div class="page">
<div  style="width:100%">
<div style="display:flex; flex-direction: row; align-items: center; justify-content:space-between; width: 100%">
  <div style="width: 75%; margin-left: 10px">
    <h1 style="text-align: center; font-size:18px; font-weight: bold; margin: 15px 0px" >إذن فتح  ${
      printData.company_name
    } رقم (${printData.practicing_permission_id})</h1>
    <hr>
    <h1 style="font-size:18px; font-weight: bold; margin: 20px 0px">بعد الإطلاع على:</h1>
  </div>
  <div style="border: 1px solid #424242;  height: 150px; width: 25%;">
    
  </div>
</div>

<ul style="margin:20px 0px;">
  <li style="font-weight: 600; font-size: 16px; margin: 3px 0px">النظام الاساسي لنقابة طب المختبرات وتنفيذاً لأحكام القانون رقم (23) لسنة 1998م , بشأن النقابات والإتحادات والروابط المهينة ولائحته التنفيذية.</li>
  <li style="font-weight: 600; font-size: 16px; margin: 3px 0px">وتنفيذاً لأحكام القانون الصحي رقم (106) لسنة 1973م ولائحته التنفيذية . </li>
  <li style="font-weight: 600; font-size: 16px; margin: 3px 0px">وعلى القانون رقم (9) لسنة 1985م ولائحته التنفيذية.</li>
  <li style="font-weight: 600; font-size: 16px; margin: 3px 0px">وعلى قرار امين اللجنة الشعبية العامة للصحة سابقاً رقم (543) لسنة 1993م , بتحديد الأنشطة والاعمال الطبية التي يجوز مزاولتها في العيادات والمؤسسات الطبية والإشتراكية.</li>
  <li style="font-weight: 600; font-size: 16px; margin: 3px 0px">وعلى القانون رقم (6) لسنة 1430م بشأن النظام التشاركي في مجال التعليم والصحة .</li>
  <li style="font-weight: 600; font-size: 16px; margin: 3px 0px">وعلى القانون رقم (23) لسنة 2010 بشأن النشاط التجاري واللوائح المعمول بها .</li>
  <li style="font-weight: 600; font-size: 16px; margin: 3px 0px">وعلى قرار أمين اللجنة الشعبية العامة للصناعة والاقتصاد والتجارة سابقاً رقم (644) لسنة 2010م بشأن اصدار الرخص التجارية.</li>
  <li style="font-weight: 600; font-size: 16px; margin: 3px 0px">وعلى الإطار العام لوصف وترتيب الوظائف لسنة 1986م الصادر عن اللجنة الشعبية العامة للخدمة العاملة سابقاً.</li>
  <li style="font-weight: 600; font-size: 16px; margin: 3px 0px">وعلى القرار (90) لسنة 2016م الصادر عن وزير الصحة المفوض من حكومة الوفاق الوطني, بشأن تسمية مهنة المختبرات من المهن الطبية.</li>
  <li style="font-weight: 600; font-size: 16px; margin: 3px 0px">وبالإطلاع على سجل العضوية لنقابة طب المختبرات وإستيفاء للشروط اللازمة للحصول على الإذن.</li>
</ul>

<h3 style="font-size: 18px; font-weight: bold; margin: 20px 0px"><span style="margin-right:15px"></span>
  بناءً عليه يؤذن للسيد/ة ( ${
    printData.official_representative
  }) والحامل للمؤهل العلمي (${printData.qualification}) المسجل تحت عضوية رقم (${
    printData.member_ship_id
  })
</h3>

<p style="font-size: 18px; font-weight: bold; margin: 20px 0px">يفتح شركة محلية خاصة باستيراد المعدات الطبية ومشغلاتها, وذلك لمدة سنة تبدأ من تاريخ صدوره ويجدد الإذن لنفس المدة بذات الشروط وفي حالة عدم التجديد أو إيقاف العمل به , يترتب عليه إلزاماً دفع رسوم التجديد للسنوات السابقة.</p>
<p style="font-size: 18px; font-weight: bolder; margin: 20px 0px"يستعمل هذا الإن لفتح شركة محلية خاصة باستيراد المعدات الطبية ومشغلاتها , ويلغى في حالة مخالفة للقوانين واللوائح النافذة .</p>

<div style=" width: 100%; margin: 30px 0px; display:flex; justify-content: end; align-items: center  ">

  <img  onload="print()" height="80px" width="80px" style="margin-left: 15px" src="https://api.llms.ly/storage/qrcode/${
    printData.member_ship_id
  }.svg" />
  
  ${
    admin_data
      ? `<div style="margin-left:15px; text-align: center">
      <h1 style="font-weight: bold; font-size: 22px; margin: 5px 0px">
      ${admin_data.status == "super-admin" ? "د." : ""} ${
          admin_data.name_ar
        }</h1>
      <h1 style="font-weight: bold; font-size: 22px; margin: 5px 0px">
         النقيب العام
      </h1>
  </div>`
      : ""
  }
  
    </div>
    
    </div>
    <div style="display:flex; align-items:center; font-weight:bold; justify-content: center;width:100% ">
      <h3 style="text-align: center">صدر هذا الإذن في ${
        admin_data.status == "super-admin" ? "طرابلس" : printData.branch.name_ar
      } تاريخ ${printData.activation_date}</h3>
      <h3 >تاريخ الانتهاء ${printData.endDate}</h3>
    </div>

  

  </div>    
  </body>
</html>
`;
};

export default printCompany;