import axios from "@/axios";

const getData = {
  state: () => ({
    branches: null,
    labs: null,
    companies: null,
    doctors: null,
    employees: null,
    doctorDetails: null,
    LabDetails: null,
    companyDetail: null,
    requestsCount: null,
    RenewalRequestsCount: null,
    finishedMemberShipCount: null,
    news: null,
    events: null,
    Structure: [],
  }),
  mutations: {
    getBranches: (state, payload) => {
      state.branches = payload;
    },
    getAllLabs: (state, payload) => {
      state.labs = payload;
    },
    getLabDetails: (state, payload) => {
      state.LabDetails = payload;
    },
    getAllCompanies: (state, payload) => {
      state.companies = payload;
    },
    geCompanyDetails: (state, payload) => {
      state.companyDetail = payload;
    },
    getDoctors: (state, payload) => {
      state.doctors = payload;
    },
    doctorDetails: (state, payload) => {
      state.doctorDetails = payload.data;
    },
    getEmployees: (state, payload) => {
      state.employees = payload;
    },
    requestsCount: (state, payload) => {
      state.requestsCount = payload;
    },
    RenewalRequestsCount: (state, payload) => {
      state.RenewalRequestsCount = payload;
    },
    finishedMemberShipCount: (state, payload) => {
      state.finishedMemberShipCount = payload;
    },
    news: (state, payload) => {
      state.news = payload;
    },
    addPostToNews: (state, payload) => {
      state.news.push(payload);
    },
    Events: (state, payload) => {
      state.events = payload;
    },
    AddEvents: (state, payload) => {
      state.events.push(payload);
    },
    fetchStructureData: (state, payload) => {
      state.Structure = payload.data;
    },
    addEmployeeToStructure: (state, payload) => {
      state.Structure.push(payload.data);
    },
    editEmployeeToStructure: (state, payload) => {
      state.Structure = state.Structure.map((employee) => {
        return employee.id == payload.employee.id
          ? (employee = payload.employee)
          : employee;
      });
    },
    deleteEmployeeFromStructure: (state, payload) => {
      state.Structure = state.Structure.filter(
        (employee) => employee.id != payload
      );
    },
  },
  actions: {
    getBranches({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/branches")
          .then((Response) => {
            if (Response.status == 200) {
              commit("getBranches", Response.data.data);
              resolve(Response.data.data);
            }
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    addPostToNews({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/news", payload)
          .then(({ data }) => {
            resolve(data.news);
            commit("addPostToNews", data.news);
          })
          .catch((err) => reject(err.response));
      });
    },
    addEvent({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/events", payload)
          .then(({ data }) => {
            resolve(data);
            commit("AddEvents", data);
          })
          .catch((err) => reject(err.response));
      });
    },
    fetchEvents({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/events")
          .then(({ data }) => {
            commit("Events", data.data);
            resolve(data.events);
          })
          .catch((err) => reject(err.response));
      });
    },
    fetchNews({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/news")
          .then(({ data }) => {
            commit("news", data.data);
            resolve(data.news);
          })
          .catch((err) => reject(err.response));
      });
    },
    NewsDetails(_, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/news/${payload}`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch((err) => reject(err.response));
      });
    },
    editNewsPost(_, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/news/${payload.id}`, payload.form)
          .then(({ data }) => {
            resolve(data.news);
          })
          .catch((err) => reject(err.response));
      });
    },
    DeletePostFromNews(_, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/news/${payload}`, { _method: "delete" })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => reject(err.response));
      });
    },
    getAllLabs({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/labs?page=${payload.page}&sort=${payload.sort}&search=${payload.search}&branch_id=${payload.branch_id}`
          )
          .then((Response) => {
            if (Response.status == 200) {
              commit("getAllLabs", Response.data);
              resolve(Response.data);
            }
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    getlabDetails({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/labs/${id}`)
          .then((res) => {
            resolve(res);
            commit("getLabDetails", res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    LabsRequests({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/membership/labsRequests?page=${payload.page}&sort=${payload.sort}&search=${payload.search}&branch_id=${payload.branch_id}`
          )
          .then((Response) => {
            if (Response.status == 200) {
              commit("getAllLabs", Response.data);
              resolve(Response.data);
            }
          })
          .catch((error) => reject(error));
      });
    },
    renewalLabssRequests({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/membership/renewalLabsRequests?page=${payload.page}&sort=${payload.sort}&search=${payload.search}&branch_id=${payload.branch_id}`
          )
          .then((Response) => {
            if (Response.status == 200) {
              commit("getAllLabs", Response.data);
              resolve(Response.data);
            }
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    finishedLabsMemberShip({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/membership/finishedLabsMemberShip?page=${payload.page}&sort=${payload.sort}&search=${payload.search}&branch_id=${payload.branch_id}`
          )
          .then((Response) => {
            if (Response.status == 200) {
              commit("getAllLabs", Response.data);
              resolve(Response.data);
            }
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    getAllCompanies({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/companies?page=${payload.page}&sort=${payload.sort}&search=${payload.search}&branch_id=${payload.branch_id}`
          )
          .then((Response) => {
            if (Response.status == 200) {
              commit("getAllCompanies", Response.data);
              resolve(Response.data);
            }
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    geCompanyDetails({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/companies/${id}`)
          .then(({ data }) => {
            resolve(data);
            commit("geCompanyDetails", data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    CompaniesRequests({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/membership/companiesRequests?page=${payload.page}&sort=${payload.sort}&search=${payload.search}&branch_id=${payload.branch_id}`
          )
          .then((Response) => {
            if (Response.status == 200) {
              commit("getAllCompanies", Response.data);
              resolve(Response.data);
            }
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    renewalCompaniessRequests({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/membership/renewalCompaniesRequests?page=${payload.page}&sort=${payload.sort}&search=${payload.search}&branch_id=${payload.branch_id}`
          )
          .then((Response) => {
            if (Response.status == 200) {
              commit("getAllCompanies", Response.data);
              resolve(Response.data);
            }
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    finishedCompaniesMemberShip({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/membership/finishedCompaniesMemberShip?page=${payload.page}&sort=${payload.sort}&search=${payload.search}&branch_id=${payload.branch_id}`
          )
          .then((Response) => {
            if (Response.status == 200) {
              commit("getAllCompanies", Response.data);
              resolve(Response.data);
            }
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    getAllDoctors({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/doctors?page=${payload.page}&sort=${payload.sort}&search=${payload.search}&branch_id=${payload.branch_id}`
          )
          .then((Response) => {
            if (Response.status == 200) {
              commit("getDoctors", Response.data);
              resolve(Response.data);
            }
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    getDoctorDetails({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/doctors/${id}`)
          .then(({ data }) => {
            resolve(data);
            commit("doctorDetails", data);
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    doctorRequests({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/membership/doctorRequests?page=${payload.page}&sort=${payload.sort}&search=${payload.search}&branch_id=${payload.branch_id}`
          )
          .then((Response) => {
            if (Response.status == 200) {
              resolve(Response.data);
              commit("getDoctors", Response.data);
            }
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    renewalDoctorsRequests({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/membership/renewalDoctorsRequests?page=${payload.page}&sort=${payload.sort}&search=${payload.search}&branch_id=${payload.branch_id}`
          )
          .then((Response) => {
            if (Response.status == 200) {
              commit("getDoctors", Response.data);
              resolve(Response.data);
            }
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    finishedDoctorsMemberShip({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/membership/finishedDoctorsMemberShip?page=${payload.page}&sort=${payload.sort}&search=${payload.search}&branch_id=${payload.branch_id}`
          )
          .then((Response) => {
            if (Response.status == 200) {
              commit("getDoctors", Response.data);
              resolve(Response.data);
            }
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    getAllemployees({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/users?page=${payload.page}&sort=${payload.sort}&search=${payload.search}&branch_id=${payload.branch_id}`
          )
          .then(({ data }) => {
            commit("getEmployees", data.users);
            resolve(data.users);
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    DeleteEmployee(_, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/users/${payload}`, { _method: "delete" })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    requestsCount({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/membership/requestsCount")
          .then((Response) => {
            if (Response.status == 200) {
              commit("requestsCount", Response.data);
            }
            resolve(Response.data);
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    RenewalRequestsCount({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/membership/RenewalRequestsCount")
          .then((Response) => {
            if (Response.status == 200) {
              commit("RenewalRequestsCount", Response.data);
            }
            resolve(Response.data);
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    finishedMemberShipCount({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/membership/finishedMemberShipCount")
          .then((Response) => {
            if (Response.status == 200) {
              commit("finishedMemberShipCount", Response.data);
            }
            resolve(Response.data);
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    fetchStructureData({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/structure")
          .then(({ data }) => {
            resolve(data);
            commit("fetchStructureData", data);
          })
          .catch((err) => reject(err.response));
      });
    },
    addEmployeeToStructure({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/structure", payload)
          .then(({ data }) => {
            commit("addEmployeeToStructure", data);
            resolve(data);
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    editEmployeeToStructure({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/structure/${payload.id}`, { ...payload, _method: "put" })
          .then(({ data }) => {
            commit("editEmployeeToStructure", data);
            resolve(data);
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    deleteEmployeeFromStructure({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/structure/${id}`, { _method: "delete" })
          .then(({ data }) => {
            commit("deleteEmployeeFromStructure", id);
            resolve(data);
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
  },
  getters: {
    getBranches(state) {
      return state.branches;
    },

    getAllDoctors(state) {
      return state.doctors;
    },
    getAllCompanies(state) {
      return state.companies;
    },
    getAllLabs(state) {
      return state.labs;
    },
    getEmployees(state) {
      return state.employees;
    },
    requestsCount(state) {
      return state.requestsCount;
    },
    RenewalRequestsCount(state) {
      return state.RenewalRequestsCount;
    },
    finishedMemberShipCount(state) {
      return state.finishedMemberShipCount;
    },
    news(state) {
      return state.news;
    },
    Structure(state) {
      return state.Structure;
    },
    events(state) {
      return state.events;
    },
  },
};

export default getData;
