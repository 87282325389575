<template>
  <div>
    <div v-if="!loading" class="w-full h-full flex flex-wrap my-5">
      <div
        class="
          flex
          items-center
          justify-between
          container
          mx-auto
          py-4
          px-8
          border-b
          mb-10
        "
      >
        <h1 class="text-3xl text-h1Text">تفاصيل الباحث</h1>
        <button
          class="bg-primary px-4 py-2 text-lg rounded-md text-white"
          @click="editDoctor(doctorDetail)"
        >
          <span class="mx-2">تعديل</span>
          <i class="bx bx-message-square-edit"></i>
        </button>
      </div>
      <div
        v-if="doctorDetail"
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-xl font-bold">
          الحالة
        </span>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">الحالة:</label>
          <p
            :class="
              doctorDetail.status == 'نشط'
                ? 'bg-activeBG text-activeText'
                : doctorDetail.status == 'طلب عضوية'
                ? 'bg-unactiveBG text-unactiveText'
                : doctorDetail.status == 'حذف'
                ? 'bg-deletedBG text-deletedText'
                : doctorDetail.status == 'حظر'
                ? 'bg-blockedBG text-blockedText'
                : doctorDetail.status == 'غير نشط'
                ? 'bg-unactiveBG text-unactiveText'
                : null
            "
            class="
              font-medium
              text-xl text-h1Text
              mx-2
              px-3
              py-1
              rounded-lg
              flex
              items-center
            "
          >
            <i
              class="bx mx-2 text-xl"
              :class="
                doctorDetail.status == 'حذف'
                  ? 'bx-trash text-deletedBtn'
                  : doctorDetail.status == 'حظر'
                  ? 'bx-block text-blockedBtn'
                  : doctorDetail.status == 'غير نشط' || 'طلب عضوية'
                  ? 'bx-toggle-left text-unactiveBtn'
                  : doctorDetail.status == 'نشط'
                  ? 'bx-donate-heart text-activeBtn'
                  : null
              "
            ></i>
            <span class="mx-2">{{ doctorDetail.status }}</span>
          </p>
        </div>
        <div class="p-5 flex items-center justify-between w-full md:w-1/2">
          <label for="status" class="font-medium text-lg text-right block"
            >تغيير الحالة الى:</label
          >
          <v-select
            dir="rtl"
            :options="['حذف', 'حظر', 'نشط']"
            id="status"
            :clearable="false"
            name="status"
            v-model="status"
            class="
              p-0
              border-inputBorder
              bg-white
              rounded-md
              border
              w-3/5
              text-right
              focus-within:border-primary
              hover:border-primary
              focus:border-primary focus:outline-none focus:shadow-md
              my-1
            "
            :value="doctorDetail.status"
          ></v-select>
        </div>
      </div>
      <div
        v-if="doctorDetail"
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-xl font-bold">
          المعلومات الشخصية
        </span>
        <div class="w-full m-5">
          <div
            v-if="doctorDetail.photos.doctor_image.length > 0"
            class="relative h-48 w-40"
          >
            <img
              :src="'https://' + doctorDetail.photos.doctor_image[0].path"
              alt=""
              loading="lazy"
              class="
                border-4
                outline-none
                border-gray-500
                focus:outline-none
                border-dashed
                rounded-lg
                lg:rounded-md
                w-full
                h-full
                object-cover
              "
            />
          </div>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">فرع النقابة:</label>
          <p
            v-if="doctorDetail.branches"
            class="font-medium text-xl text-h1Text mx-2"
          >
            {{ doctorDetail.branches.name_ar }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">الاسم رُباعي:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ doctorDetail.name }}
          </p>
        </div>
        <div class="p-5 flex items-center w-1/2 md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">رقم الهاتف:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ doctorDetail.phone_number }}
          </p>
        </div>
        <div
          v-if="doctorDetail.email"
          class="p-5 flex items-center w-full md:w-1/2 flex-wrap"
        >
          <label class="font-medium text-xl text-gray-700"
            >البريد الالكتروني:</label
          >
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ doctorDetail.email }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">مكان السكن:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ doctorDetail.address }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">الجنسية:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ doctorDetail.nationality }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >تاريخ الميلاد:</label
          >
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ doctorDetail.birth_date }}
          </p>
        </div>
        <div
          v-if="doctorDetail.id_number"
          class="p-5 flex items-center w-full md:w-1/2 flex-wrap"
        >
          <label class="font-medium text-xl text-gray-700">الرقم الوطني:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ doctorDetail.id_number }}
          </p>
        </div>
        <div
          v-if="doctorDetail.type_of_identity"
          class="p-5 flex items-center w-full md:w-1/2 flex-wrap"
        >
          <label class="font-medium text-xl text-gray-700"
            >{{ doctorDetail.type_of_identity }}:</label
          >
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ doctorDetail.identity_number }}
          </p>
        </div>
        <div
          v-if="doctorDetail.photos.passport_copy.length > 0"
          class="p-5 flex items-center w-full md:w-1/2 flex-wrap"
        >
          <label class="font-medium text-xl text-gray-700">
            صورة من جواز السفر:</label
          >
          <a
            :href="'https://' + doctorDetail.photos.passport_copy[0].path"
            download
            target="_blank"
            class="
              font-medium
              text-xl text-white
              bg-green-600
              px-4
              py-1
              rounded-lg
              mx-2
            "
          >
            تحميل
          </a>
        </div>
        <div
          v-if="doctorDetail.photos.residency_photo.length > 0"
          class="p-5 flex items-center w-full md:w-1/2 flex-wrap"
        >
          <label class="font-medium text-xl text-gray-700">
            صورة من الاقامة:</label
          >
          <a
            :href="'https://' + doctorDetail.photos.residency_photo[0].path"
            download
            target="_blank"
            class="
              font-medium
              text-xl text-white
              bg-green-600
              px-4
              py-1
              rounded-lg
              mx-2
            "
          >
            تحميل
          </a>
        </div>
      </div>
      <div
        class="
          relative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-xl font-bold">
          المؤهل العلمي
        </span>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >المؤهل العلمي:</label
          >
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ doctorDetail.qualification }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">التخصص:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ doctorDetail.specialization }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">الجامعة:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ doctorDetail.name_of_the_university }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">سنة التخرج:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ doctorDetail.year_of_graduation }}
          </p>
        </div>
      </div>
      <div
        class="
          relative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-xl font-bold">
          الخبرة العملية
        </span>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >مكان العمل الحالي:</label
          >
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ doctorDetail.current_place_of_work }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >تاريخ التعيين بالقطاع:</label
          >
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ doctorDetail.date_of_hiring }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >تاريخ الانضمام الى النقابة:</label
          >
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ doctorDetail.date_of_joining }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">نوع القطاع:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ doctorDetail.sector == "public" ? "عام" : "خاص" }}
          </p>
        </div>
      </div>

      <div
        class="
          relative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-xl font-bold">
          طباعة
        </span>
        <div class="py-3 w-full sm:w-1/2 lg:w-2/6 my-5 mx-auto rounded-lg">
          <button
            @click="printReport"
            class="
              border-2 border-primary
              rounded-lg
              bg-primary
              text-lg text-white
              py-2
              font-bold
              w-full
              flex
              justify-center
              items-center
            "
          >
            <span> طباعة اذن مزاولة الباحث {{ doctorDetail.name }} </span>
            <i class="bx bx-printer mx-2 text-2xl"></i>
          </button>
        </div>
      </div>
      <div v-if="errMsg">
        <alert :msg="errMsg" />
      </div>
    </div>
    <div v-else class="flex justify-center py-5">
      <i class="text-4xl mx-auto text-primary bx bx-loader-circle bx-spin"></i>
    </div>
  </div>
</template>
<script>
import alert from "../../layout/alert";
import printDoc from "../printDoc/doctor";

export default {
  name: "DoctorDetail",
  data() {
    return {
      loading: false,
      doctorDetail: null,
      status: null,
      errMsg: null,
      url: process.env.VUE_APP_URL,
    };
  },
  watch: {
    status() {
      this.ChangeDoctorStatus();
    },
  },
  created() {
    this.getNewData();
  },
  computed: {
    branches() {
      return this.$store.getters.getBranches;
    },

    userData() {
      return this.$store.getters.userData;
    },
  },
  methods: {
    getNewData() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("getDoctorDetails", this.$route.params.doc)
          .then(({ data }) => {
            this.doctorDetail = data;
            resolve(data);
            this.loading = false;
          })
          .catch((err) => {
            reject(err);
            this.loading = false;
          });
      });
    },
    editDoctor(doctorDetail) {
      this.$store.commit("editDoctor", doctorDetail);
      this.$router.push({ name: "indivualGeneral" });
    },
    ChangeDoctorStatus() {
      this.loading = true;
      this.$store
        .dispatch("DoctorStatus", {
          status: this.status,
          _id: this.doctorDetail.id,
        })
        .then(() => {
          this.getNewData();
        });
    },
    edit() {
      this.$store.commit("editDoctor", this.doctorDetail);
      this.$router.push({ name: "indivualGeneral" });
    },
    printReport() {
      if (this.doctorDetail.status !== "نشط") {
        this.errMsg = "يجب تنشيط هذا الطبيب لطباعة إذن المزاولة الخاص به";
        setTimeout(() => {
          this.errMsg = null;
        }, 7000);
      } else {
        let tab = printDoc(this.doctorDetail, this.userData);
        const WinPrint = window.open(
          "",
          "",
          "right=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        );
        WinPrint.document.write(tab);
      }
    },
  },
  components: {
    alert,
  },
};
</script>