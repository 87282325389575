<template>
  <div class="bg-bgcolor w-full z-50 flex items-center justify-center h-screen">
    <div
      class="
        md:rounded-lg
        border border-formBorderColor
        overflow-hidden
        shadow-2xl
        h-full
        md:h-auto
        w-full
        md:w-1/2
        lg:w-1/3
        z-50
      "
    >
      <h1 class="text-h1Text text-center text-3xl my-5">
        اعادة تعيين كلمة المرور
      </h1>

      <form
        @submit.prevent="submit"
        class="w-full mx-auto p-5"
        autocomplete="on"
      >
        <div class="mb-5">
          <div class="flex items-center justify-start">
            <input
              id="password"
              v-model="password"
              @blur="$v.password.$touch()"
              :class="
                $v.password.$error ? ' border-red-700' : 'border-inputBorder'
              "
              class="
                w-full
                p-3
                rounded-md
                border
                shadow
                outline-none
                focus:outline-none
                hover:bg-gray-100
                focus:bg-gray-100 focus:shadow-outline
              "
              required
              :type="passwordtype"
              placeholder="كلمة المرور الجديدة"
            />
            <i
              class="bx text-lg -mr-8 text-gray-500 cursor-pointer"
              :class="passwordtype == 'password' ? 'bx-show-alt' : 'bx-hide'"
              @click="
                passwordtype == 'password'
                  ? (passwordtype = 'text')
                  : (passwordtype = 'password')
              "
            ></i>
            <i
              v-if="$v.password.$error"
              class="bx bx-error text-red-700 text-lg -mr-12"
            ></i>
          </div>
          <p
            v-if="$v.password.$error"
            class="text-red-700 text-xs tracking-widest m-1 font-bold"
          >
            يجب التأكد من كتابة كلمة المرور
          </p>
          <p
            v-if="!$v.password.minLength"
            class="text-red-700 text-xs tracking-widest m-1 font-bold"
          >
            كلمة المرور يجب ان تتكون من 8 ارقام على الاقل
          </p>
        </div>
        <div class="mb-5">
          <div class="flex items-center justify-start">
            <input
              id="repeatPassword"
              v-model="repeatPassword"
              @blur="$v.repeatPassword.$touch()"
              :class="
                $v.repeatPassword.$error
                  ? ' border-red-700'
                  : 'border-inputBorder'
              "
              class="
                w-full
                p-3
                rounded-md
                border
                shadow
                outline-none
                focus:outline-none
                hover:bg-gray-100
                focus:bg-gray-100 focus:shadow-outline
              "
              required
              type="password"
              placeholder="اعد كتابة كلمة المرور الجديدة"
            />
            <i
              v-if="$v.repeatPassword.$error"
              class="bx bx-error text-red-700 text-lg -mr-12"
            ></i>
          </div>
          <p
            v-if="$v.repeatPassword.$error"
            class="text-red-700 text-xs tracking-widest m-1 font-bold"
          >
            كلمة المرور يجب ان تكون مطابقة
          </p>
        </div>

        <button
          @click.prevent="submit"
          class="
            p-3
            my-3
            flex
            items-center
            w-full
            justify-center
            bg-primary
            text-white
            rounded-md
            outline-none
            focus:outline-none focus:shadow-outline
          "
        >
          <span>تأكيد</span
          ><i
            v-if="loading"
            class="text-xl text-white bx bx-loader-circle bx-spin"
          ></i>
        </button>
      </form>
    </div>
  </div>
</template>
<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
export default {
  name: "ResetPassword",
  data() {
    return {
      passwordtype: "password",
      password: null,
      Errors: null,
      repeatPassword: null,
      loading: false,
    };
  },
  validations: {
    password: {
      required,
      minLength: minLength(8),
    },
    repeatPassword: {
      sameAsPassword: sameAs("password"),
    },
  },
  created() {
    if (!this.$route.params.token) {
      this.$router.pussh({ name: "Home" });
    }
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (
        this.$v.password.$pending ||
        this.$v.password.$error ||
        this.$v.repeatPassword.$pending ||
        this.$v.repeatPassword.$error
      )
        return;

      this.loading = true;

      const token = this.$route.params.token;
      const email = this.$route.query.email;

      this.$store
        .dispatch("resetpassword", {
          token,
          email,
          password: this.password,
          password_confirmation: this.repeatPassword,
        })
        .then(() => {
          this.loading = false;
          this.$store.commit("toggleSuccessMsg", "تم تعديل كلمة المرور بنجاح");
        })
        .catch((err) => {
          this.loading = false;
          this.$store.commit("toggleErrorMsg", err);
        });
    },
  },
};
</script>
