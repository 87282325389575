var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex flex-wrap w-full p-5 mx-3 mt-8 border rounded-md  releative lg:w-3/4 lg:mx-auto border-formBorderColor"},[_c('span',{staticClass:"absolute -mt-8 text-lg font-bold bg-white text-primary"},[_vm._v(" نسخة من المستندات المطلوبة ")]),_c('div',{staticClass:"flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"},[_c('label',{staticClass:"flex items-center w-full text-lg font-medium text-right",attrs:{"for":"statement_of_graduate"}},[_vm._v("صورة ضوئية طبق الاصل \"سكانر\" من افادة التخرج معتمدة من المؤسسات التعليمية")]),_c('input',{ref:"statement_of_graduate",staticClass:"hidden",class:_vm.defualtInput,attrs:{"id":"statement_of_graduate","name":"statement_of_graduate","accept":"image/*,.pdf","type":"file","required":""},on:{"change":_vm.statement_of_graduateFunc}}),_c('div',{staticClass:"relative flex items-center w-full"},[_c('button',{staticClass:"absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none",on:{"click":_vm.pickstatement_of_graduateFunc}},[_vm._v(" اختر ملف ")]),_c('input',{staticClass:"pr-20 border-inputBorder",attrs:{"type":"text","placeholder":"لم يتم اختيار ملف"},domProps:{"value":_vm.form.statement_of_graduate
              ? _vm.form.statement_of_graduate.name || _vm.form.statement_of_graduate
              : null},on:{"click":_vm.pickstatement_of_graduateFunc}})])]),_c('div',{staticClass:"flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"},[_c('label',{staticClass:"flex items-center w-full text-lg font-medium text-right",attrs:{"for":"better_from_education_department"}},[_vm._v("رسالة من ادارة التعليم الخاص بوزارة التعليم لخريجي القطاع الخاص (دبلوم عالي - بكالوريس)")]),_c('input',{ref:"better_from_education_department",staticClass:"hidden",class:_vm.defualtInput,attrs:{"id":"better_from_education_department","name":"better_from_education_department","accept":"image/*,.pdf","type":"file","required":""},on:{"change":_vm.better_from_education_departmentFunc}}),_c('div',{staticClass:"relative flex items-center w-full"},[_c('button',{staticClass:"absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none",on:{"click":_vm.pickbetter_from_education_departmentFunc}},[_vm._v(" اختر ملف ")]),_c('input',{staticClass:"pr-20",class:_vm.defualtInput + 'border-inputBorder',attrs:{"type":"text","placeholder":"لم يتم اختيار ملف"},domProps:{"value":_vm.form.better_from_education_department
              ? _vm.form.better_from_education_department.name ||
                _vm.form.better_from_education_department
              : null},on:{"click":_vm.pickbetter_from_education_departmentFunc}})])]),_c('div',{staticClass:"flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"},[_c('label',{staticClass:"w-full text-lg font-medium text-right",attrs:{"for":"better_from_quality_department"}},[_vm._v("رسالة من ادارة الجودة بما يفيد معادلة الشهادة للدارسين خارج ليبيا")]),_c('input',{ref:"better_from_quality_department",staticClass:"hidden",class:_vm.defualtInput,attrs:{"id":"better_from_quality_department","name":"better_from_quality_department","accept":"image/*,.pdf","type":"file","required":""},on:{"change":_vm.better_from_quality_departmentFunc}}),_c('div',{staticClass:"relative flex items-center w-full"},[_c('button',{staticClass:"absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none",on:{"click":_vm.pickbetter_from_quality_departmentFunc}},[_vm._v(" اختر ملف ")]),_c('input',{staticClass:"pr-20",class:_vm.defualtInput + 'border-inputBorder',attrs:{"type":"text","placeholder":"لم يتم اختيار ملف"},domProps:{"value":_vm.form.better_from_quality_department
              ? _vm.form.better_from_quality_department.name ||
                _vm.form.better_from_quality_department
              : null},on:{"click":_vm.pickbetter_from_quality_departmentFunc}})])]),_c('div',{staticClass:"flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"},[_c('label',{staticClass:"flex items-center w-full text-lg font-medium text-right",attrs:{"for":"birth_certificate"}},[_vm._v("شهادة ميلاد مع الرقم الوطني معتمدة")]),_c('input',{ref:"birth_certificate",staticClass:"hidden",class:_vm.defualtInput,attrs:{"id":"birth_certificate","name":"birth_certificate","accept":"image/*,.pdf","type":"file","required":""},on:{"change":_vm.birth_certificateFunc}}),_c('div',{staticClass:"relative flex items-center w-full"},[_c('button',{staticClass:"absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none",on:{"click":_vm.pickbirth_certificateFunc}},[_vm._v(" اختر ملف ")]),_c('input',{staticClass:"pr-20 border-inputBorder",attrs:{"type":"text","placeholder":"لم يتم اختيار ملف"},domProps:{"value":_vm.form.birth_certificate
              ? _vm.form.birth_certificate.name || _vm.form.birth_certificate
              : null},on:{"click":_vm.pickbirth_certificateFunc}})])]),_c('div',{staticClass:"flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"},[_c('label',{staticClass:"flex items-center w-full text-lg font-medium text-right",attrs:{"for":"proof_of_identity"}},[_vm._v(" صورة اثبات الهوية (جواز السفر - البطاقة الشخصية) سارية الصلاحية")]),_c('input',{ref:"proof_of_identity",staticClass:"hidden",class:_vm.defualtInput,attrs:{"id":"proof_of_identity","name":"proof_of_identity","accept":"image/*,.pdf","type":"file","required":""},on:{"change":_vm.proof_of_identityFunc}}),_c('div',{staticClass:"relative flex items-center w-full"},[_c('button',{staticClass:"absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none",on:{"click":_vm.pickproof_of_identityFunc}},[_vm._v(" اختر ملف ")]),_c('input',{staticClass:"pr-20 border-inputBorder",attrs:{"type":"text","placeholder":"لم يتم اختيار ملف"},domProps:{"value":_vm.form.proof_of_identity
              ? _vm.form.proof_of_identity.name || _vm.form.proof_of_identity
              : null},on:{"click":_vm.pickproof_of_identityFunc}})])]),_c('div',{staticClass:"flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"},[_c('label',{staticClass:"flex items-center w-full text-lg font-medium text-right",attrs:{"for":"personal_image"}},[_vm._v("صورة شخصية")]),_c('input',{ref:"personal_image",staticClass:"hidden",attrs:{"id":"personal_image","name":"personal_image","accept":"image/*,.pdf","type":"file","required":""},on:{"change":_vm.personal_imageFunc}}),_c('div',{staticClass:"relative flex items-center w-full"},[_c('button',{staticClass:"absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none",on:{"click":_vm.pickpersonal_imageFunc}},[_vm._v(" اختر ملف ")]),_c('input',{staticClass:"pr-20 border-inputBorder",attrs:{"type":"text","placeholder":"لم يتم اختيار ملف"},domProps:{"value":_vm.form.personal_image
              ? _vm.form.personal_image.name || _vm.form.personal_image
              : null},on:{"click":_vm.pickpersonal_imageFunc}})])]),_c('div',{staticClass:"flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"},[_c('label',{staticClass:"flex items-center w-full text-lg font-medium text-right",attrs:{"for":"image_of_subscription"}},[_vm._v(" صورة من اشتراك النقابة ")]),_c('input',{ref:"image_of_subscription",staticClass:"hidden",class:_vm.defualtInput,attrs:{"id":"image_of_subscription","name":"image_of_subscription","accept":"image/*,.pdf","type":"file","required":""},on:{"change":_vm.image_of_subscriptionFunc}}),_c('div',{staticClass:"relative flex items-center w-full"},[_c('button',{staticClass:"absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none",on:{"click":_vm.pickimage_of_subscriptionFunc}},[_vm._v(" اختر ملف ")]),_c('input',{staticClass:"pr-20",class:_vm.defualtInput + 'border-inputBorder',attrs:{"type":"text","placeholder":"لم يتم اختيار ملف"},domProps:{"value":_vm.form.image_of_subscription
              ? _vm.form.image_of_subscription.name || _vm.form.image_of_subscription
              : null},on:{"click":_vm.pickimage_of_subscriptionFunc}})])]),_c('div',{staticClass:"flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"},[_c('label',{staticClass:"flex items-center w-full text-lg font-medium text-right",attrs:{"for":"copy_of_a_valid_license_to_practice_the_profession"}},[_vm._v(" صورة من اذن مزاولة المهنة سارية الصلاحية ")]),_c('input',{ref:"copy_of_a_valid_license_to_practice_the_profession",staticClass:"hidden",class:_vm.defualtInput,attrs:{"id":"copy_of_a_valid_license_to_practice_the_profession","name":"copy_of_a_valid_license_to_practice_the_profession","accept":"image/*,.pdf","type":"file","required":""},on:{"change":_vm.copy_of_a_valid_license_to_practice_the_professionFunc}}),_c('div',{staticClass:"relative flex items-center w-full"},[_c('button',{staticClass:"absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none",on:{"click":_vm.pickcopy_of_a_valid_license_to_practice_the_professionFunc}},[_vm._v(" اختر ملف ")]),_c('input',{staticClass:"pr-20",class:_vm.defualtInput + 'border-inputBorder',attrs:{"type":"text","placeholder":"لم يتم اختيار ملف"},domProps:{"value":_vm.form.copy_of_a_valid_license_to_practice_the_profession
              ? _vm.form.copy_of_a_valid_license_to_practice_the_profession
                  .name ||
                _vm.form.copy_of_a_valid_license_to_practice_the_profession
              : null},on:{"click":_vm.pickcopy_of_a_valid_license_to_practice_the_professionFunc}})])]),_c('div',{staticClass:"flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"},[_c('label',{staticClass:"flex items-center w-full text-lg font-medium text-right",attrs:{"for":"former_permission_of_company"}},[_vm._v("صورة من اذن فتح الشركة السابق")]),_c('input',{ref:"former_permission_of_company",staticClass:"hidden",attrs:{"id":"former_permission_of_company","name":"former_permission_of_company","accept":"image/*,.pdf","type":"file","required":""},on:{"change":_vm.former_permission_of_companyFunc}}),_c('div',{staticClass:"relative flex items-center w-full"},[_c('button',{staticClass:"absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none",on:{"click":_vm.pickformer_permission_of_companyFunc}},[_vm._v(" اختر ملف ")]),_c('input',{staticClass:"pr-20",class:_vm.defualtInput + 'border-inputBorder',attrs:{"type":"text","placeholder":"لم يتم اختيار ملف"},domProps:{"value":_vm.form.former_permission_of_company
              ? _vm.form.former_permission_of_company.name ||
                _vm.form.former_permission_of_company
              : null},on:{"click":_vm.pickformer_permission_of_companyFunc}})])]),_c('div',{staticClass:"flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"},[_c('label',{staticClass:"flex items-center w-full text-lg font-medium text-right",attrs:{"for":"experience_certificate"}},[_vm._v("خبرة عمل في مجال المختبرات الطبية لا تقل عن 5 سنوات , صورة من شهادة الخبرة")]),_c('input',{ref:"experience_certificate",staticClass:"hidden",attrs:{"id":"experience_certificate","name":"experience_certificate","accept":"image/*,.pdf","type":"file","required":""},on:{"change":_vm.experience_certificateFunc}}),_c('div',{staticClass:"relative flex items-center w-full"},[_c('button',{staticClass:"absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none",on:{"click":_vm.pickexperience_certificateFunc}},[_vm._v(" اختر ملف ")]),_c('input',{staticClass:"pr-20",class:_vm.defualtInput + 'border-inputBorder',attrs:{"type":"text","placeholder":"لم يتم اختيار ملف"},domProps:{"value":_vm.form.experience_certificate
              ? _vm.form.experience_certificate.name ||
                _vm.form.experience_certificate
              : null},on:{"click":_vm.pickexperience_certificateFunc}})])]),_c('div',{staticClass:"flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"},[_c('label',{staticClass:"flex items-center w-full text-lg font-medium text-right",attrs:{"for":"contract_of_establishment_of_company"}},[_vm._v("عقد تأسيس الشركة ")]),_c('input',{ref:"contract_of_establishment_of_company",staticClass:"hidden",attrs:{"id":"contract_of_establishment_of_company","name":"contract_of_establishment_of_company","accept":"image/*,.pdf","type":"file","required":""},on:{"change":_vm.contract_of_establishment_of_companyFunc}}),_c('div',{staticClass:"relative flex items-center w-full"},[_c('button',{staticClass:"absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none",on:{"click":_vm.pickcontract_of_establishment_of_companyFunc}},[_vm._v(" اختر ملف ")]),_c('input',{staticClass:"pr-20",class:_vm.defualtInput + 'border-inputBorder',attrs:{"type":"text","placeholder":"لم يتم اختيار ملف"},domProps:{"value":_vm.form.contract_of_establishment_of_company
              ? _vm.form.contract_of_establishment_of_company.name ||
                _vm.form.contract_of_establishment_of_company
              : null},on:{"click":_vm.pickcontract_of_establishment_of_companyFunc}})])]),_c('div',{staticClass:"flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"},[_c('label',{staticClass:"flex items-center w-full text-lg font-medium text-right",attrs:{"for":"minutes_of_the_meeting"}},[_vm._v(" محضر الاجتماع ")]),_c('input',{ref:"minutes_of_the_meeting",staticClass:"hidden",attrs:{"id":"minutes_of_the_meeting","name":"minutes_of_the_meeting","accept":"image/*,.pdf","type":"file","required":""},on:{"change":_vm.minutes_of_the_meetingFunc}}),_c('div',{staticClass:"relative flex items-center w-full"},[_c('button',{staticClass:"absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none",on:{"click":_vm.pickminutes_of_the_meetingFunc}},[_vm._v(" اختر ملف ")]),_c('input',{staticClass:"pr-20",class:_vm.defualtInput + 'border-inputBorder',attrs:{"type":"text","placeholder":"لم يتم اختيار ملف"},domProps:{"value":_vm.form.minutes_of_the_meeting
              ? _vm.form.minutes_of_the_meeting.name ||
                _vm.form.minutes_of_the_meeting
              : null},on:{"click":_vm.pickminutes_of_the_meetingFunc}})])]),_c('div',{staticClass:"flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"},[_c('label',{staticClass:"flex items-center w-full text-lg font-medium text-right",attrs:{"for":"the_general_assembly"}},[_vm._v(" الجمعية العمومية")]),_c('input',{ref:"the_general_assembly",staticClass:"hidden",attrs:{"id":"the_general_assembly","name":"the_general_assembly","accept":"image/*,.pdf","type":"file","required":""},on:{"change":_vm.the_general_assemblyFunc}}),_c('div',{staticClass:"relative flex items-center w-full"},[_c('button',{staticClass:"absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none",on:{"click":_vm.pickthe_general_assemblyFunc}},[_vm._v(" اختر ملف ")]),_c('input',{staticClass:"pr-20",class:_vm.defualtInput + 'border-inputBorder',attrs:{"type":"text","placeholder":"لم يتم اختيار ملف"},domProps:{"value":_vm.form.the_general_assembly
              ? _vm.form.the_general_assembly.name || _vm.form.the_general_assembly
              : null},on:{"click":_vm.pickthe_general_assemblyFunc}})])]),_c('div',{staticClass:"flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"},[_c('label',{staticClass:"flex items-center w-full text-lg font-medium text-right",attrs:{"for":"commercial_record"}},[_vm._v("السجل التجاري")]),_c('input',{ref:"commercial_record",staticClass:"hidden",attrs:{"id":"commercial_record","name":"commercial_record","accept":"image/*,.pdf","type":"file","required":""},on:{"change":_vm.commercial_recordFunc}}),_c('div',{staticClass:"relative flex items-center w-full"},[_c('button',{staticClass:"absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none",on:{"click":_vm.pickcommercial_recordFunc}},[_vm._v(" اختر ملف ")]),_c('input',{staticClass:"pr-20",class:_vm.defualtInput + 'border-inputBorder',attrs:{"type":"text","placeholder":"لم يتم اختيار ملف"},domProps:{"value":_vm.form.commercial_record
              ? _vm.form.commercial_record.name || _vm.form.commercial_record
              : null},on:{"click":_vm.pickcommercial_recordFunc}})])]),_c('div',{staticClass:"flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"},[_c('label',{staticClass:"flex items-center w-full text-lg font-medium text-right",attrs:{"for":"chamber_of_commerce"}},[_vm._v("الغرفة التجارية")]),_c('input',{ref:"chamber_of_commerce",staticClass:"hidden",attrs:{"id":"chamber_of_commerce","name":"chamber_of_commerce","accept":"image/*,.pdf","type":"file","required":""},on:{"change":_vm.chamber_of_commerceFunc}}),_c('div',{staticClass:"relative flex items-center w-full"},[_c('button',{staticClass:"absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none",on:{"click":_vm.pickchamber_of_commerceFunc}},[_vm._v(" اختر ملف ")]),_c('input',{staticClass:"pr-20",class:_vm.defualtInput + 'border-inputBorder',attrs:{"type":"text","placeholder":"لم يتم اختيار ملف"},domProps:{"value":_vm.form.chamber_of_commerce
              ? _vm.form.chamber_of_commerce.name || _vm.form.chamber_of_commerce
              : null},on:{"click":_vm.pickchamber_of_commerceFunc}})])]),_c('div',{staticClass:"flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"},[_c('label',{staticClass:"w-full text-lg font-medium text-right",attrs:{"for":"former_license"}},[_vm._v("صورة من الترخيص الممنوح سابقا لمزاولة النشاط (ان وجد)")]),_c('input',{ref:"former_license",staticClass:"hidden",attrs:{"id":"former_license","name":"former_license","accept":"image/*,.pdf","type":"file","required":""},on:{"change":_vm.former_licenseFunc}}),_c('div',{staticClass:"relative flex items-center w-full"},[_c('button',{staticClass:"absolute z-20 p-2 text-white  bg-primary rounded-r-md focus:outline-none",on:{"click":_vm.pickformer_licenseFunc}},[_vm._v(" اختر ملف ")]),_c('input',{staticClass:"pr-20",class:_vm.defualtInput + 'border-inputBorder',attrs:{"type":"text","placeholder":"لم يتم اختيار ملف"},domProps:{"value":_vm.form.former_license
              ? _vm.form.former_license.name || _vm.form.former_license
              : null},on:{"click":_vm.pickformer_licenseFunc}})])])]),_c('div',{staticClass:"flex items-center justify-end w-full mx-auto lg:w-3/4"},[(_vm.$v.form.$error)?_c('p',{staticClass:"font-medium text-red-600 text-md"},[_vm._v(" الرجاء تكملة الحقول المطلوبة ")]):_vm._e(),_c('button',{staticClass:"w-auto px-8 py-2 m-3 text-lg font-medium bg-white border rounded-md shadow  focus:outline-none hover:shadow-lg md:m-5 border-primary text-primary",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" رجوع ")]),_c('button',{staticClass:"flex items-center w-auto px-8 py-2 m-3 text-lg font-medium text-white rounded-md shadow  focus:outline-none hover:shadow-lg md:m-5 bg-primary",on:{"click":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('span',[_vm._v("تأكيد ")]),(_vm.loading)?_c('i',{staticClass:"mx-2 text-2xl bx bx-loader-circle bx-spin"}):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }