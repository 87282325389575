<template>
  <div class="py-12 container mx-auto px-5 lg:px-12">
    <div v-if="loading" class="flex justify-center py-5">
      <i class="text-4xl mx-auto text-primary bx bx-loader-circle bx-spin"></i>
    </div>
    <div v-else>
      <div class="flex justify-between items-center py-4 border-b mb-10">
        <h1 class="text-h1Text text-3xl">الأحداث والنشاطات</h1>
        <router-link
          :to="{ name: 'addEvent' }"
          class="
            px-5
            py-2
            focus:outline-none
            hover:shadow-md
            rounded-md
            bg-primary
            text-white
            flex
            items-center
          "
        >
          اضافة<i class="bx bxs-plus-circle mx-1"></i>
        </router-link>
      </div>

      <table
        v-if="events && events.length > 0"
        class="min-w-full divide-y divide-gray-200"
      >
        <thead class="bg-gray-50">
          <tr class="text-center">
            <th
              class="
                px-6
                py-3
                text-xs
                font-medium
                text-gray-500
                uppercase
                tracking-wider
              "
            >
              id
            </th>
            <th
              class="
                px-6
                py-3
                text-xs
                font-medium
                text-gray-500
                uppercase
                tracking-wider
              "
            >
              العنوان
            </th>
            <th
              class="
                px-6
                py-3
                text-xs
                font-medium
                text-gray-500
                uppercase
                tracking-wider
              "
            >
              تاريخ الحدث
            </th>
            <th
              class="
                px-6
                py-3
                text-lg
                font-medium
                text-gray-500
                uppercase
                tracking-wider
              "
            ></th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200 my-5">
          <tr v-for="(event, index) in events" :key="index" class="text-center">
            <td class="px-6 py-4 text-gray-700">{{ event.id }}</td>
            <td class="px-6 py-4 text-gray-700">{{ event.title_ar }}</td>
            <td class="px-6 py-4 text-gray-700">
              <span>{{ event.event_date }}</span>
            </td>
            <td class="px-6 py-4 text-blue-600 text-md">
              <router-link
                :to="{ name: 'EventDetail', params: { id: event.id } }"
              >
                عرض التفاصيل</router-link
              >
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else class="my-8 text-center">لا يوجد بيانات لعرضها</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      success: false,
      errorMsg: "",
      loading: true,
    };
  },

  created() {
    this.$store.dispatch("fetchEvents").finally(() => {
      this.loading = false;
    });
  },
  computed: {
    events() {
      return this.$store.getters.events;
    },
  },
};
</script>