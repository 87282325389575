<template>
  <div id="navigation" class="h-screen bg-h1Text overflow-scroll pb-8">
    <ul class="h-auto text-white mt-5 pb-16">
      <li v-if="userData.status == 'super-admin'" @click="toggleSlide">
        <router-link
          :to="{ name: 'BranchesPanel' }"
          :class="$route.name == 'BranchesPanel' ? 'border-r-4 bg-black' : null"
          class="
            py-4
            flex
            justify-start
            items-center
            cursor-pointer
            hover:bg-black
            active:bg-black
          "
        >
          <i class="bx text-lg bx-git-branch mx-5"></i>
          <span>فروع النقابة</span>
        </router-link>
      </li>
      <li @click="toggleSlide">
        <router-link
          :to="{ name: 'DoctorsPanel', params: { count: 'all' } }"
          :class="$route.name == 'DoctorsPanel' ? 'border-r-4 bg-black' : null"
          class="
            py-4
            flex
            justify-start
            items-center
            cursor-pointer
            hover:bg-black
            active:bg-black
          "
        >
          <i class="bx text-lg bxs-first-aid mx-5"></i>
          <span>الباحثون</span>
        </router-link>
      </li>
      <li @click="toggleSlide">
        <router-link
          :to="{ name: 'LabsPanel', params: { count: 'all' } }"
          :class="$route.name == 'LabsPanel' ? 'border-r-4 bg-black' : null"
          class="
            py-4
            flex
            justify-start
            items-center
            cursor-pointer
            hover:bg-black
            active:bg-black
          "
        >
          <i class="bx text-lg bxs-flask mx-5"></i>
          <span> المختبرات</span>
        </router-link>
      </li>
      <li v-if="userData.status == 'super-admin'" @click="toggleSlide">
        <router-link
          :to="{ name: 'CompaniesPanel', params: { count: 'all' } }"
          :class="
            $route.name == 'CompaniesPanel' ? 'border-r-4 bg-black' : null
          "
          class="
            py-4
            flex
            justify-start
            items-center
            cursor-pointer
            hover:bg-black
            active:bg-black
          "
        >
          <i class="bx text-lg bx-buildings mx-5"></i>
          <span>الشركات</span>
        </router-link>
      </li>

      <li @click="toggleSlide">
        <router-link
          :to="{ name: 'employeesPanel' }"
          :class="
            $route.name == 'employeesPanel' ? 'border-r-4 bg-black' : null
          "
          class="
            py-4
            flex
            justify-start
            items-center
            cursor-pointer
            hover:bg-black
            active:bg-black
          "
        >
          <i class="bx mx-5 text-lg bxs-user-account"></i>
          <span>الموظفين</span>
        </router-link>
      </li>

      <li v-if="userData.status == 'super-admin'" @click="toggleSlide">
        <button
          @click="$store.commit('sendEmailNotification')"
          class="
            py-4
            flex
            justify-start
            items-center
            w-full
            focus:outline-none
            cursor-pointer
            hover:bg-black
            active:bg-black
          "
        >
          <i class="bx text-lg mx-5 bx-message-detail"></i>
          <span>ارسل بريد الكتروني </span>
        </button>
      </li>

      <li v-if="userData.status == 'super-admin'" @click="toggleSlide">
        <router-link
          :to="{ name: 'News' }"
          class="
            py-4
            flex
            justify-start
            items-center
            w-full
            focus:outline-none
            cursor-pointer
            hover:bg-black
            active:bg-black
          "
        >
          <i class="bx bx-news text-lg mx-5"></i>
          <span> اخر الاخبار </span>
        </router-link>
      </li>

      <li v-if="userData.status == 'super-admin'" @click="toggleSlide">
        <router-link
          :to="{ name: 'Events' }"
          class="
            py-4
            flex
            justify-start
            items-center
            w-full
            focus:outline-none
            cursor-pointer
            hover:bg-black
            active:bg-black
          "
        >
          <i class="bx bx-news text-lg mx-5"></i>
          <span> الاحداث والنشاطات </span>
        </router-link>
      </li>

      <li v-if="userData.status == 'super-admin'" @click="toggleSlide">
        <router-link
          :to="{ name: 'organizationStructure' }"
          class="
            py-4
            flex
            justify-start
            items-center
            w-full
            focus:outline-none
            cursor-pointer
            hover:bg-black
            active:bg-black
          "
        >
          <i class="bx bxs-category text-lg mx-5"></i>
          <span>الهيكل التظيمي</span>
        </router-link>
      </li>

      <li>
        <button
          @click="$store.commit('toggleResetPassword')"
          class="
            py-4
            flex
            justify-start
            items-center
            w-full
            focus:outline-none
            cursor-pointer
            hover:bg-black
            active:bg-black
          "
        >
          <i class="bx text-lg mx-5 bx bxs-key"></i>
          <span>تغيير كلمة المرور</span>
        </button>
      </li>
      <li
        @click="logout"
        class="
          py-4
          flex
          justify-between
          items-center
          cursor-pointer
          hover:bg-black
          active:bg-black
        "
      >
        <div class="flex items-center">
          <i class="bx bx-exit text-lg mx-5"></i>
          <span class="tracking-wide">تسجيل الخروج</span>
        </div>
        <i v-if="loading" class="text-2xl ml-5 bx bx-loader-circle bx-spin"></i>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "navigation",
  data() {
    return {
      sildeMenu: false,
      loading: false,
    };
  },
  methods: {
    toggleSlide() {
      this.$emit("toggleSlide");
    },
    logout() {
      this.loading = true;
      this.$store.dispatch("logout").then(() => {
        this.loading = false;
        setTimeout(() => {
          this.$router.push("/");
        });
      });
    },
  },
  computed: {
    userData() {
      return this.$store.getters.userData;
    },
  },
};
</script>
