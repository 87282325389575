<template>
  <div class="w-full h-full">
    <div class="flex flex-row flex-wrap w-full h-full mt-5">
      <div
        class="relative flex flex-wrap w-full p-5 mx-3 mt-8 border rounded-md  lg:w-3/4 lg:mx-auto border-formBorderColor"
      >
        <span class="absolute -mt-8 text-lg font-bold bg-white text-primary">
          الخبرة العملية
        </span>
        <div
          class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
        >
          <label
            for="qualification"
            class="flex items-center w-full text-lg font-medium text-right"
            ><span class="mx-2 mt-2 text-3xl text-red-600">*</span> المؤهل
            العلمي
          </label>
          <input
            id="qualification"
            name="qualification"
            v-model="form.qualification"
            type="text"
            required
            @blur="$v.form.qualification.$touch()"
            :class="
              defualtInput +
              ($v.form.qualification.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.qualification.$error"
            class="w-full my-1 text-sm text-red-600"
          >
            الرجاء تعبئة هذا الحقل
          </p>
        </div>
        <div
          class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
        >
          <label
            for="specialization"
            class="flex items-center w-full text-lg font-medium text-right"
            ><span class="mx-2 mt-2 text-3xl text-red-600">*</span>التخصص</label
          >
          <input
            id="specialization"
            name="specialization"
            v-model="form.specialization"
            type="text"
            required
            @blur="$v.form.specialization.$touch()"
            :class="
              defualtInput +
              ($v.form.specialization.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.specialization.$error"
            class="w-full my-1 text-sm text-red-600"
          >
            الرجاء تعبئة هذا الحقل
          </p>
        </div>
        <div
          class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
        >
          <label
            for="year_of_graduation"
            class="flex items-center w-full text-lg font-medium text-right"
            ><span class="mx-2 mt-2 text-3xl text-red-600">*</span>سنة
            التخرج</label
          >
          <input
            id="year_of_graduation"
            name="year_of_graduation"
            v-model="form.year_of_graduation"
            @blur="$v.form.year_of_graduation.$touch()"
            :class="
              defualtInput +
              ($v.form.year_of_graduation.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
            type="date"
          />
          <p
            v-if="$v.form.year_of_graduation.$error"
            class="w-full my-1 text-sm text-red-600"
          >
            الرجاء تعبئة هذا الحقل
          </p>
        </div>
        <div
          class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
        >
          <label
            for="name_of_the_university"
            class="flex items-center w-full text-lg font-medium text-right"
            ><span class="mx-2 mt-2 text-3xl text-red-600">*</span>اسم
            الجامعة</label
          >
          <input
            id="name_of_the_university"
            name="name_of_the_university"
            v-model="form.name_of_the_university"
            type="text"
            required
            @blur="$v.form.name_of_the_university.$touch()"
            :class="
              defualtInput +
              ($v.form.name_of_the_university.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.name_of_the_university.$error"
            class="w-full my-1 text-sm text-red-600"
          >
            الرجاء تعبئة هذا الحقل
          </p>
        </div>
        <div
          class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
        >
          <label
            for="experience"
            class="w-full text-lg font-medium text-right text-h1Text"
            >الخبرة العملية</label
          >
          <input
            id="experience"
            name="experience"
            v-model="form.experience"
            type="text"
            required
            :class="defualtInput + 'border-inputBorder'"
          />
        </div>
        <div
          class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
        >
          <label
            for="date_of_hiring"
            class="w-full text-lg font-medium text-right text-h1Text"
            >تاريخ التعيين بالقطاع</label
          >
          <input
            id="date_of_hiring"
            name="date_of_hiring"
            v-model="form.date_of_hiring"
            :class="defualtInput + 'border-inputBorder'"
            type="date"
          />
        </div>
        <div
          class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
        >
          <label
            for="current_place_of_work"
            class="w-full text-lg font-medium text-right text-h1Text"
            >مكان العمل الحالي</label
          >
          <div class="flex flex-wrap w-full lg:pl-3">
            <input
              id="current_place_of_work"
              name="current_place_of_work"
              v-model="form.current_place_of_work"
              type="text"
              required
              :class="defualtInput + 'border-inputBorder'"
            />
            <div class="flex flex-wrap items-center w-full mt-1">
              <label class="inline-flex items-center mx-2">
                <input
                  type="radio"
                  class="form-radio"
                  name="WorkType"
                  :class="
                    $v.form.sector.$error
                      ? 'border-red-600'
                      : 'border-inputBorder'
                  "
                  value="public"
                  v-model="form.sector"
                />
                <span class="mr-2 text-h1Text">عام</span>
              </label>
              <label class="inline-flex items-center mx-2">
                <input
                  type="radio"
                  class="form-radio border-inputBorder"
                  name="WorkType"
                  value="private"
                  :class="
                    $v.form.sector.$error
                      ? 'border-red-600'
                      : 'border-inputBorder'
                  "
                  v-model="form.sector"
                />
                <span class="mr-2 text-h1Text">خاص</span>
              </label>
              <span class="text-sm text-red-500" v-if="$v.form.sector.$error"
                >يجب تحديد القطاع</span
              >
            </div>
          </div>
        </div>

        <div
          class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
        >
          <label
            for="date_of_joining"
            class="w-full text-lg font-medium text-right text-h1Text"
            >تاريخ الانتساب للنقابة</label
          >
          <input
            id="date_of_joining"
            name="date_of_joining"
            v-model="form.date_of_joining"
            :class="defualtInput + 'border-inputBorder'"
            type="date"
          />
        </div>
        <div
          class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
        >
          <label
            for="notes"
            class="w-full text-lg font-medium text-right text-h1Text"
            >بيانات اضافية</label
          >
          <textarea v-model="form.notes" :class="defualtInput"> </textarea>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-end w-full mx-auto lg:w-3/4">
      <p v-if="$v.form.$error" class="font-medium text-red-600 text-md">
        الرجاء تكملة الحقول المطلوبة
      </p>
      <button
        @click="$router.go(-1)"
        class="w-auto px-8 py-2 m-3 text-lg font-medium bg-white border rounded-md shadow  focus:outline-none hover:shadow-lg md:m-5 border-primary text-primary"
      >
        رجوع
      </button>
      <button
        @click.prevent="submit"
        class="flex items-center w-auto px-8 py-2 m-3 text-lg font-medium text-white rounded-md shadow  focus:outline-none hover:shadow-lg md:m-5 bg-primary"
      >
        <span>تأكيد </span
        ><i
          v-if="loading"
          class="mx-2 text-2xl bx bx-loader-circle bx-spin"
        ></i>
      </button>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      defualtInput:
        "p-2  bg-white rounded-md border w-full text-right focus-within:border-primary hover:border-primary focus:border-primary focus:outline-none focus:shadow-md my-1",
      maxDate: moment().toISOString(),
      loading: false,
      form: {
        qualification: "",
        specialization: "",
        year_of_graduation: "",
        name_of_the_university: "",
        experience: "",
        date_of_hiring: "",
        current_place_of_work: "",
        sector: "",
        date_of_joining: "",
        notes: "",
      },
    };
  },
  validations() {
    return {
      form: {
        qualification: { required },
        specialization: { required },
        year_of_graduation: { required },
        name_of_the_university: { required },
        sector: {
          required: requiredIf(() => this.form.current_place_of_work),
        },
      },
    };
  },
  computed: {
    editDoctor() {
      return this.$store.getters.editDoctor;
    },
    individualData() {
      return this.$store.getters.individualData;
    },
  },
  created() {
    if (this.individualData) {
      this.form = this.individualData;
    }
  },
  mounted() {
    if (this.editDoctor) {
      this.form = {
        qualification: this.editDoctor.qualification,
        specialization: this.editDoctor.specialization,
        year_of_graduation: this.editDoctor.year_of_graduation,
        name_of_the_university: this.editDoctor.name_of_the_university,
        experience: this.editDoctor.experience,
        date_of_hiring: this.editDoctor.date_of_hiring,
        current_place_of_work: this.editDoctor.current_place_of_work,
        sector: this.editDoctor.sector,
        date_of_joining: this.editDoctor.date_of_joining,
        notes: this.editDoctor.notes,
      };
    }
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$pending || this.$v.form.$error) return;
      this.loading = true;

      this.$store.commit("individualFormFilling", this.form);
      if (!this.editDoctor) {
        this.$store
          .dispatch("submitIndividual")
          .then(() => {
            this.$store.commit(
              "toggleSuccessMsg",
              "سيتم مراجعة الطلب وارسال القبول على بريدك الإلكتروني"
            );
            this.loading = false;
          })
          .catch((data) => {
            this.loading = false;
            this.$store.commit("toggleErrorMsg", data);
          });
      } else {
        this.$store
          .dispatch("editSubmitIndividual", this.editDoctor.id)
          .then(() => {
            this.$store.commit("toggleSuccessMsg", "تم التعديل بنجاح");
            this.loading = false;
          })
          .catch((message) => {
            this.loading = false;
            this.$store.commit("toggleErrorMsg", message);
          });
      }
    },
  },
};
</script>