<template>
  <div class="h-screen flex items-center justify-center bg-bgcolor">
    <i
      v-if="pageLoading"
      class="bx bx-loader-alt bx-spin text-6xl text-primary"
    ></i>
    <transition name="fade">
      <div
        v-if="!pageLoading"
        class="
          bg-bgcolor
          md:rounded-lg
          border border-formBorderColor
          overflow-hidden
          shadow-2xl
          h-full
          md:h-auto
          w-full
          md:w-1/2
          lg:w-1/3
          z-50
        "
      >
        <div class="bg-bgcolor p-smMargin">
          <form
            v-if="!ResetPassword"
            @submit.prevent="submit"
            autocomplete="on"
            ref="login"
          >
            <div class="flex justify-between items-center w-full mb-5">
              <h1 class="text-primary text-center text-3xl">تسجيل الدخول</h1>
              <img
                class="h-16 w-16"
                alt=""
                loading="lazy"
                src="../assets/imgs/libyan_labs_logo_white_bg.png"
              />
            </div>

            <div class="flex items-center justify-start">
              <input
                required
                id="email"
                name="email"
                type="email"
                placeholder="البريد الالكتروني"
                v-model="form.email"
                @blur="$v.form.email.$touch()"
                :class="
                  $v.form.email.$error
                    ? ' border-red-700'
                    : 'border-inputBorder'
                "
                class="
                  mb-5
                  p-3
                  shadow
                  w-full
                  rounded-md
                  border
                  outline-none
                  focus:outline-none
                  hover:bg-gray-100
                  focus:bg-gray-100 focus:border-primary focus:shadow-md
                "
              />
            </div>

            <div class="flex items-center justify-start">
              <input
                id="password"
                name="password"
                v-model="form.password"
                @blur="$v.form.password.$touch()"
                :class="
                  $v.form.password.$error
                    ? ' border-red-700'
                    : 'border-inputBorder'
                "
                class="
                  w-full
                  p-3
                  rounded-md
                  border
                  shadow
                  outline-none
                  focus:outline-none
                  hover:bg-gray-100
                  focus:bg-gray-100 focus:border-primary focus:shadow-md
                "
                required
                :type="passwordtype"
                placeholder="كلمة المرور"
              />
              <i
                class="-mr-8 text-3xl text-gray-500 cursor-pointer"
                @click="
                  passwordtype == 'password'
                    ? (passwordtype = 'text')
                    : (passwordtype = 'password')
                "
                :class="
                  passwordtype == 'password' ? 'bx bx-show' : 'bx bx-hide'
                "
              ></i>
            </div>

            <button
              @click.prevent="submit"
              class="
                mt-5
                mb-2
                p-3
                flex
                items-center
                bg-primary
                justify-center
                bg-green
                text-white
                w-full
                rounded-lg
                outline-none
                focus:outline-none focus:shadow-outline
              "
            >
              <span class="mx-2">تسجيل الدخول</span>
              <i
                v-if="loading"
                class="text-2xl bx bx-loader-circle bx-spin"
              ></i>
            </button>

            <p
              v-if="$v.form.$error"
              class="text-red-600 text-md my-2 font-medium"
            >
              الرجاء ادخال البريد الالكتروني و كلمة المرور
            </p>
          </form>

          <form
            v-else
            @submit.prevent="ResetPasswordFunc"
            autocomplete="on"
            ref="ResetPassword"
          >
            <h1 class="text-primary my-5 text-right text-3xl">
              اعادة كلمة المرور
            </h1>

            <div class="flex items-center justify-start">
              <input
                required
                id="email"
                name="email"
                type="email"
                placeholder="البريد الالكيتروني"
                v-model="ResetPassEmail"
                @blur="$v.ResetPassEmail.$touch()"
                :class="
                  $v.ResetPassEmail.$error
                    ? ' border-red-700'
                    : 'border-inputBorder'
                "
                class="
                  mb-5
                  p-3
                  shadow
                  w-full
                  text-right
                  rounded-md
                  border
                  outline-none
                  focus:outline-none
                  hover:bg-gray-100
                  focus:bg-gray-100 focus:border-primary focus:shadow-md
                "
              />
              <p
                v-if="$v.ResetPassEmail.$error"
                class="text-red-600 text-md my-2 font-medium"
              >
                الرجاء ادخال البريد الالكتروني
              </p>
            </div>

            <button
              @click.prevent="ResetPasswordFunc"
              class="
                my-5
                p-3
                flex
                items-center
                bg-primary
                justify-center
                bg-green
                text-white
                w-full
                rounded-lg
                outline-none
                focus:outline-none focus:shadow-outline
              "
            >
              <span class="mx-2">اعادة كلمة المرور</span>
              <i
                v-if="loading"
                class="text-2xl bx bx-loader-circle bx-spin"
              ></i>
            </button>
          </form>

          <p
            v-if="loginError"
            class="
              px-5
              py-2
              border
              flex
              items-center
              border-red-700
              text-red-600
              bg-red-100
              text-right
              font-bold
              text-lg
              rounded-lg
              mt-5
            "
          >
            <i class="bx bxs-error-circle mx-2 text-xl"></i>
            <span v-for="(err, index) in loginError" :key="index">{{
              err
            }}</span>
          </p>
          <div
            v-if="success"
            class="
              flex
              justify-center
              items-center
              m-1
              font-medium
              py-1
              px-2
              rounded-md
              text-green-100
              bg-green-700
              border border-green-700
            "
          >
            <div slot="avatar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-check-circle w-5 h-5 mx-2"
              >
                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                <polyline points="22 4 12 14.01 9 11.01"></polyline>
              </svg>
            </div>
            <div class="text-xl font-normal text-right">
              <h1 class="py-2 font-medium">{{ success }}</h1>
            </div>
            <button
              @click="toggleSuccessMsg"
              class="
                bg-white
                flex
                items-center
                text-green-700
                focus:outline-none
                font-medium
                mx-3
                text-lg
                rounded-md
                px-3
                py-1
              "
            >
              <span>حسناً</span>
            </button>
          </div>

          <button
            @click="ResetPassword = !ResetPassword"
            class="
              block
              text-hTextColor
              my-5
              tracking-wide
              outline-none
              focus:outline-none
            "
          >
            {{ ResetPassword ? "تسجيل الدخول" : "نسيت كلمة المرور ؟" }}
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { required, email, minLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      pageLoading: false,
      form: {
        email: null,
        password: null,
      },
      passwordtype: "password",
      ResetPassEmail: null,
      ResetPassword: false,
      loginError: "",
      loading: false,
      success: null,
    };
  },
  validations: {
    form: {
      email: { required, email },
      password: { required, minLength: minLength(6) },
    },
    ResetPassEmail: { required, email },
  },

  computed: {
    userData() {
      return this.$store.getters.userData;
    },
  },
  methods: {
    toggleSuccessMsg() {
      this.success = null;
      this.$router.push({ name: "Home" });
    },
    ResetPasswordFunc() {
      this.$v.ResetPassEmail.$touch();
      if (this.$v.ResetPassEmail.$pending || this.$v.ResetPassEmail.$error)
        return;
      this.loading = true;
      this.$store
        .dispatch("forgotPassword", this.ResetPassEmail)
        .then((res) => {
          this.success = res.message;
          this.loading = false;
        })
        .catch(({ message }) => {
          this.loginError = message;
          setTimeout(() => {
            this.loginError = "";
          }, 5000);
          this.loading = false;
        });
    },
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$pending || this.$v.form.$error) return;
      this.loading = true;
      this.loginError = "";
      this.$store
        .dispatch("login", this.form)
        .then(() => {
          this.loading = false;
          this.$router.push({ name: "Dashboard" });
        })
        .catch((response) => {
          if (response.message) {
            this.loginError = [response.message];
          }
          this.loading = false;
        });
    },
  },
};
</script>
<style>
input::placeholder {
  color: #d3d3d3;
}
</style>
