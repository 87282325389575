<template>
  <div>
    <div v-if="!loading" class="w-full h-full flex flex-wrap my-5">
      <div
        class="
          flex
          items-center
          justify-between
          container
          mx-auto
          py-4
          px-8
          border-b
          mb-10
        "
      >
        <h1 class="text-3xl text-h1Text">تفاصيل المختبر</h1>
        <button
          class="bg-primary px-4 py-2 text-lg rounded-md text-white"
          @click="editLab(labDetail)"
        >
          <span class="mx-2">تعديل</span>
          <i class="bx bx-message-square-edit"></i>
        </button>
      </div>
      <div
        v-if="labDetail"
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-xl font-bold">
          الحالة
        </span>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">الحالة:</label>
          <p
            :class="
              labDetail.status == 'نشط'
                ? 'bg-activeBG text-activeText'
                : labDetail.status == 'طلب عضوية'
                ? 'bg-unactiveBG text-unactiveText'
                : labDetail.status == 'حذف'
                ? 'bg-deletedBG text-deletedText'
                : labDetail.status == 'حظر'
                ? 'bg-blockedBG text-blockedText'
                : labDetail.status == 'غير نشط'
                ? 'bg-unactiveBG text-unactiveText'
                : null
            "
            class="
              font-medium
              text-xl text-h1Text
              mx-2
              px-3
              py-1
              rounded-lg
              flex
              items-center
            "
          >
            <i
              class="bx mx-2 text-xl"
              :class="
                labDetail.status == 'حذف'
                  ? 'bx-trash text-deletedBtn'
                  : labDetail.status == 'حظر'
                  ? 'bx-block text-blockedBtn'
                  : labDetail.status == 'غير نشط' || 'طلب عضوية'
                  ? 'bx-toggle-left text-unactiveBtn'
                  : labDetail.status == 'نشط'
                  ? 'bx-donate-heart text-activeBtn'
                  : null
              "
            ></i>
            <span class="mx-2">{{ labDetail.status }}</span>
          </p>
        </div>
        <div class="p-5 flex items-center justify-between w-full md:w-1/2">
          <label for="status" class="font-medium text-lg text-right block"
            >تغيير الحالة الى:</label
          >
          <v-select
            dir="rtl"
            :options="['حذف', 'حظر', 'نشط']"
            id="status"
            :clearable="false"
            name="status"
            v-model="status"
            class="
              p-0
              border-inputBorder
              bg-white
              rounded-md
              border
              w-3/5
              text-right
              focus-within:border-primary
              hover:border-primary
              focus:border-primary focus:outline-none focus:shadow-md
              my-1
            "
            :value="labDetail.status"
          ></v-select>
        </div>
      </div>
      <div
        v-if="labDetail"
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-xl font-bold">
          المعلومات الاساسية
        </span>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">اسم المختبر:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ labDetail.lab_name }}
          </p>
        </div>
        <div class="p-5 flex items-center w-1/2 md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >رقم التسجيل بالغرفة التجارية:</label
          >
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ labDetail.chamber_of_commerce_registration_number }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >البريد الالكتروني:</label
          >
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ labDetail.email }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">فرع النقابة:</label>
          <p
            v-if="labDetail.branches"
            class="font-medium text-xl text-h1Text mx-2"
          >
            {{ labDetail.branches.name_ar }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >الموقع الالكتروني:</label
          >
          <p
            v-if="labDetail.website"
            class="font-medium text-xl text-h1Text mx-2"
          >
            {{ labDetail.website }}
          </p>
        </div>
      </div>
      <div
        v-if="labDetail"
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-xl font-bold">
          بيانات الترخيص
        </span>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">رقم الترخيص:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ labDetail.license_number }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">جهة صدوره:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ labDetail.Where_license_issued }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">تاريخ صدوره:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ labDetail.date_of_license_issue }}
          </p>
        </div>
      </div>
      <div
        v-if="labDetail"
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-xl font-bold">
          بيانات اذن المزاولة
        </span>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >رقم اذن المزاولة:</label
          >
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ labDetail.Service_practice_number }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">جهة صدوره:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ labDetail.where_service_practice_issued }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">تاريخ صدوره:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ labDetail.date_of_service_practice_issue }}
          </p>
        </div>
      </div>
      <div
        v-if="labDetail"
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-xl font-bold">
          مكان المختبر
        </span>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">المدينة:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ labDetail.city }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">البلدية:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ labDetail.region }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">الشارع:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ labDetail.street }}
          </p>
        </div>
      </div>
      <div
        v-if="labDetail"
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-xl font-bold">
          مالك المختبر
        </span>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">الاسم:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ labDetail.lap_owner_name }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >البريد الالكتروني:</label
          >
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ labDetail.owner_email }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">الهاتف:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ labDetail.owner_phone_number }}
          </p>
        </div>
      </div>

      <div
        v-if="labDetail"
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-xl font-bold">
          طباعة
        </span>
        <div class="py-3 w-full sm:w-1/2 lg:w-2/6 my-5 mx-auto rounded-lg">
          <button
            @click="printReport"
            class="
              border-2 border-primary
              rounded-lg
              bg-primary
              text-lg text-white
              py-2
              font-bold
              w-full
              flex
              justify-center
              items-center
            "
          >
            <span> طباعة اذن مزاولة مختبر {{ labDetail.lab_name }} </span>
            <i class="bx bx-printer mx-2 text-2xl"></i>
          </button>
        </div>
      </div>
      <div v-if="errMsg">
        <alert :msg="errMsg" />
      </div>
    </div>
    <div v-else class="flex justify-center py-5">
      <i class="text-4xl mx-auto text-primary bx bx-loader-circle bx-spin"></i>
    </div>
  </div>
</template>
<script>
import alert from "../../layout/alert";
import Printlab from "../printDoc/lab";
export default {
  name: "labDetail",
  data() {
    return {
      loading: false,
      labDetail: null,
      status: null,
      errMsg: null,
    };
  },
  watch: {
    status() {
      this.ChangeLabStatus();
    },
  },
  computed: {
    Branches() {
      return this.$store.getters.getBranches;
    },
    userData() {
      return this.$store.getters.userData;
    },
  },
  created() {
    this.getNewData();
  },
  methods: {
    getNewData() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("getlabDetails", this.$route.params.doc)
          .then(({ data }) => {
            this.labDetail = data.data;
            resolve(data);
            this.loading = false;
          })
          .catch((err) => {
            reject(err);
            this.loading = false;
          });
      });
    },
    editLab(labDetail) {
      this.$store.commit("editLab", labDetail);
      this.$router.push({ name: "Labs" });
    },
    ChangeLabStatus() {
      this.loading = true;
      this.$store
        .dispatch("LabStatus", { status: this.status, _id: this.labDetail.id })
        .then(() => {
          this.getNewData();
        });
    },
    printReport() {
      if (this.labDetail.status !== "نشط") {
        this.errMsg = "يجب تنشيط هذا المختبر لطباعة إذن المزاولة الخاص به";
        setTimeout(() => {
          this.errMsg = null;
        }, 7000);
      } else {
        var tab = Printlab(this.labDetail, this.userData);

        const WinPrint = window.open(
          "",
          "",
          "right=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        );
        WinPrint.document.write(tab);
      }
    },
  },
  components: {
    alert,
  },
};
</script>