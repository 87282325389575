<template>
  <div class="w-full h-full">
    <nav class="w-full z-50 bg-primary mb-5">
      <div class="container mx-auto px-8 flex items-center justify-start py-2">
        <img
          @click="$router.push('/')"
          src="../../assets/imgs/libyan_labs_logo_dark_bg.png"
          class="cursor-pointer"
          width="65"
          height="65"
          alt=""
          loading="lazy"
        />
        <h1 class="text-right text-white text-xl font-bold p-5">
          نموذج تسجيل شركة محلية
        </h1>
      </div>
    </nav>

    <div class="w-full h-full mt-5">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.$store.dispatch("getBranches");
  },
};
</script>
