<template>
  <div class="w-full h-full">
    <nav class="w-full z-50 bg-primary mb-5">
      <div class="container mx-auto px-8 flex items-center justify-start py-2">
        <img
          @click="$router.push('/')"
          src="../../assets/imgs/libyan_labs_logo_dark_bg.png"
          class="cursor-pointer"
          width="65"
          height="65"
          alt=""
          loading="lazy"
        />
        <h1 class="text-right text-white text-xl font-bold p-5">
          اضافة فرع جديد
        </h1>
      </div>
    </nav>
    <div class="w-full h-full mt-5">
      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
          بيانات الفرع
        </span>
        <div
          class="
            p-5
            flex
            items-center
            w-full
            mx-auto
            md:mx-0
            md:w-1/2
            flex-wrap
          "
        >
          <label
            for="city"
            class="font-medium text-lg w-full text-right flex items-center"
            ><span class="text-red-600 mx-2 text-3xl mt-3">*</span>
            <span>المدينة</span>
          </label>
          <input
            id="city"
            name="city"
            v-model="form.name_ar"
            type="text"
            required
            @blur="$v.form.name_ar.$touch()"
            :class="
              defualtInput +
              ($v.form.name_ar.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.name_ar.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            الرجاء تعبئة هذا الحقل
          </p>
        </div>
        <div
          class="
            p-5
            flex
            items-center
            w-full
            mx-auto
            md:mx-0
            md:w-1/2
            flex-wrap
          "
        >
          <label
            for="city"
            class="font-medium text-lg w-full text-right flex items-center"
            ><span class="text-red-600 mx-2 text-3xl mt-3">*</span>اسم المدينة
            بالانجليزي
          </label>
          <input
            id="city"
            name="city"
            v-model="form.name_en"
            type="text"
            required
            @blur="$v.form.name_en.$touch()"
            :class="
              defualtInput +
              ($v.form.name_en.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.name_en.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            الرجاء تعبئة هذا الحقل
          </p>
        </div>
        <div
          class="
            p-5
            flex
            items-center
            md:mx-0
            w-full
            mx-auto
            md:w-1/2
            flex-wrap
          "
        >
          <label
            for="admin_name"
            class="font-medium text-lg w-full text-right flex items-center"
            ><span class="text-red-600 mx-2 text-3xl mt-3">*</span>اسم رئيس
            الفرع
          </label>
          <input
            id="admin_name"
            name="admin_name"
            v-model="form.admin_name_ar"
            type="text"
            required
            @blur="$v.form.admin_name_ar.$touch()"
            :class="
              defualtInput +
              ($v.form.admin_name_ar.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.admin_name_ar.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            الرجاء تعبئة هذا الحقل
          </p>
        </div>
        <div
          class="
            p-5
            flex
            items-center
            md:mx-0
            w-full
            mx-auto
            md:w-1/2
            flex-wrap
          "
        >
          <label
            for="admin_name"
            class="font-medium text-lg w-full text-right flex items-center"
            ><span class="text-red-600 mx-2 text-3xl mt-3">*</span>اسم رئيس
            الفرع بالانجليزي</label
          >
          <input
            id="admin_name"
            name="admin_name"
            v-model="form.admin_name_en"
            type="text"
            required
            @blur="$v.form.admin_name_en.$touch()"
            :class="
              defualtInput +
              ($v.form.admin_name_en.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.admin_name_en.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            الرجاء تعبئة هذا الحقل
          </p>
        </div>
        <div
          class="
            p-5
            flex
            items-center
            md:mx-0
            w-full
            mx-auto
            md:w-1/2
            flex-wrap
          "
        >
          <label
            for="phone_number"
            class="font-medium text-lg w-full text-right flex items-center"
          >
            <span class="text-red-600 mx-2 text-3xl mt-3">*</span>
            <span>رقم الهاتف 1</span>
          </label>
          <input
            id="phone_number"
            name="phone_number"
            v-model="form.phone_number"
            type="tel"
            required
            @blur="$v.form.phone_number.$touch()"
            :class="
              defualtInput +
              ($v.form.phone_number.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.phone_number.$error && !$v.form.phone_number.required"
            class="text-red-600 text-md font-medium w-full text-right"
          >
            الرجاء كتابة رقم الهاتف
          </p>
          <p
            v-if="
              !$v.form.phone_number.minLength || !$v.form.phone_number.maxLength
            "
            class="text-red-600 text-md font-medium w-full text-right"
          >
            الرجاء التحقق من صحة رقم الهاتف، رقم الهاتف يجب ن يكون على النحو
            التالي 09xxxxxxxx
          </p>
        </div>
        <div
          class="
            p-5
            flex
            items-center
            md:mx-0
            w-full
            mx-auto
            md:w-1/2
            flex-wrap
          "
        >
          <label
            for="mobile_number"
            class="font-medium text-lg w-full text-right"
            >رقم الهاتف 2
          </label>
          <input
            id="mobile_number"
            name="mobile_number"
            v-model="form.mobile_number"
            type="tel"
            required
            @blur="$v.form.mobile_number.$touch()"
            :class="
              defualtInput +
              ($v.form.mobile_number.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="
              !$v.form.mobile_number.minLength ||
              !$v.form.mobile_number.maxLength
            "
            class="text-red-600 text-md font-medium w-full text-right"
          >
            الرجاء التحقق من صحة رقم الهاتف، رقم الهاتف يجب ن يكون على النحو
            التالي 09xxxxxxxx
          </p>
        </div>
        <div
          class="
            p-5
            flex
            items-center
            w-full
            mx-auto
            md:mx-0
            md:w-1/2
            flex-wrap
          "
        >
          <label
            for="email"
            class="font-medium text-lg w-full text-right flex items-center"
          >
            <span class="text-red-600 mx-2 text-3xl mt-3">*</span>
            <span>البريد الالكتروني</span>
          </label>
          <input
            id="email"
            name="email"
            v-model="form.email"
            type="email"
            required
            @blur="$v.form.email.$touch()"
            :class="
              defualtInput +
              ($v.form.email.$error ? ' border-red-700' : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.email.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            الرجاء تعبئة هذا الحقل
          </p>
        </div>
        <div
          v-if="!editBranch"
          class="
            p-5
            flex
            items-center
            w-full
            mx-auto
            md:mx-0
            md:w-1/2
            flex-wrap
          "
        >
          <label
            for="admin_password"
            class="font-medium text-lg w-full text-right flex items-center"
            ><span class="text-red-600 mx-2 text-3xl mt-3">*</span>كلمة المرور
          </label>
          <input
            id="admin_password"
            name="admin_password"
            v-model="form.admin_password"
            type="password"
            required
            @blur="$v.form.admin_password.$touch()"
            :class="
              defualtInput +
              ($v.form.admin_password.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.admin_password.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            الرجاء تعبئة هذا الحقل
          </p>
        </div>
      </div>
      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
          مكان الفرع على الخريطة
        </span>
        <div
          class="
            p-5
            flex
            items-center
            w-full
            mx-auto
            md:mx-0
            md:w-1/2
            flex-wrap
          "
        >
          <label
            for="long"
            class="font-medium text-lg w-full text-right flex items-center"
          >
            <span class="text-red-600 mx-2 text-3xl mt-3">*</span>
            <span>خط الطول (long)</span>
          </label>
          <input
            id="long"
            name="long"
            v-model="form.long"
            type="number"
            required
            @blur="$v.form.long.$touch()"
            :class="
              defualtInput +
              ($v.form.long.$error ? ' border-red-700' : 'border-inputBorder')
            "
          />
          <p v-if="$v.form.long.$error" class="text-red-600 my-1">
            يبدو انه ليس خط طول فعلي، خط الطول يجب ان يكون ما بين -180 الى 180
            درجة
          </p>
        </div>
        <div
          class="
            p-5
            flex
            items-center
            w-full
            mx-auto
            md:mx-0
            md:w-1/2
            flex-wrap
          "
        >
          <label
            for="lat"
            class="font-medium text-lg w-full text-right flex items-center"
            ><span class="text-red-600 mx-2 text-3xl mt-3">*</span>
            <span> خط العرض (lat) </span>
          </label>
          <input
            id="lat"
            name="lat"
            v-model="form.lat"
            type="number"
            required
            @blur="$v.form.lat.$touch()"
            :class="
              defualtInput +
              ($v.form.lat.$error ? ' border-red-700' : 'border-inputBorder')
            "
          />
          <p v-if="$v.form.lat.$error" class="text-red-600 my-1">
            يبدو انه ليس خط عرض فعلي، خط العرض يجب ان يكون ما بين -90 الى 90
            درجة
          </p>
        </div>
      </div>
      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <div class="w-full">
          <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
            موضفين الفرع
          </span>
          <div
            class="
              p-5
              flex
              items-center
              w-full
              mx-auto
              md:mx-0
              md:w-1/2
              flex-wrap
            "
          >
            <label for="subAdmin" class="font-medium text-lg w-full text-right"
              >اسم نائب رئيس الفرع
            </label>
            <input
              v-if="!editBranch"
              id="subAdmin"
              name="subAdmin"
              v-model="form.subAdmin"
              type="text"
              required
              :class="defualtInput + 'border-inputBorder'"
            />
            <div v-else class="w-full">
              <input
                v-if="typeof form.subAdmin == 'object'"
                id="subAdmin"
                name="subAdmin"
                v-model="form.subAdmin.name_ar"
                type="text"
                required
                :class="defualtInput + 'border-inputBorder'"
              />
              <input
                v-else
                id="subAdmin"
                name="subAdmin"
                v-model="form.subAdmin"
                type="text"
                required
                :class="defualtInput + 'border-inputBorder'"
              />
            </div>
          </div>
        </div>

        <div
          class="
            p-5
            flex
            items-center
            w-full
            mx-auto
            md:mx-0
            md:w-1/2
            flex-wrap
          "
        >
          <div
            v-for="Employee in form.employees"
            :key="Employee.id"
            class="w-full my-3"
          >
            <label class="font-medium text-lg w-full text-right my-1"
              >اسم الموضف
            </label>
            <div class="flex items-center">
              <input
                type="text"
                v-model="Employee.name_ar"
                required
                :class="defualtInput + 'border-inputBorder'"
              />
              <button
                @click="deleteEmp(Employee.id)"
                class="
                  p-2
                  m-2
                  border border-red-600
                  text-red-600
                  flex
                  items-center
                  justify-center
                  rounded-md
                "
              >
                <i class="bx bx-trash text-xl"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="p-5 flex items-center w-full mx-auto md:mx-0 flex-wrap">
          <button
            @click="AddNumberOfEmployees"
            class="
              px-3
              rounded-md
              w-auto
              shadow
              py-2
              focus:outline-none
              hover:shadow-lg
              my-3
              bg-primary
              text-white
              font-medium
              flex
              items-center
            "
          >
            <span class="mx-2">اضافة موظف</span>
            <i class="bx bxs-plus-circle mx-1"></i>
          </button>
        </div>
      </div>

      <div class="flex w-full lg:w-3/4 mx-auto items-center justify-end">
        <button
          @click="
            $router.go(-1);
            $store.commit('editBranch', null);
          "
          class="
            px-8
            rounded-md
            w-auto
            shadow
            py-2
            focus:outline-none
            hover:shadow-lg
            m-3
            md:m-5
            border border-primary
            text-primary
            bg-white
            text-lg
            font-medium
          "
        >
          رجوع
        </button>
        <button
          @click.prevent="!editBranch ? submit() : EditBranch()"
          class="
            px-8
            rounded-md
            w-auto
            shadow
            flex
            items-center
            py-2
            focus:outline-none
            hover:shadow-lg
            m-3
            md:m-5
            bg-primary
            text-white text-lg
            font-medium
          "
        >
          <span>تأكيد </span
          ><i
            v-if="loading"
            class="text-2xl mx-2 bx bx-loader-circle bx-spin"
          ></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  required,
  email,
  numeric,
  between,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
export default {
  name: "addBranch",
  data() {
    return {
      defualtInput:
        "p-2  bg-white rounded-md border w-full text-right focus-within:border-primary hover:border-primary focus:border-primary focus:outline-none focus:shadow-md my-1",
      form: {
        name_ar: "",
        name_en: "",
        admin_name_ar: "",
        admin_name_en: "",
        admin_password: "",
        phone_number: "",
        mobile_number: "",
        email: "",
        subAdmin: "",
        long: "",
        lat: "",
        employees: [],
      },
      employees_id: 0,
      loading: false,
      successMsg: "",
    };
  },

  validations: {
    form: {
      name_ar: { required },
      name_en: { required },
      admin_name_ar: { required },
      admin_name_en: { required },
      admin_password: { required },
      phone_number: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      mobile_number: {
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      email: { required, email },
      long: { required, between: between(-180, 180) },
      lat: { required, between: between(-90, 90) },
    },
  },
  mounted() {
    if (this.editBranch) {
      this.form = {
        id: this.editBranch.id,
        name_ar: this.editBranch.name_ar,
        name_en: this.editBranch.name_ar,
        lat: this.editBranch.lat,
        long: this.editBranch.long,
        admin_name_ar: this.editBranch.users.find(
          (user) => user.status == "مسؤول"
        ).name_ar,
        admin_id: this.editBranch.users.find((user) => user.status == "مسؤول")
          .id,
        admin_name_en: this.editBranch.users.find(
          (user) => user.status == "مسؤول"
        ).name_en,
        phone_number: this.editBranch.users.find(
          (user) => user.status == "مسؤول"
        ).phone_number,
        email: this.editBranch.users.find((user) => user.status == "مسؤول")
          .email,
        employees: this.editBranch.users.filter(
          (user) => user.job_description == "موظف" && user.status != "مسؤول"
        ),
        subAdmin: this.editBranch.users.find(
          (user) => user.job_description == "نائب الرئيس"
        ),
      };
    }
  },
  created() {
    window.onpopstate = () => {
      if (this.$route.name == "Home" || this.$route.name == "BranchesPanel") {
        this.clear();
      }
    };
  },
  destroyed() {
    this.$store.commit("editBranch", null);
  },
  methods: {
    clear() {
      this.$store.commit("editBranch", null);
    },
    AddNumberOfEmployees() {
      this.employees_id = this.employees_id + 1;
      this.form.employees.push({ id: this.employees_id, name_ar: "" });
    },
    deleteEmp(id) {
      this.form.employees = this.form.employees.filter((emp) => emp.id != id);
    },
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$pending || this.$v.form.$error) return;
      this.loading = true;

      this.form.employees.forEach((em) => {
        if (em.name_ar) {
          return em;
        }
      });

      this.$store
        .dispatch("addNewBranch", this.form)
        .then(() => {
          this.loading = false;
          this.$store.commit("toggleSuccessMsg", "تم اضافة هذا الفرع بنجاح");
        })
        .catch((messages) => {
          this.loading = false;
          this.$store.commit("toggleErrorMsg", messages);
        });
    },
    EditBranch() {
      this.form.employees = this.form.employees.map((em) => {
        if (em.name_ar) {
          return { id: em.id, name_ar: em.name_ar };
        }
      });

      if (typeof this.form.subAdmin == "object") {
        this.form = {
          ...this.form,
          subAdmin: {
            id: this.form.subAdmin.id,
            name_ar: this.form.subAdmin.name_ar,
          },
        };
      }

      this.$store
        .dispatch("editBranch", this.form)
        .then(() => {
          this.loading = false;
          this.$store.commit("toggleSuccessMsg", "تم التعديل على الفرع بنجاح");
        })
        .catch((messages) => {
          this.loading = false;
          this.$store.commit("toggleErrorMsg", messages);
        });
    },
  },
  computed: {
    editBranch() {
      return this.$store.getters.editBranch;
    },
  },
};
</script>
