<template>
  <div>
    <div
      class="flex flex-wrap w-full p-5 mx-3 mt-8 border rounded-md  releative lg:w-3/4 lg:mx-auto border-formBorderColor"
    >
      <span class="absolute -mt-8 text-lg font-bold bg-white text-primary">
        المعلومات الاساسية
      </span>
      <div
        v-if="Branches"
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="date"
          class="flex items-center w-full text-lg font-medium text-right"
          ><span class="mx-2 mt-2 text-3xl text-red-600">*</span>فرع
          النقابة</label
        >
        <v-select
          v-if="Branches"
          :options="Branches"
          dir="rtl"
          label="name_ar"
          id="branch_id"
          :clearable="false"
          @blur="$v.form.branch_id.$touch()"
          name="branch_id"
          v-model="form.branch_id"
          :reduce="(branch_id) => branch_id.id"
          :class="
            defualtInput +
            ($v.form.branch_id.$error
              ? ' border-red-700'
              : 'border-inputBorder')
          "
          class="p-0"
        ></v-select>
        <p
          v-if="$v.form.branch_id.$error"
          class="w-full my-1 text-sm text-red-600"
        >
          الرجاء تعبئة هذا الحقل
        </p>
      </div>
      <div
        v-if="userData ? userData.status == 'super-admin' : null"
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="member_ship_id"
          class="flex items-center w-full text-lg font-medium text-right"
          >رقم العضوية</label
        >
        <input
          id="member_ship_id"
          name="member_ship_id"
          v-model="form.member_ship_id"
          type="text"
          required
          :class="defualtInput + 'border-inputBorder'"
        />
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="company_type"
          class="flex items-center w-full text-lg font-medium text-right"
          ><span class="mx-2 mt-2 text-3xl text-red-600">*</span> نوع
          الشركة</label
        >
        <v-select
          :options="['شركة استيراد', 'شركة خدمات']"
          dir="rtl"
          id="company_type"
          @blur="$v.form.company_type.$touch()"
          name="company_type"
          v-model="form.company_type"
          :class="
            defualtInput +
            ($v.form.company_type.$error
              ? ' border-red-700'
              : 'border-inputBorder')
          "
          class="p-0"
        ></v-select>
        <p
          v-if="$v.form.company_type.$error"
          class="w-full my-1 text-sm text-red-600"
        >
          الرجاء تعبئة هذا الحقل
        </p>
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="registration_number"
          class="flex items-center w-full text-lg font-medium text-right  text-h1Text"
          >رقم القيد</label
        >
        <input
          id="registration_number"
          name="registration_number"
          v-model.number="form.registration_number"
          type="text"
          required
          :class="defualtInput + 'border-inputBorder'"
        />
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="registration_date"
          class="flex items-center w-full text-lg font-medium text-right  text-h1Text"
          >تاريخ تسجيل القيد</label
        >
        <input
          id="registration_date"
          name="registration_date"
          v-model="form.registration_date"
          :class="defualtInput + 'border-inputBorder'"
          type="date"
        />
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="company_name"
          class="flex items-center w-full text-lg font-medium text-right  text-h1Text"
          ><span class="mx-2 mt-2 text-3xl text-red-600">*</span>اسم
          الشركة</label
        >
        <input
          id="company_name"
          name="company_name"
          v-model="form.company_name"
          type="text"
          required
          @blur="$v.form.company_name.$touch()"
          :class="
            defualtInput +
            ($v.form.company_name.$error
              ? ' border-red-700'
              : 'border-inputBorder')
          "
        />
        <p
          v-if="$v.form.company_name.$error"
          class="w-full my-1 text-sm text-red-600"
        >
          الرجاء تعبئة هذا الحقل
        </p>
      </div>

      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="phone_number"
          class="flex items-center w-full text-lg font-medium text-right  text-h1Text"
          ><span class="mx-2 mt-2 text-3xl text-red-600">*</span>رقم
          الهاتف</label
        >
        <input
          id="phone_number"
          name="phone_number"
          v-model="form.phone_number"
          type="text"
          required
          @blur="$v.form.phone_number.$touch()"
          :class="
            defualtInput +
            ($v.form.phone_number.$error
              ? ' border-red-700'
              : 'border-inputBorder')
          "
        />
        <p
          v-if="$v.form.phone_number.$error && !$v.form.phone_number.required"
          class="w-full my-1 text-sm text-red-600"
        >
          الرجاء تعبئة هذا الحقل
        </p>
        <p
          v-if="!$v.form.phone_number.minLength"
          class="w-full my-1 text-sm text-red-600"
        >
          الرجاء التحقق من صحة رقم الهاتف، رقم الهاتف يجب ن يكون على النحو
          التالي 09xxxxxxxx
        </p>
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="mobile_number"
          class="flex items-center w-full text-lg font-medium text-right  text-h1Text"
        >
          رقم الهاتف اخر</label
        >
        <input
          id="mobile_number"
          name="mobile_number"
          v-model="form.mobile_number"
          type="text"
          required
          @blur="$v.form.mobile_number.$touch()"
          :class="
            defualtInput +
            ($v.form.mobile_number.$error
              ? ' border-red-700'
              : 'border-inputBorder')
          "
        />
        <p
          v-if="!$v.form.mobile_number.minLength"
          class="w-full my-1 text-sm text-red-600"
        >
          الرجاء التحقق من صحة رقم الهاتف
        </p>
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="email"
          class="flex items-center w-full text-lg font-medium text-right  text-h1Text"
          >البريد الالكتروني</label
        >
        <input
          id="email"
          name="email"
          v-model="form.email"
          type="email"
          required
          :class="defualtInput + 'border-inputBorder'"
        />
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="website"
          class="w-full text-lg font-medium text-right text-h1Text"
          >الموقع الالكتروني ان وجد</label
        >
        <input
          id="website"
          name="website"
          v-model="form.website"
          type="url"
          @blur="$v.form.website.$touch()"
          :class="
            defualtInput +
            ($v.form.website.$error ? ' border-red-700' : 'border-inputBorder')
          "
        />
        <p
          v-if="$v.form.website.$error"
          class="w-full my-1 text-sm text-red-600"
        >
          الرجاء التحقق من الرابط
        </p>
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="license_number"
          class="flex items-center w-full text-lg font-medium text-right  text-h1Text"
          >رقم الترخيص</label
        >
        <input
          id="license_number"
          name="license_number"
          v-model.number="form.license_number"
          type="text"
          required
          :class="defualtInput + 'border-inputBorder'"
        />
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="Issuer"
          class="flex items-center w-full text-lg font-medium text-right  text-h1Text"
          >جهة ومكان الاصدار</label
        >
        <input
          id="Issuer"
          name="Issuer"
          v-model="form.Issuer"
          type="text"
          required
          :class="defualtInput + 'border-inputBorder'"
        />
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full p-5 md:w-1/2"
      >
        <label
          for="chamber_of_commerce_registration_number"
          class="flex items-center w-full text-lg font-medium text-right  text-h1Text"
          >رقم التسجيل بالغرفة التجارية</label
        >
        <input
          id="chamber_of_commerce_registration_number"
          name="chamber_of_commerce_registration_number"
          v-model.number="form.chamber_of_commerce_registration_number"
          type="text"
          required
          :class="defualtInput + 'border-inputBorder'"
        />
      </div>
    </div>
    <div class="flex items-center justify-end w-full mx-auto lg:w-3/4">
      <p v-if="$v.form.$error" class="font-medium text-red-600 text-md">
        الرجاء تكملة الحقول المطلوبة
      </p>
      <button
        @click="
          () => {
            clear();
            $router.go(-1);
          }
        "
        class="w-auto px-8 py-2 m-3 text-lg font-medium bg-white border rounded-md shadow  focus:outline-none hover:shadow-lg md:m-5 border-primary text-primary"
      >
        رجوع
      </button>
      <button
        @click="next"
        class="w-auto px-8 py-2 m-3 text-lg font-medium text-white rounded-md shadow  focus:outline-none hover:shadow-lg md:m-5 bg-primary"
      >
        التالي
      </button>
    </div>
  </div>
</template>
<script>
import {
  required,
  email,
  numeric,
  url,
  minLength,
} from "vuelidate/lib/validators";
import moment from "moment";
export default {
  name: "companyGeneral",
  data() {
    return {
      defualtInput:
        "p-2  bg-white rounded-md border w-full text-right focus-within:border-primary hover:border-primary focus:border-primary focus:outline-none focus:shadow-md my-1",
      maxDate: moment().format("L"),
      form: {
        branch_id: "",
        company_type: "",
        registration_number: "",
        registration_date: "",
        member_ship_id: "",
        company_name: "",
        phone_number: "",
        mobile_number: "",
        email: "",
        website: "",
        license_number: "",
        Issuer: "",
        chamber_of_commerce_registration_number: "",
      },
    };
  },
  validations() {
    return {
      form: {
        branch_id: { required },
        company_type: { required },
        company_name: { required },
        website: { url },
        email: { email },
        phone_number: { numeric, required, minLength: minLength(10) },
        mobile_number: { numeric, minLength: minLength(10) },
      },
    };
  },
  created() {
    this.$store.dispatch("getBranches");
    if (this.companyData) {
      this.form = { ...this.form, ...this.companyData };
    }
    window.onpopstate = () => {
      if (this.$route.name == "Home" || this.$route.name == "CompaniesPanel") {
        this.clear();
      }
    };
    if (this.userData) {
      if (this.userData.status !== "super-admin") {
        this.form.branch_id = this.userData.branch_id;
      }
    }
  },
  computed: {
    userData() {
      return this.$store.getters.userData;
    },
    Branches() {
      return this.$store.getters.getBranches;
    },
    editCompany() {
      return this.$store.getters.editCompany;
    },
    companyData() {
      return this.$store.getters.companyData;
    },
  },

  mounted() {
    if (this.editCompany) {
      this.form = {
        branch_id: this.editCompany.branch_id,
        company_type: this.editCompany.company_type,
        registration_number: this.editCompany.registration_number,
        registration_date: this.editCompany.registration_date,
        company_name: this.editCompany.company_name,
        member_ship_id: this.editCompany.member_ship_id,
        phone_number: this.editCompany.phone_number,
        mobile_number: this.editCompany.mobile_number,
        email: this.editCompany.email,
        website: this.editCompany.website,
        license_number: this.editCompany.license_number,
        Issuer: this.editCompany.Issuer,
        chamber_of_commerce_registration_number:
          this.editCompany.chamber_of_commerce_registration_number,
      };
    }
  },
  methods: {
    clear() {
      this.$store.commit("editCompany", null);
      this.$store.commit("companyFormFilling", null);
    },
    next() {
      this.$v.form.$touch();
      if (this.$v.form.$pending || this.$v.form.$error) return;

      this.$store.commit("companyFormFilling", {
        ...this.form,
      });
      this.$router.push({ name: "companyPersonal" });
    },
  },
};
</script>
