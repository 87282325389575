<template>
  <div>
    <div v-if="!loading" class="w-full h-full flex flex-wrap my-5">
      <div
        class="
          flex
          items-center
          justify-between
          container
          mx-auto
          py-4
          px-8
          border-b
          mb-10
        "
      >
        <h1 class="text-3xl text-h1Text">تفاصيل الشركة</h1>
        <button
          class="bg-primary px-4 py-2 text-lg rounded-md text-white"
          @click="editCompany"
        >
          <span class="mx-2">تعديل</span>
          <i class="bx bx-message-square-edit"></i>
        </button>
      </div>
      <div
        v-if="CompanyDetail"
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-xl font-bold">
          الحالة
        </span>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">الحالة:</label>
          <p
            :class="
              CompanyDetail.status == 'نشط'
                ? 'bg-activeBG text-activeText'
                : CompanyDetail.status == 'طلب عضوية'
                ? 'bg-unactiveBG text-unactiveText'
                : CompanyDetail.status == 'حذف'
                ? 'bg-deletedBG text-deletedText'
                : CompanyDetail.status == 'حظر'
                ? 'bg-blockedBG text-blockedText'
                : CompanyDetail.status == 'غير نشط'
                ? 'bg-unactiveBG text-unactiveText'
                : null
            "
            class="
              font-medium
              text-xl text-h1Text
              mx-2
              px-3
              py-1
              rounded-lg
              flex
              items-center
            "
          >
            <i
              class="bx mx-2 text-xl"
              :class="
                CompanyDetail.status == 'حذف'
                  ? 'bx-trash text-deletedBtn'
                  : CompanyDetail.status == 'حظر'
                  ? 'bx-block text-blockedBtn'
                  : CompanyDetail.status == 'غير نشط' || 'طلب عضوية'
                  ? 'bx-toggle-left text-unactiveBtn'
                  : CompanyDetail.status == 'نشط'
                  ? 'bx-donate-heart text-activeBtn'
                  : null
              "
            ></i>
            <span v-if="CompanyDetail" class="mx-2">{{
              CompanyDetail.status
            }}</span>
          </p>
        </div>
        <div class="p-5 flex items-center justify-between w-full md:w-1/2">
          <label for="status" class="font-medium text-lg text-right block"
            >تغيير الحالة الى:</label
          >
          <v-select
            dir="rtl"
            :options="['حذف', 'حظر', 'نشط']"
            id="status"
            :clearable="false"
            name="status"
            v-model="status"
            class="
              p-0
              border-inputBorder
              bg-white
              rounded-md
              border
              w-3/5
              text-right
              focus-within:border-primary
              hover:border-primary
              focus:border-primary focus:outline-none focus:shadow-md
              my-1
            "
            :value="CompanyDetail.status"
          ></v-select>
        </div>
      </div>
      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-xl font-bold">
          المعلومات الاساسية
        </span>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">اسم الشركة:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ CompanyDetail.company_name }}
          </p>
        </div>
        <div class="p-5 flex items-center w-1/2 md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >رقم التسجيل بالغرفة التجارية:</label
          >
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ CompanyDetail.chamber_of_commerce_registration_number }}
          </p>
        </div>
        <div
          v-if="CompanyDetail.email"
          class="p-5 flex items-center w-full md:w-1/2 flex-wrap"
        >
          <label class="font-medium text-xl text-gray-700"
            >البريد الالكتروني:</label
          >
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ CompanyDetail.email }}
          </p>
        </div>
        <div
          v-if="CompanyDetail.phone_number"
          class="p-5 flex items-center w-full md:w-1/2 flex-wrap"
        >
          <label class="font-medium text-xl text-gray-700">هاتف:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ CompanyDetail.phone_number }}
          </p>
        </div>
        <div
          v-if="CompanyDetail.mobile_number"
          class="p-5 flex items-center w-full md:w-1/2 flex-wrap"
        >
          <label class="font-medium text-xl text-gray-700">هاتف النقال:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ CompanyDetail.mobile_number }}
          </p>
        </div>
        <div
          v-if="CompanyDetail.branches"
          class="p-5 flex items-center w-full md:w-1/2 flex-wrap"
        >
          <label class="font-medium text-xl text-gray-700">فرع النقابة:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ CompanyDetail.branches.name }}
          </p>
        </div>
        <div
          v-if="CompanyDetail.website"
          class="p-5 flex items-center w-full md:w-1/2 flex-wrap"
        >
          <label class="font-medium text-xl text-gray-700"
            >الموقع الالكتروني:</label
          >
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ CompanyDetail.website }}
          </p>
        </div>
      </div>
      <div
        class="
          relative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-xl font-bold">
          بيانات الترخيص
        </span>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">رقم الترخيص:</label>
          <p
            v-if="CompanyDetail.license_number"
            class="font-medium text-xl text-h1Text mx-2"
          >
            {{ CompanyDetail.license_number }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">رقم القيد:</label>
          <p
            v-if="CompanyDetail.registration_number"
            class="font-medium text-xl text-h1Text mx-2"
          >
            {{ CompanyDetail.registration_number }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">تاريخ صدوره:</label>
          <p
            v-if="CompanyDetail.registration_date"
            class="font-medium text-xl text-h1Text mx-2"
          >
            {{ CompanyDetail.registration_date }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >صورة من اشتراك النقابة :</label
          >
          <a
            v-if="CompanyDetail.photos.image_of_subscription.length > 0"
            :href="
              'https://' + CompanyDetail.photos.image_of_subscription[0].path
            "
            download
            target="_blank"
            class="
              font-medium
              text-xl text-white
              bg-green-600
              px-4
              py-1
              rounded-lg
              mx-2
            "
          >
            تحميل
          </a>
          <p v-else class="font-medium text-xl text-h1Text px-4 py-1 mx-2">
            لا يوجد ملف
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">
            وصورة من اذن مزاولة المهنة سارية الصلاحية:</label
          >
          <a
            v-if="
              CompanyDetail.photos
                .copy_of_a_valid_license_to_practice_the_profession.length > 0
            "
            :href="
              'https://' +
              CompanyDetail.photos
                .copy_of_a_valid_license_to_practice_the_profession[0].path
            "
            download
            target="_blank"
            class="
              font-medium
              text-xl text-white
              bg-green-600
              px-4
              py-1
              rounded-lg
              mx-2
            "
          >
            تحميل
          </a>
          <p v-else class="font-medium text-xl text-h1Text px-4 py-1 mx-2">
            لا يوجد ملف
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >صورة من اذن فتح الشركة السابق:</label
          >
          <a
            v-if="CompanyDetail.photos.former_permission_of_company.length > 0"
            :href="
              'https://' +
              CompanyDetail.photos.former_permission_of_company[0].path
            "
            download
            target="_blank"
            class="
              font-medium
              text-xl text-white
              bg-green-600
              px-4
              py-1
              rounded-lg
              mx-2
            "
          >
            تحميل
          </a>
          <p v-else class="font-medium text-xl text-h1Text px-4 py-1 mx-2">
            لا يوجد ملف
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >عقد تأسيس الشركة :</label
          >
          <a
            v-if="
              CompanyDetail.photos.contract_of_establishment_of_company.length >
              0
            "
            :href="
              'https://' +
              CompanyDetail.photos.contract_of_establishment_of_company[0].path
            "
            download
            target="_blank"
            class="
              font-medium
              text-xl text-white
              bg-green-600
              px-4
              py-1
              rounded-lg
              mx-2
            "
          >
            تحميل
          </a>
          <p v-else class="font-medium text-xl text-h1Text px-4 py-1 mx-2">
            لا يوجد ملف
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">
            محضر الاجتماع :</label
          >
          <a
            v-if="CompanyDetail.photos.minutes_of_the_meeting.length > 0"
            :href="
              'https://' + CompanyDetail.photos.minutes_of_the_meeting[0].path
            "
            download
            target="_blank"
            class="
              font-medium
              text-xl text-white
              bg-green-600
              px-4
              py-1
              rounded-lg
              mx-2
            "
          >
            تحميل
          </a>
          <p v-else class="font-medium text-xl text-h1Text px-4 py-1 mx-2">
            لا يوجد ملف
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">
            والجمعية العمومية:</label
          >
          <a
            v-if="CompanyDetail.photos.the_general_assembly.length > 0"
            :href="
              'https://' + CompanyDetail.photos.the_general_assembly[0].path
            "
            download
            target="_blank"
            class="
              font-medium
              text-xl text-white
              bg-green-600
              px-4
              py-1
              rounded-lg
              mx-2
            "
          >
            تحميل
          </a>
          <p v-else class="font-medium text-xl text-h1Text px-4 py-1 mx-2">
            لا يوجد ملف
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >السجل التجاري:</label
          >
          <a
            v-if="CompanyDetail.photos.commercial_record.length > 0"
            :href="'https://' + CompanyDetail.photos.commercial_record[0].path"
            download
            target="_blank"
            class="
              font-medium
              text-xl text-white
              bg-green-600
              px-4
              py-1
              rounded-lg
              mx-2
            "
          >
            تحميل
          </a>
          <p v-else class="font-medium text-xl text-h1Text px-4 py-1 mx-2">
            لا يوجد ملف
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >الغرفة التجارية:</label
          >
          <a
            v-if="CompanyDetail.photos.chamber_of_commerce.length > 0"
            :href="
              'https://' + CompanyDetail.photos.chamber_of_commerce[0].path
            "
            download
            target="_blank"
            class="
              font-medium
              text-xl text-white
              bg-green-600
              px-4
              py-1
              rounded-lg
              mx-2
            "
          >
            تحميل
          </a>
          <p v-else class="font-medium text-xl text-h1Text px-4 py-1 mx-2">
            لا يوجد ملف
          </p>
        </div>
      </div>
      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-xl font-bold">
          مكان المختبر
        </span>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">المدينة:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ CompanyDetail.city }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">البلدية:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ CompanyDetail.municipal }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">الشارع:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ CompanyDetail.street }}
          </p>
        </div>
      </div>
      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-xl font-bold">
          بيانات مالك الشركة
        </span>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">الاسم:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ CompanyDetail.official_representative }}
          </p>
        </div>
        <div
          v-if="CompanyDetail.official_representative_describe"
          class="p-5 flex items-center w-full md:w-1/2 flex-wrap"
        >
          <label class="font-medium text-xl text-gray-700">وظيفته :</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ CompanyDetail.official_representative_describe }}
          </p>
        </div>
        <div
          v-if="CompanyDetail.official_representative_email"
          class="p-5 flex items-center w-full md:w-1/2 flex-wrap"
        >
          <label class="font-medium text-xl text-gray-700"
            >البريد الالكتروني:</label
          >
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ CompanyDetail.official_representative_email }}
          </p>
        </div>
        <div
          v-if="CompanyDetail.official_representative_number"
          class="p-5 flex items-center w-full md:w-1/2 flex-wrap"
        >
          <label class="font-medium text-xl text-gray-700">الهاتف:</label>
          <p class="font-medium text-xl text-h1Text mx-2">
            {{ CompanyDetail.official_representative_number }}
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >صورة من إفادة التخرج</label
          >
          <a
            v-if="CompanyDetail.photos.statement_of_graduate.length > 0"
            :href="
              'https://' + CompanyDetail.photos.statement_of_graduate[0].path
            "
            download
            target="_blank"
            class="
              font-medium
              text-xl text-white
              bg-green-600
              px-4
              py-1
              rounded-lg
              mx-2
            "
          >
            تحميل
          </a>
          <p v-else class="font-medium text-xl text-h1Text px-4 py-1 mx-2">
            لا يوجد ملف
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >رسالة من ادارة التعليم الخاص بوزارة التعليم لخريجي القطاع الخاص
            (دبلوم عالي - بكالوريس)</label
          >
          <a
            v-if="
              CompanyDetail.photos.better_from_education_department.length > 0
            "
            :href="
              'https://' +
              CompanyDetail.photos.better_from_education_department[0]
            "
            download
            target="_blank"
            class="
              font-medium
              text-xl text-white
              bg-green-600
              px-4
              py-1
              rounded-lg
              mx-2
            "
          >
            تحميل
          </a>
          <p v-else class="font-medium text-xl text-h1Text px-4 py-1 mx-2">
            لا يوجد ملف
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >رسالة من ادارة الجودة بما يفيد معادلة الشهادة للدارسين خارج
            ليبيا</label
          >
          <a
            v-if="
              CompanyDetail.photos.better_from_quality_department.length > 0
            "
            :href="
              'https://' +
              CompanyDetail.photos.better_from_quality_department[0].path
            "
            download
            target="_blank"
            class="
              font-medium
              text-xl text-white
              bg-green-600
              px-4
              py-1
              rounded-lg
              mx-2
            "
          >
            تحميل
          </a>
          <p v-else class="font-medium text-xl text-h1Text px-4 py-1 mx-2">
            لا يوجد ملف
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >الصورة الشخصية لمالك الشركة:</label
          >
          <a
            v-if="CompanyDetail.photos.personal_image.length > 0"
            :href="'https://' + CompanyDetail.photos.personal_image[0].path"
            download
            target="_blank"
            class="
              font-medium
              text-xl text-white
              bg-green-600
              px-4
              py-1
              rounded-lg
              mx-2
            "
          >
            تحميل
          </a>
          <p v-else class="font-medium text-xl text-h1Text px-4 py-1 mx-2">
            لا يوجد ملف
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >شهادة ميلاد مع الرقم الوطني معتمدة:</label
          >
          <a
            v-if="CompanyDetail.photos.birth_certificate.length > 0"
            :href="'https://' + CompanyDetail.photos.birth_certificate[0].path"
            download
            target="_blank"
            class="
              font-medium
              text-xl text-white
              bg-green-600
              px-4
              py-1
              rounded-lg
              mx-2
            "
          >
            تحميل
          </a>
          <p v-else class="font-medium text-xl text-h1Text px-4 py-1 mx-2">
            لا يوجد ملف
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700"
            >صورة اثبات الهوية (جواز السفر - البطاقة الشخصية) سارية
            الصلاحية:</label
          >
          <a
            v-if="CompanyDetail.photos.proof_of_identity.length > 0"
            :href="'https://' + CompanyDetail.photos.proof_of_identity[0].path"
            download
            target="_blank"
            class="
              font-medium
              text-xl text-white
              bg-green-600
              px-4
              py-1
              rounded-lg
              mx-2
            "
          >
            تحميل
          </a>
          <p v-else class="font-medium text-xl text-h1Text px-4 py-1 mx-2">
            لا يوجد ملف
          </p>
        </div>
        <div class="p-5 flex items-center w-full md:w-1/2 flex-wrap">
          <label class="font-medium text-xl text-gray-700">
            خبرة عمل في مجال المختبرات الطبية, صورة من شهادة الخبرة:</label
          >
          <a
            v-if="CompanyDetail.photos.experience_certificate.length > 0"
            :href="
              'https://' + CompanyDetail.photos.experience_certificate[0].path
            "
            download
            target="_blank"
            class="
              font-medium
              text-xl text-white
              bg-green-600
              px-4
              py-1
              rounded-lg
              mx-2
            "
          >
            تحميل
          </a>
          <p v-else class="font-medium text-xl text-h1Text px-4 py-1 mx-2">
            لا يوجد ملف
          </p>
        </div>
      </div>

      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-xl font-bold">
          طباعة
        </span>
        <div class="py-3 w-full sm:w-1/2 lg:w-2/6 my-5 mx-auto rounded-lg">
          <button
            @click="printReport"
            class="
              border-2 border-primary
              rounded-lg
              bg-primary
              text-lg text-white
              py-2
              font-bold
              w-full
              flex
              justify-center
              items-center
            "
          >
            <span>
              طباعة اذن مزاولة شركة {{ CompanyDetail.company_name }}
            </span>
            <i class="bx bx-printer mx-2 text-2xl"></i>
          </button>
        </div>
      </div>
      <div v-if="errMsg">
        <alert :msg="errMsg" />
      </div>
    </div>
    <div v-else class="flex justify-center py-5">
      <i class="text-4xl mx-auto text-primary bx bx-loader-circle bx-spin"></i>
    </div>
  </div>
</template>
<script>
import alert from "../../layout/alert";
import Printcompany from "../printDoc/company";
import Printcompany_services from "../printDoc/compay_services";

export default {
  name: "CompanyDetail",
  data() {
    return {
      loading: false,
      CompanyDetail: null,
      data: null,
      status: null,
      errMsg: null,
    };
  },
  watch: {
    status() {
      this.ChangeCompanyStatus();
    },
  },
  computed: {
    userData() {
      return this.$store.getters.userData;
    },
    Branches() {
      return this.$store.getters.getBranches;
    },
  },
  created() {
    if (!this.userData) {
      this.$router.push({ name: "Signin" });
      return;
    }
    this.getNewData();
  },
  methods: {
    async getNewData() {
      this.loading = true;
      try {
        const { data } = await this.$store.dispatch(
          "geCompanyDetails",
          this.$route.params.doc
        );
        this.data = data;
        this.CompanyDetail = data;
        //this.formatDetails(data);
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },

    editCompany() {
      this.$store.commit("editCompany", this.data);
      this.$router.push({ name: "companyGeneral" });
    },
    ChangeCompanyStatus() {
      this.loading = true;
      this.$store
        .dispatch("CompanyStatus", {
          status: this.status,
          _id: this.CompanyDetail.id,
        })
        .then(() => {
          this.getNewData();
        });
    },
    printReport() {
      if (this.CompanyDetail.status !== "نشط") {
        this.errMsg = "يحب تنشيط هذه الشركة لطباعة إذن المزاولة الخاص بها";
        setTimeout(() => {
          this.errMsg = null;
        }, 7000);
      } else {
         let tab;
        if (this.CompanyDetail.company_type == "شركة خدمات") {
          tab = Printcompany_services(this.CompanyDetail, this.userData);
        } else {
          tab = Printcompany(this.CompanyDetail, this.userData);
        }
        const WinPrint = window.open(
          "",
          "",
          "right=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        );
        WinPrint.document.write(tab);
      }
    },
  },
  components: {
    alert,
  },
};
</script>
