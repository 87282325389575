import indivual from "@/views/registration/indivual";
import company from "@/views/registration/company";
import Labs from "@/views/registration/labs";
import Branch from "@/views/registration/Branch";

import indivualGeneral from "@/components/registration/individual/General";
import Education from "@/components/registration/individual/Education";

import companyGeneral from "@/components/registration/company/general";
import companyPersonal from "@/components/registration/company/personal";
import companyDocuments from "@/components/registration/company/documents";

import AddEmployee from "@/views/registration/Employee";
// import store from "@/store/index";

const registrationRoutes = [
  {
    path: "/doctor-registration",
    name: "indivual",
    component: indivual,
    // beforeEnter: async (to, from, next) => {
    //   try {
    //     if (store.getters.userData) next();
    //     let user = await store.dispatch("is_logged_in");
    //     if (user.message == "success") next();
    //     else next("/");
    //   } catch {
    //     next("/");
    //   }
    // },
    children: [
      {
        path: "/personal-data",
        name: "indivualGeneral",
        component: indivualGeneral,
      },
      {
        path: "/experience",
        name: "Education",
        component: Education,
      },
    ],
  },
  {
    path: "/company-registration",
    name: "company",
    component: company,
    children: [
      {
        path: "/general-information",
        name: "companyGeneral",
        component: companyGeneral,
      },
      {
        path: "/location",
        name: "companyPersonal",
        component: companyPersonal,
      },
      {
        path: "/Documents",
        name: "companyDocuments",
        component: companyDocuments,
      },
    ],
  },
  {
    path: "/lab-registration",
    name: "Labs",
    component: Labs,
  },
  {
    path: "/Branch-registration",
    name: "Branch",
    component: Branch,
  },
  {
    path: "/Employees",
    name: "Employees",
    component: AddEmployee,
  },
];
export default registrationRoutes;
