<template>
  <transition name="slideTop">
    <div
      v-if="animate"
      class="bg-white p-5  md:rounded-lg border border-formBorderColor overflow-hidden shadow-2xl h-full md:h-auto w-full md:w-1/2 lg:w-1/3 z-50"
    >
      <form @submit.prevent="submit" autocomplete="on" class="w-full">
        <div class="flex justify-between items-center w-full mb-5">
          <h1 class="text-primary text-2xl">ارسال بريد الكتروني</h1>
          <i
            @click="$store.commit('sendEmailNotification')"
            class="bx bx-x text-3xl text-gray-800 cursor-pointer"
          ></i>
        </div>

        <div class="my-5 flex items-center justify-center w-full flex-wrap">
          <label
            for="email"
            class="font-medium text-lg w-full text-right text-h1Text"
          >
            إلى
          </label>
          <input
            id="email"
            name="email"
            v-model="form.email"
            type="email"
            placeholder="example@example.com"
            required
            @blur="$v.form.email.$touch()"
            :class="
              defualtInput +
                ($v.form.email.$error
                  ? ' border-red-700'
                  : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.email.$error"
            class="text-red-600 text-sm text-right my-1 w-full"
          >
            الرجاء إدخال بريد الالكلتروني الخاص بالشخص المراد ارسال البريد
            الالكتروني اليه
          </p>
        </div>
        <div class="my-5 flex items-center justify-center w-full  flex-wrap">
          <label
            for="subject"
            class="font-medium text-lg w-full text-right text-h1Text"
            >العنوان</label
          >
          <input
            id="subject"
            name="subject"
            v-model="form.subject"
            type="text"
            required
            @blur="$v.form.subject.$touch()"
            :class="
              defualtInput +
                ($v.form.subject.$error
                  ? ' border-red-700'
                  : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.subject.$error"
            class="text-red-600 text-right text-sm my-1 w-full"
          >
            الرجاء ادخال عنوان الرسالة
          </p>
        </div>
        <div class="my-5 flex items-center justify-center w-full  flex-wrap">
          <label
            for="content"
            class="font-medium text-lg w-full text-right text-h1Text"
            >محتوى الرسالة</label
          >
          <textarea
            id="content"
            name="content"
            required
            v-model="form.content"
            class="form-textarea"
            @blur="$v.form.content.$touch()"
            :class="
              defualtInput +
                ($v.form.content.$error
                  ? ' border-red-700'
                  : 'border-inputBorder')
            "
          >
          </textarea>
          <p
            v-if="$v.form.content.$error"
            class="text-red-600 text-right text-sm my-1 w-full"
          >
            الرجاء تعبئة هذا الحقل
          </p>
        </div>
        <button
          type="submit"
          v-if="!success"
          @click.prevent="submit"
          class="bg-primary text-white flex items-center text-lg px-4 py-1 rounded-md focus:outline-none"
        >
          <span class="mx-1">ارسل</span>
          <i v-if="!loading" class="bx bx-mail-send"></i>
          <i
            v-if="loading"
            class="text-2xl mx-2 bx bx-loader-circle bx-spin text-white"
          ></i>
        </button>
      </form>
      <div
        v-if="success"
        class="flex w-full justify-between items-center my-5 font-medium py-1 px-2 rounded-md text-green-100 bg-green-700 border border-green-700"
      >
        <div class="flex items-center">
          <div slot="avatar">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-check-circle w-5 h-5 mx-2"
            >
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
              <polyline points="22 4 12 14.01 9 11.01"></polyline>
            </svg>
          </div>
          <h1 class="text-xl py-2 font-medium">
            تم ارسال البريد الالكتروني بنجاح
          </h1>
        </div>

        <button
          @click="toggleSuccessMsg"
          class="bg-white text-green-700 focus:outline-none font-medium mx-3 text-lg rounded-md px-3 py-1"
        >
          حسناً
        </button>
      </div>
    </div>
  </transition>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      defualtInput:
        "p-2  bg-white rounded-md border w-full text-right focus-within:border-primary hover:border-primary focus:border-primary focus:outline-none focus:shadow-md my-1",
      loading: false,
      animate: false,
      success: false,
      form: { email: null, subject: null, content: null },
    };
  },
  validations: {
    form: {
      email: { required, email },
      subject: { required },
      content: { required },
    },
  },
  created() {
    document.addEventListener("keyup", (evt) => {
      if (evt.keyCode === 27) {
        this.toggleSuccessMsg();
      }
    });
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$pending || this.$v.form.$error) return;
      this.loading = true;
      this.$store
        .dispatch("sendEmailNotifivationMethods", this.form)
        .then(() => {
          this.loading = false;
          this.success = true;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    toggleSuccessMsg() {
      this.success = false;
      this.loading = false;
      this.$store.commit("sendEmailNotification");
    },
  },
  computed: {
    participantEmail(){
      return this.$store.getters.participantEmail
    }
  },
  mounted() {
    setTimeout(() => {
      this.form.email = this.participantEmail
      this.animate = true;
    }, 10);
  },
};
</script>
