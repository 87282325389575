import controlPanel from "@/views/controlPanel";

import Dashboard from "@/components/dashboard/Dashboard";
import CompaniesPanel from "@/components/dashboard/TablesData/Companies";
import DoctorsPanel from "@/components/dashboard/TablesData/DoctorsPanel";
import LabsPanel from "@/components/dashboard/TablesData/LabsPanel";
import BranchesPanel from "@/components/dashboard/TablesData/Branches";
import employeesPanel from "@/components/dashboard/TablesData/employee";
import News from "@/components/dashboard/News";
import NewsDetail from "@/components/dashboard/DetailsScreens/NewsDetail";
import Events from "@/components/dashboard/Events";
import EventDetail from "@/components/dashboard/DetailsScreens/EventDetail";
import addEvent from "@/components/dashboard/Add/AddEvent";
import AddNews from "@/components/dashboard/Add/AddNews";
import organizationStructure from "@/components/dashboard/organizationStructure";

import doctorDetails from "@/components/dashboard/DetailsScreens/doctorDetails";
import LabDetails from "@/components/dashboard/DetailsScreens/LabDetails";
import companyDetails from "@/components/dashboard/DetailsScreens/companyDetails";
// import store from "@/store/index";

const controlPanelChildrens = [
  {
    path: "/control-panel",
    name: "controlPanel",
    component: controlPanel,
    // beforeEnter: async (to, from, next) => {
    //   try {
    //     if (store.getters.userData) next();
    //     let user = await store.dispatch("is_logged_in");
    //     if (user.message == "success") next();
    //     else next("/");
    //   } catch {
    //     next("/");
    //   }
    // },
    children: [
      {
        path: "/Dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/Companies/:count",
        name: "CompaniesPanel",
        component: CompaniesPanel,
      },
      {
        path: "/companyDetails/:doc",
        name: "companyDetails",
        component: companyDetails,
      },
      {
        path: "/Doctors/:count",
        name: "DoctorsPanel",
        component: DoctorsPanel,
      },
      {
        path: "/doctorDetails/:doc",
        name: "doctorDetails",
        component: doctorDetails,
      },
      {
        path: "/laboratories/:count",
        name: "LabsPanel",
        component: LabsPanel,
      },
      {
        path: "/LabDetails/:doc",
        name: "LabDetails",
        component: LabDetails,
      },
      {
        path: "/Branches",
        name: "BranchesPanel",
        component: BranchesPanel,
      },
      {
        path: "/emplyees",
        name: "employeesPanel",
        component: employeesPanel,
      },
      {
        path: "/News",
        name: "News",
        component: News,
      },
      {
        path: "/AddNews",
        name: "AddNews",
        component: AddNews,
      },
      {
        path: "/NewsDetail/:id",
        name: "NewsDetail",
        component: NewsDetail,
      },
      {
        path: "/Events",
        name: "Events",
        component: Events,
      },
      {
        path: "/addEvent",
        name: "addEvent",
        component: addEvent,
      },
      {
        path: "/EventDetail/:id",
        name: "EventDetail",
        component: EventDetail,
      },
      {
        path: "/organizationStructure",
        name: "organizationStructure",
        component: organizationStructure,
      },
    ],
    meta: {
      auth: true,
    },
  },
];
export default controlPanelChildrens;
